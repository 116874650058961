import React from "react";
import PropTypes from "prop-types";
import { Col, Grid, Row } from "react-flexbox-grid";
import StoryCarousel from "../../../components/story-carousel";
import { AnimatedContent } from "../../../components/animated-content";
import { createMarkup } from "./helpers";
import VideoSlide from "./videoSlide";
import EmbeddedVideo from "./EmbeddedVideo";

const ExploreStoryContentOriginal = React.forwardRef(
  (
    {
      text,
      theme,
      photos,
      videoUrl,
      thumbnailUrl,
      thumbnail_message,
      embedded,
    },
    ref
  ) => {
    const { likeRef } = ref;

    return (
      <>
        <AnimatedContent rootMargin={0} threshold={0.1} delay={0.35} custom={1}>
          <EmbeddedVideo videoUrl={videoUrl} />
          {/* {!embedded && (
            <VideoSlide
              imgUrl={thumbnailUrl}
              videoUrl={videoUrl}
              message={thumbnail_message}
            />
          )} */}
        </AnimatedContent>
        <Grid className="bc-explore-story__content">
          <Row center="lg">
            <Col lg={10}>
              <AnimatedContent
                rootMargin={0}
                threshold={0.1}
                delay={0.35}
                custom={1}
              >
                <div
                  ref={likeRef}
                  dangerouslySetInnerHTML={createMarkup(text)}
                  className="bc-formatted-text"
                />
              </AnimatedContent>
            </Col>
          </Row>

          <Row center="lg">
            <Col lg={10}>
              <AnimatedContent
                rootMargin={0}
                threshold={0.1}
                delay={0.35}
                custom={1}
              >
                <StoryCarousel photos={photos} theme={theme} />
              </AnimatedContent>
            </Col>
          </Row>
        </Grid>
      </>
    );
  }
);

ExploreStoryContentOriginal.defaultProps = {
  text: "",
  photos: [],
  videos: [],
  likes: 0,
  stickyLike: false,
};

ExploreStoryContentOriginal.propTypes = {
  likes: PropTypes.number,
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  theme: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  stickyLike: PropTypes.bool,
};

export default ExploreStoryContentOriginal;
