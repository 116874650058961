import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';

import { Icons } from '../icon';
import DropdownSelectItem from './dropdown-select-item';

import { useEventListener, useOnClickOutside } from '../../helpers/hooks';

import './dropdown-select.scss';

const DropdownSelect = ({
  label,
  options,
  selected,
  onChange,
  disabled,
  editable,
  placeholder,
  error
}) => {
  const optionsRef = React.useRef();
  const inputRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(selected);
  const [focused, setFocused] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    if (!selectedOption || selected !== selectedOption.value) {
      setSelectedOption(options.find(item => item.value === selected));
    }
  }, [selected, selectedOption, options]);

  const handleClose = useCallback(() => {
    setFocused(false);
    setInputValue('');
    setOpen(false);
  }, [setOpen]);

  const handleKeyUp = React.useCallback(
    e => {
      if (
        (e.key === 'Enter' || e.key === 'Space') &&
        optionsRef.current === document.activeElement
      ) {
        setOpen(true);
      }

      if (e.key === 'Escape') {
        setOpen(false);
        setFocused(false);
      }
    },
    [optionsRef]
  );

  useOnClickOutside(optionsRef, handleClose);
  useEventListener('keyup', handleKeyUp);
  React.useEffect(() => {
    if (focused) {
      inputRef.current.focus();
    }
  }, [focused]);

  return (
    <div className='bc-dropdown-select'>
      <div
        className={classNames('bc-dropdown-select__container', {
          disabled,
          editable,
          hasSelected: !!selectedOption,
          focused,
          error: !!error,
          open
        })}
        ref={optionsRef}
      >
        <div
          /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
          tabIndex={0}
          onFocus={() => {
            setFocused(true);
            setOpen(true);
          }}
          className={classNames('bc-dropdown-select__control', { open })}
          onClick={() => {
            setOpen(true);
            setFocused(true);
          }}
        >
          {focused ? (
            <input
              className='bc-dropdown-select__input'
              ref={inputRef}
              onClick={e => e.stopPropagation()}
              onFocus={() => setFocused(true)}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              type='text'
            />
          ) : (
            <div>{selectedOption ? selectedOption.label : placeholder}</div>
          )}
          {label && <div className='bc-dropdown-select__label'>{label}</div>}
          <div className={classNames('control-toggle', { open })}>
            {Icons.CHEVRON_DOWN}
          </div>
        </div>
        <div className={classNames('bc-dropdown-select__options', { open })}>
          {options
            .filter(option => {
              if (inputValue.trim() !== '') {
                return option.label
                  ?.toLowerCase()
                  .startsWith(inputValue?.toLowerCase());
              }
              return true;
            })
            .sort((a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0;
            })
            .map(option => (
              <DropdownSelectItem
                key={option.value}
                label={option.label}
                value={option.value}
                selected={
                  selectedOption && option.value === selectedOption.value
                }
                onClick={() => {
                  setSelectedOption(option);
                  setInputValue('');
                  handleClose(false);
                  onChange(option);
                }}
              />
            ))}
        </div>
      </div>
      {!!error && (
        <div className='bc__status'>
          <div>{Icons.ERROR}</div>
          <div>{error}</div>
        </div>
      )}
    </div>
  );
};

DropdownSelect.defaultProps = {
  selected: null,
  disabled: false,
  placeholder: '',
  label: '',
  error: null,
  editable: true
};

DropdownSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string
};

export default DropdownSelect;
