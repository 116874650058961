import { css } from "styled-components";
import { font_family_body } from "./fontFamily";
import { weight_semi_bold } from "./weight";

export const noselect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
export const fontFamilyBodySemibold = css`
  font-family: ${font_family_body};
  font-weight: ${weight_semi_bold};
`;
