import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
// import { motion, useAnimation } from "framer-motion";

export const AnimatedContent = ({
  children,
  className,
  threshold = 0.3,
  rootMargin = 60,
  translate = 0,
  delay = 0.3,
  custom = 0,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold,
    rootMargin: `-${rootMargin}px`,
  });

  const variants = useMemo(
    () => ({
      visible: (i) => ({
        opacity: 1,
        transform: "translateY(0px)",
        transition: { duration: 0.4, delay: i * delay },
      }),
      hidden: {
        opacity: 0,
        transform: `translateY(${translate}px)`,
        transition: { duration: 0.4 }, // Adjust the duration if needed
      },
    }),
    [delay, translate]
  );

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      custom={custom}
      animate={controls}
      initial="hidden"
      variants={variants}
      className={className}
    >
      {children}
    </motion.div>
  );
};

AnimatedContent.defaultProps = {
  custom: 0,
  delay: 0.3,
  className: "",
  translate: 10,
  threshold: 0.3,
  rootMargin: 60,
};

AnimatedContent.propTypes = {
  children: PropTypes.node.isRequired,
  custom: PropTypes.number,
  delay: PropTypes.number,
  translate: PropTypes.number,
  rootMargin: PropTypes.number,
  threshold: PropTypes.number,
  className: PropTypes.string,
};
