import { gql } from "@apollo/client";
import { apolloClient } from "../../index";
import { maxFilesNumber } from "./../../serverConfig";

export const GET_STORY_MEDIA_QUERY = gql`
query getStoryMedia($storyId: ID!) {
  story(id:$storyId){
    data{ attributes{
      cover_image{data{id attributes{mime url formats}}}
      attached_files(pagination:{limit:${
        maxFilesNumber ?? 100
      }}){data{id attributes{mime url formats}}}
      video_links
    }}
  }
}
`;
export const DETELE_FILE_MUTATION = gql`
  mutation DeleteUploadFile($id: ID!) {
    deleteUploadFile(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const getStoryMedia = async (storyId) => {
  const getThumbUrl = (attributes) => {
    if (!attributes) return null;
    //else
    const { mime, url, formats } = attributes;

    // if (!formats || mime?.startsWith("video"))
    //   return url;
    // // else
    const img =
      formats?.small ?? formats?.medium ?? formats?.thumbnail ?? formats?.large;
    const _url = img?.url ?? url;
    return _url ? `${process.env.REACT_APP_API_BASE_URL}${_url}` : null;
  };

  try {
    const { error, data } = await apolloClient.query({
      query: GET_STORY_MEDIA_QUERY,
      variables: { storyId },
    });
    if (error) throw error;
    //else
    const _cover_image = data.story.data?.attributes?.cover_image?.data;
    const _attached_files = data.story.data?.attributes?.attached_files?.data;
    const videoLinks = data.story.data?.attributes?.video_links ?? [];

    const coverImage = _cover_image
      ? {
          id: _cover_image?.id,
          url: getThumbUrl(_cover_image?.attributes),
        }
      : null;
    const attachedFiles = _attached_files
      ? _attached_files?.map((x) => ({
          id: x.id,
          mime: x.attributes.mime,
          url: getThumbUrl(x.attributes),
          //url: `${process.env.REACT_APP_API_BASE_URL}${x?.attributes?.url}`
        }))
      : [];

    return { coverImage, attachedFiles, videoLinks };
  } catch (error) {
    //handle error
    console.error(error.message);
  }
};

export const removeFile = async (id) => {
  try {
    const result = await apolloClient.mutate({
      mutation: DETELE_FILE_MUTATION,
      variables: { id },
    });

    if (result.error) throw result.error;
    //else
    return result;
  } catch (error) {
    //handle error
    console.error(error.message);
  }
};

export const updateStoryWithLinks = async (storyId, links) => {
  const UPDATE_STORY_LINK = gql`
    mutation updateStoryLink($storyId: ID!, $data: StoryInput!) {
      updateStory(id: $storyId, data: $data) {
        data {
          id
          attributes {
            video_links
          }
        }
      }
    }
  `;
  try {
    const result = await apolloClient.mutate({
      mutation: UPDATE_STORY_LINK,
      variables: {
        storyId,
        data: { video_links: links },
      },
    });
    if (result?.error) throw result.error;
    //else
    return result?.data?.updateStory?.data?.attributes?.video_links;
  } catch (error) {
    //handle error
    console.error(error.message);
  }
};
