import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withRouter from "./withRouter.jsx";
import Prompt from "./Prompt";
import Popup from '../popup';

import { resetState } from '../../helpers/localStorage';

import styles from './unsaved-dialog.module.scss';

let navigationConfirmed = false;
/**
 * Displays confirmation dialog when there are unsaved data exist
 */
class UnsavedDialogTp extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = { showDialog: false, ready: true, mounted: false };
    this.hasUnsavedData = this.hasUnsavedData.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
  }

  /**
   * Add listener - this will catch reload, url changes, tab close actions
   */
  componentDidMount() {
    const { hasUnsaved } = this.props;
    const { mounted } = this.state;
    window.addEventListener('beforeunload', this.handleLeave);
    if (hasUnsaved) {
      window.addEventListener('unload', this.handleUnload);
    } else {
      window.removeEventListener('unload', this.handleUnload);
    }

    this.setState({ mounted: true });
    this.timeout = setTimeout(() => {
      if (mounted) {
        this.setState({ ready: true });
      }
    }, 1000);
  }

  /**
   * Add unload listener if there are unsaved changes
   * @param nextProps
   */
  componentDidUpdate(nextProps) {
    if (nextProps.hasUnsaved) {
      window.addEventListener('unload', this.handleUnload);
    } else {
      window.removeEventListener('unload', this.handleUnload);
    }
  }

  /**
   * Removes listener on unmount
   */
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.remove) {
      this.remove();
    }
    window.removeEventListener('beforeunload', this.handleLeave);
    window.removeEventListener('unload', this.handleUnload);
  }

  /**
   * Block navigation handler
   * @param nextLocation
   * @returns {boolean}
   */
  handleBlockedNavigation = nextLocation => {
    const { confirmedNavigation } = this.state;
    const { allowTabs, tabsCommonPath, location } = this.props;
    if (!confirmedNavigation && this.hasUnsavedData()) {
      const currentPath = location.pathname;
      const nextPath = nextLocation.pathname;
      const currentPathPrefix = currentPath.substr(
        0,
        currentPath.indexOf(tabsCommonPath) + tabsCommonPath.length
      );
      const nextPathPrefix = nextPath.substr(
        0,
        currentPath.indexOf(tabsCommonPath) + tabsCommonPath.length
      );
      const nextPathSuffix = nextPath.substr(
        nextPathPrefix.length,
        nextPath.length
      );

      if (
        navigationConfirmed ||
        !this.hasUnsavedData() ||
        (allowTabs &&
          currentPathPrefix === nextPathPrefix &&
          (nextPathSuffix === '' || nextPathSuffix.startsWith('/')))
      ) {
        navigationConfirmed = false;
        return true;
      }
      this.setState({ showDialog: true, nextLocation });
      return false;
    }

    return true;
  };

  /**
   * Handle page leave - this will catch reload, url changes, tab close actions
   * @param e
   * @returns {string}
   */
  handleLeave(e) {
    const { warningMessage } = this.props;
    if (this.hasUnsavedData()) {
      const confirmationMessage = warningMessage;
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
    return null;
  }

  /**
   * Handles cancel action
   */
  handleCancel() {
    this.setState({ showDialog: false });
  }

  /**
   * Handles confirm action
   */
  handleConfirm() {
    const { onLeave, onNavigate } = this.props;
    const {
      nextLocation: { pathname }
    } = this.state;
    resetState('bc-persisted-state');
    this.setState(
      {
        showDialog: false
      },
      () => {
        setTimeout(() => {
          navigationConfirmed = true;
          // history.push(pathname);
          onNavigate(pathname);
          if (onLeave) {
            onLeave();
          }
        }, 400);
      }
    );
  }

  /**
   * Clear data on page unload
   */
  handleUnload() {
    const { onLeave } = this.props;
    resetState('bc-persisted-state');
    if (onLeave) {
      onLeave();
    }
  }

  /**
   * Save data
   */
  saveData = () => {
    const { onSave } = this.props;
    if (onSave) {
      onSave().then(() => this.handleCancel());
    }
  };

  /**
   * Check if there are unsaved data
   * @returns {boolean}
   */
  hasUnsavedData() {
    const { hasUnsaved } = this.props;
    return hasUnsaved;
  }

  /**
   * Render dialog - this will handle internal navigation
   */
  render() {
    const { warningMessage } = this.props;
    const { showDialog, ready } = this.state;

    const buttons = [
      {
        onClick: this.handleConfirm,
        type: 'primary',
        label: 'Leave anyway',
        disabled: false,
        className: 'bc-submit-add-your-story-trigger'
      },
      {
        onClick: this.handleCancel,
        type: 'secondary',
        label: 'Cancel',
        disabled: false,
        className: 'bc-submit-add-your-story-trigger'
      }
    ];

    return (
      <>

        {/* Prompt not working !!!!
        {ready && (
          <Prompt
            when={this.hasUnsavedData()}
            message={this.handleBlockedNavigation}
          />
        )} */}
        <Popup
          title='Unsaved changes'
          className={styles.popup}
          isShown={showDialog}
          handleClose={this.handleCancel}
          buttons={buttons}
        >
          <div className='unsaved-changes-dialog-content'>{warningMessage}</div>
        </Popup>
      </>
    );
  }
}

UnsavedDialogTp.defaultProps = {
  hasUnsaved: false,
  // router: undefined,
  // routes: undefined,
  warningMessage:
    'Are you sure you want to leave? The changes you made will not be saved.',
  allowTabs: false,
  tabsCommonPath: '',
  onSave: undefined,
  onLeave: undefined
};

UnsavedDialogTp.propTypes = {
  /**
   * Indicates that there are unsaved changes exist
   */
  hasUnsaved: PropTypes.bool,
  /**
   * Custom warning message
   */
  warningMessage: PropTypes.string,
  allowTabs: PropTypes.bool,
  tabsCommonPath: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  onSave: PropTypes.func,
  onLeave: PropTypes.func,
  onNavigate: PropTypes.func.isRequired
};

export { UnsavedDialogTp };
export default withRouter(UnsavedDialogTp);
