import React, { useMemo, createContext } from "react";
import clsx from "clsx";
import { isMobileDevice } from "responsive-react";

// Components
import ExploreStoryHeader from "./explore-story-header/explore-story-header";
import ExploreStoryContentOriginal from "./explore-story-content/explore-story-content-original";
import ExploreStoryContentMobile from "./explore-story-content/explore-story-content-mobile";
import Navbar from "../../components/navbar";
import Background from "../../components/background";


// Helpers
import { useIntersect } from "../../helpers/hooks";
import { getTheme, isFeatureAvailable } from "../../helpers/common";

// Styles
import "./explore-story.scss";

export const StoryContext = createContext({});

const ExploreStoryRenderer = ({ story }) => {
  const theme = useMemo(() => getTheme(), []);
  const [observerEntry, entryRef] = useIntersect({
    threshold: "0, 1",
    rootMargin: "0px 0px 0px 0px",
  });
  const [observerLike, likeRef] = useIntersect({
    threshold: "0.01, 0.5, 1",
    rootMargin: "-50px 0px 0px 0px",
  });

  const {
    id,
    title,
    text,
    cover_image,
    cover_image_thumbnail,
    location,
    author,
    date,
    photos,
    videos,
    nominated_primo_name,
    is_winner,
    likes,
    tags,
    rank,
  } = story;


  const stickyLike = isFeatureAvailable(1) && !observerLike.isIntersecting;
  const storyTagsNames = tags?.map(x => x.name);
  
  return (
    <div className={clsx("bc-explore-story__container", theme)}>
      <Background />
      <Navbar light />

      <section ref={entryRef}>
        <StoryContext.Provider value={{ is_winner }}>
          <ExploreStoryHeader
            theme={theme}
            minimized={
              observerEntry.isIntersecting !== undefined &&
              !(
                observerEntry.isIntersecting &&
                observerEntry.boundingClientRect?.top === 0
              )
            }
            author={author}
            date={date}
            rank={rank}
            nominatedAuthor={nominated_primo_name}
            is_winner
            coverImage={cover_image}
            coverImageThumb={cover_image_thumbnail}
            location={location}
            title={title}
          />
        </StoryContext.Provider>
      </section>
      {isMobileDevice() ? (
        <ExploreStoryContentMobile
          id={id}
          likes={likes}
          ref={{ likeRef }}
          theme={theme}
          photos={photos}
          text={text}
          videos={videos}
          storyTagsNames={storyTagsNames}
        />
      ) : (
        <ExploreStoryContentOriginal
          stickyLike={stickyLike && observerEntry.boundingClientRect?.top >= 0}
          likes={likes}
          ref={{ likeRef }}
          theme={theme}
          photos={photos}
          text={text}
          id={id}
          videos={videos}
          storyTagsNames={storyTagsNames}
        />
      )}

    </div>
  );
};

export default ExploreStoryRenderer;
