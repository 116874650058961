import { gql } from "@apollo/client";
import { apolloClient } from "../../index";
// import { has } from "lodash";

// const updateRunks = async (nominationYear) => {
//   const maxNbr = 99999999;
//   const GET_STORIES_LIKES_N_RANKS = gql`
//     query checkRank($nominationYear:Long!){
//       stories (filters:{nomination_year:{eq:$nominationYear}} pagination:{limit:${maxNbr}}){
//         data{id attributes{likes_number, rank}}
//         meta{pagination{total}}
//       }
//     }`;
//   const UPDATE_STORY_RANK = gql`
//     mutation updateRank($storyId: ID!, $rank: Long!) {
//       updateStory(id: $storyId, data: { rank: $rank }) {
//         data {
//           attributes {
//             rank
//           }
//         }
//       }
//     }
//   `;
//   try {
//     const storiesResult = await apolloClient.query({
//       query: GET_STORIES_LIKES_N_RANKS,
//       variables: { nominationYear },
//       fetchPolicy: "network-only",
//     });
//     if (storiesResult.error) {
//       throw storiesResult.error;
//     }
//     //else
//     let storiesLikesRanks = storiesResult.data?.stories?.data?.map?.((x) => ({
//       storyId: x.id,
//       likesNumber: x.attributes?.likes_number,
//       rank: x.attributes?.rank,
//     }));
//     storiesLikesRanks = storiesLikesRanks.sort(
//       (a, b) => b.likesNumber - a.likesNumber
//     );
//     // Calculate newRanks and add them to items
//     let currentRank = 0;
//     let currentLikesNumber = null;
//     storiesLikesRanks.forEach((item) => {
//       if (+item.storyId === 35) {
//         console.log(item.storyId);
//       }
//       if (item.likesNumber !== currentLikesNumber) {
//         currentRank++;
//         currentLikesNumber = item.likesNumber;
//       }
//       item.newRank = currentRank;
//     });

//     //Update the server with the new ranks
//     storiesLikesRanks.forEach((item) => {
//       if (item.rank !== item.newRank) {
//         if (+item.storyId === 35) {
//           console.log(item.storyId);
//         }
//         //do updates usynchronously
//         apolloClient.mutate({
//           mutation: UPDATE_STORY_RANK,
//           variables: {
//             storyId: item.storyId,
//             rank: item.newRank,
//           },
//         });
//       }
//     });
//   } catch (error) {
//     throw error;
//   }
// };
const updateLikesNumber = async ({ storyId }) => {
  const GET_REAL_NBR = gql`
    query getLikesNumber($storyId: ID!) {
      primos(filters: { liked_stories: { id: { eq: $storyId } } }) {
        meta {
          pagination {
            total
          }
        }
      }
    }
  `;
  const UPDATE_STORY_LIKES_NUMBER = gql`
    mutation updateLikesNumber($storyId: ID!, $number: Int!) {
      updateStory(id: $storyId, data: { likes_number: $number }) {
        data {
          attributes {
            likes_number
          }
        }
      }
    }
  `;
  try {
    const nbrResult = await apolloClient.query({
      query: GET_REAL_NBR,
      variables: { storyId },
      fetchPolicy: "network-only",
    });
    if (nbrResult.error) {
      throw nbrResult.error;
    }
    //else
    const nbr = nbrResult.data?.primos?.meta?.pagination?.total;
    if (typeof nbr !== "number") {
      throw new Error(`Like number of story ${storyId} is not undefined...`);
    }

    const updateResult = await apolloClient.mutate({
      mutation: UPDATE_STORY_LIKES_NUMBER,
      variables: {
        storyId,
        number: nbr,
      },
    });
    if (updateResult.error) {
      throw updateResult.error;
    }

    //else
    const number =
      updateResult?.data?.updateStory?.data?.attributes?.likes_number;

    //Update ranks => if likes number of on story changed, ranks of severals stories can change
    //Do it asyncronously, we don't need to wait till the update finish
    //updateRunks(nominationYear);

    return number;
  } catch (error) {
    throw error;
  }
};

const getLikedStories = async (userId, nominationYear) => {
  const GET_PRIMO_LIKES = gql`
    query getPrimoLikes($userId: ID!, $nominationYear: Long!) {
      primo(id: $userId) {
        data {
          attributes {
            liked_stories(
              filters: { nomination_year: { eq: $nominationYear } }
            ) {
              data {
                id
                attributes {
                  nomination_year
                  tags {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  try {
    const result = await apolloClient.query({
      query: GET_PRIMO_LIKES,
      variables: { userId, nominationYear },
      fetchPolicy: "network-only",
    });
    if (result.error) {
      throw result.error;
    }
    const likedStories =
      result?.data?.primo?.data?.attributes?.liked_stories?.data?.map(
        (x) => x.id
      ) ?? [];
    const likedStoriesTags =
      result?.data?.primo?.data?.attributes?.liked_stories?.data?.map((s) => ({
        id: s.id,
        tags: s?.attributes?.tags?.data?.map((t) => ({
          id: t.id,
          name: t.attributes?.name,
        })),
      })) ?? [];
    return { likedStories, likedStoriesTags };
  } catch (error) {
    throw error;
  }
};
const updateLikes = async ({ userId, likedStories }) => {
  const UPDATE_PRIMO_LIKES = gql`
    mutation updatePrimo($userId: ID!, $likedStories: [ID]!) {
      updatePrimo(id: $userId, data: { liked_stories: $likedStories }) {
        data {
          id
          attributes {
            liked_stories {
              data {
                id
              }
            }
          }
        }
      }
    }
  `;
  try {
    const result = await apolloClient.mutate({
      mutation: UPDATE_PRIMO_LIKES,
      variables: {
        userId,
        likedStories,
      },
    });
    const savedLikedStories =
      result?.data?.updatePrimo?.data?.attributes?.liked_stories?.data?.map(
        (x) => x.id
      );
    return savedLikedStories; // ? savedLikedStories : [];
  } catch (error) {
    throw error;
  }
};

export const storyWithSameTagAlreadyLiked = async ({
  userId,
  storyId,
  storyTagsNames,
  ultimateFounderTag,
  nominationYear,
}) => {
  const storyMainTagName = storyTagsNames.find(
    (x) => x !== ultimateFounderTag.name
  );
  const { likedStoriesTags } = await getLikedStories(userId, nominationYear);

  const alreadyLikedWithSameMainTag = likedStoriesTags.find((x) => {
    const hasSameTag = x.tags?.find((t) => t.name === storyMainTagName);
    return !!hasSameTag;
  });

  return alreadyLikedWithSameMainTag;
};

export const likeStory = async ({
  userId,
  storyId,
  storyTagsNames,
  ultimateFounderTag,
  storyLikes,
  nominationYear,
}) => {
  //Even if we have likedSotries on font client, we will base our action on value on the server.
  let { likedStories, likedStoriesTags } = await getLikedStories(
    userId,
    nominationYear
  ); //the stories liked by the logged user (userId)
  let storyLikesNumber = storyLikes; //number of user liking the story (storyId)

  //Check if a story of the same category (with same tags) is already liked
  const storyMainTagName = storyTagsNames.find(
    (x) => x !== ultimateFounderTag.name
  );
  const alreadyLikedWithSameMainTag = likedStoriesTags.find((x) => {
    const hasSameTag = x.tags?.find((t) => t.name === storyMainTagName);
    return !!hasSameTag;
  });

  if (!alreadyLikedWithSameMainTag && !likedStories.includes(storyId)) {
    likedStories.push(storyId);
    likedStories = await updateLikes({ userId, likedStories: likedStories });

    //Now we need to update the number of likes related to the stroy
    storyLikesNumber = await updateLikesNumber({ storyId });
  }

  return { likedStories, storyLikesNumber, alreadyLikedWithSameMainTag };
};
export const unlikeStory = async ({
  userId,
  storyId,
  storyLikes,
  nominationYear,
}) => {
  //Even if we have likedSotries on font client, we will base our action on value on the server.
  let { likedStories } = await getLikedStories(userId, nominationYear); //the stories liked by the logged user (userId)
  let storyLikesNumber = storyLikes; //number of user liking the story (storyId)

  if (likedStories.includes(storyId)) {
    likedStories = likedStories.filter((x) => x !== storyId);
    likedStories = await updateLikes({ userId, likedStories: likedStories });

    //Now we need to update the number of likes related to the stroy
    storyLikesNumber = await updateLikesNumber({ storyId });
  }
  return { likedStories, storyLikesNumber };
};
