import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { string } from 'yup';
import { useFormik } from 'formik';
import { maxFilesNumber } from "./../../serverConfig";
import { Col, Grid, Row } from 'react-flexbox-grid';
// import shortid from 'shortid';

// Components
import Button from '../button';
import { useValidateStep } from './helpers';
import StatusMessage from '../status-message';
import { useScrollTop } from '../../helpers/hooks';
import MultiLinksSelector from './multi-links-selector';
import { isFeatureAvailable } from '../../helpers/common';
import { StrapiUploadDropZone } from "./uploadDropZone";
import "./tell-your-story-wizard.scss"
import { Icons } from '../icon';
import { MultipleFiles } from './MultipleFiles';

import { getStoryMedia, removeFile, updateStoryWithLinks } from "./gqlQueries";

function areLinksValid(values) {
  let hasErrors = false;
  let hasNotEmpty = false;
  values.links.forEach(item => {
    try {
      if (item.url.trim()) {
        hasNotEmpty = true;
      }
      string()
        .trim()
        .url('Enter a valid URL')
        .validateSync(item.url);
    } catch (e) {
      hasErrors = true;
    }
  });

  return !hasErrors && hasNotEmpty;
}

const CoverImage = ({ url, onRemove }) => {
  return <div className='cover-image'>

    <img alt="Cover preview" src={url} />
    <div onClick={onRemove}
      className='close-preview'>
      {Icons.CLOSE}
    </div>
  </div>;
}



export const validate = values => {
  const errors = {};
  return errors; //No validation of this page for the moment.

  if (!values.coverImage) {
    errors.coverImage = 'Please select cover image for your story.';
  }

  if ((!values?.attachment?.length) && !areLinksValid(values)) {
    errors.attachment = 'Please select at least one photo or video for your story.';
  }

  if (values.links) {
    values.links.forEach(item => {
      try {
        string()
          .trim()
          .url('Enter a valid URL')
          .validateSync(item.url);
        if (errors.links) {
          delete errors.links[item.id];
          if (Object.keys(errors.links).length === 0) {
            delete errors.links;
          }
        }
      } catch (e) {
        if (errors.links) {
          errors.links[item.id] = e.message;
        } else {
          errors.links = {
            [item.id]: e.message
          };
        }
      }
    });
  }

  return errors;
};

const WizardStepThree = ({
  storyId,
  onGoNext
}) => {
  const [coverImage, setCoverImage] = useState(null);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [uploading, setUploading] = useState(false);

  const data = {}; //TODO useSelector(getWizardData);
  const error = null; //TODO useSelector(state => state.wizard.error);

  const maxImageSize = 10000000;//5MB
  const maxVideoSize = 150000000; //150MB


  //Resizing
  useEffect(() => {
    const handleResize = () => {
      // Check if the window width is greater than 800 pixels
      setIsWideScreen(window.innerWidth > 800);
    };

    // Add a resize event listener to monitor window size changes
    window.addEventListener('resize', handleResize);

    // Call the handleResize function once on mount to set the initial value
    handleResize();

    // Cleanup: Remove the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      coverImage: data?.coverImage || null,
      attachment: data?.attachment || null,
      links: [''] //.map((x, i) => ({ id: i, url: x }))
    },
    onSubmit: async (values) => {
      try {
        setUploading(true)

        const linksToUpload = values?.links?.filter(x => x?.startsWith("http://") || x?.startsWith("https://"));
        const uniqueArray = linksToUpload?.filter((item, index, _array) => _array.indexOf(item) === index);
  
        updateStoryWithLinks(storyId, uniqueArray).then(serverLinks => {
          formik.setFieldValue('links', serverLinks?.length ? [...serverLinks, ''] : ['']);
          onGoNext(storyId);
        });
      } catch (error) {
        //handle error
        console.error(error.message);
      }
      finally {
        setUploading(false)
      }
    },
    validate
  });

  useEffect(() => {
    getStoryMedia(storyId).then(result => {
      setCoverImage(result?.coverImage);
      setAttachedFiles(result?.attachedFiles);
      // setUrls([...result?.videoLinks, '']);
      formik.setFieldValue('links', !!result?.videoLinks?.length ? [...result?.videoLinks, ''] : ['']);
    });
  }, [storyId]);


  useValidateStep(3);
  useScrollTop(null, '.bc-tell-story-wizard', false);

  const getUrl = (item) => {
    let url = item.formats?.small?.url ?? item.formats?.medium.url ?? item.formats?.thumbnail.url ?? item.url;
    return url ? `${process.env.REACT_APP_API_BASE_URL}${url}` : null;
  }
  const handleUploadedCoverImage = (files) => {
    const file = files?.length ? files[0] : null;
    if (!file) {
      return;
    }
    setCoverImage({ id: file.id, url: getUrl(file) });
  }
  const handleUploadedAttaches = (files) => {
    const items = files.map(x => {
      const mimeParts = x.mime?.split('/');
      const _url = (mimeParts?.length > 1 && mimeParts[0] === 'image')
        ? x.formats?.small?.url ?? x.formats?.medium.url ?? x.formats?.thumbnail.url ?? x.url
        : x.url;

      const url = _url ? `${process.env.REACT_APP_API_BASE_URL}${_url}` : null;
      return { id: x.id, mime: x.mime, url }
    });
    setAttachedFiles([...attachedFiles, ...items]);
  }
  const handleRemoveCoverImage = () => {
    if (coverImage?.id) {
      removeFile(coverImage.id).then(result => {
        setCoverImage(null);
      });
    }
  }
  const handleRemoveFile = (fileId) => {
    if (fileId) {
      removeFile(fileId).then(result => {
        const newList = attachedFiles.filter(x => x.id !== fileId);
        setAttachedFiles(newList);
      });
    }
  }
  const handleUploadError = (error)=>{
    if(error?.message?.length){
      //Better Handling ERROR
    }
  }

  return (
    <Grid className='wizard-step__wrapper'>
      <Row center='xs'>
        <Col xs={10}>
          <form onSubmit={formik.handleSubmit}>
            <div className='step-title'>ATTACHMENTS</div>

            <div className={isWideScreen ? 'files-drop-zones' : ""}>
              <div className='files-drop-item'>

                <div className="drop-zone-label">Cover image</div>
                {coverImage && <CoverImage url={coverImage?.url} onRemove={handleRemoveCoverImage} />}
                {!coverImage &&
                  <StrapiUploadDropZone
                    strapiCollectionRef="api::story.story"
                    strapiFieldName="cover_image"
                    strapiItemIdInCollection={storyId}
                    //
                    label="Cover image"
                    text="Drop and drop your cover image here or browse your files..."
                    maxSize={maxImageSize}//5MB
                    onUploaded={uploaded => handleUploadedCoverImage(uploaded)}
                  />
                }
              </div>
              <div className='files-drop-item'>
          
                <div className="drop-zone-label">Images & Videos</div>
                <StrapiUploadDropZone
                  strapiCollectionRef="api::story.story"
                  strapiFieldName="attached_files"
                  strapiItemIdInCollection={storyId}
                  //
                  label="Images & Videos"
                  text="Drag and drop your files here or click to browse for files...."
                  multiple
                  maxFilesNumber={maxFilesNumber - (attachedFiles?.length ?? 0)}
                  maxSize={maxVideoSize}//250MB
                  onUploaded={handleUploadedAttaches}
                  onError={handleUploadError}
                />
              </div>
            </div>

            {attachedFiles?.length > 0 &&
              <>
                <div className="drop-zone-label">Files Uploaded</div>
                <MultipleFiles files={attachedFiles} onRemove={fileId => handleRemoveFile(fileId)} />
              </>
            }

            {/* <div className='step-element'>
              <CoverImageSelector
                error={formik.touched.coverImage && formik.errors.coverImage}
                onChange={value => {
                  formik.setFieldTouched('coverImage', true);
                  formik.setFieldValue('coverImage', value);
                }}
                selected={formik.values.coverImage} />
            </div> */}
            {/* <div className='step-element'>
              <MultipleImagesSelector
                error={formik.touched.attachment && formik.errors.attachment}
                onChange={value => {
                  formik.setFieldTouched('attachment', true);
                  formik.setFieldValue('attachment', value);
                }}
                selected={formik.values.attachment} />
            </div> */}


            {isFeatureAvailable(0) && (
              <>
                <div className='step-separator-or' />
                <div className='step-element'>
                  <MultiLinksSelector
                    error={formik.touched.links && formik.errors.links}
                    onChange={array => {
                      formik.setFieldTouched('links', true);
                      formik.setFieldValue('links', array);
                    }}
                    selected={formik.values.links} />
                </div>
              </>
            )}


            <StatusMessage show={!!error} message={error} status='error' />
            <div className='step-submit'>
              <Button 
                newDesign 
                disabled={uploading}
                onClick={formik.handleSubmit} 
                label='Send story' 
                size='auto' 
              />
            </div>
          </form>
        </Col>
      </Row>
    </Grid>
  );
};

WizardStepThree.propTypes = {
  onGoNext: PropTypes.func.isRequired
};

export default WizardStepThree;
