import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Icons } from '../icon';

import './status-message.scss';

const StatusMessage = ({
  show,
  status,
  message
}) => {
  if (!show) {
    return null;
  }

  let icon;
  switch (status) {
    case 'success': {
      icon = Icons.SUCCESS_BIG;
      break;
    }
    case 'error': {
      icon = Icons.ERROR_BIG;
      break;
    }
    default: {
      icon = Icons.INFO_BIG;
    }
  }
  return (
    <div className='bc-status-message'>
      <div className='status-message__wrapper'>
        {icon}
        <div>{message}</div>
      </div>
      <div className={clsx('status-bar', status)} />
    </div>
  );
};

StatusMessage.defaultProps = {
  show: false,
  message: ''
};

StatusMessage.propTypes = {
  status: PropTypes.oneOf([
    'success',
    'error',
    'info'
  ]).isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  show: PropTypes.bool
};

export default StatusMessage;
