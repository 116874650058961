import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Helpers
import { isInternetExplorer } from "../../helpers/common";

import "./button.scss";
import { useEventListener } from "../../helpers/hooks";

const Button = ({
  label,
  classNames,
  disabled,
  theme,
  onClick,
  children,
  type,
  size,
  newDesign,
}) => {
  const buttonRef = React.useRef();
  /**
   * Generate button content.
   * If no children provided label property will be shown
   */
  const buttonContent = () => {
    if (!children) {
      return <span className="label">{label}</span>;
    }
    return React.isValidElement(children) ? (
      children
    ) : (
      <span className="label">{children}</span>
    );
  };

  const isIe = isInternetExplorer();

  const handleKeyUp = React.useCallback(
    (e) => {
      if (e.key === "Enter" && buttonRef.current === document.activeElement) {
        onClick(e);
      }
    },
    [buttonRef, onClick]
  );

  useEventListener("keyup", handleKeyUp);

  return (
    <div
      className={clsx("bc-button", theme, type, classNames, size, {
        disabled,
        newDesign,
      })}
      ref={buttonRef}
      role="button"
      tabIndex={0}
      onClick={!disabled ? onClick : undefined}
    >
      <div className="bc-button_background" />
      {buttonContent()}
      {isIe ? (
        <svg width="500" height="200" overflow="hidden">
          <defs>
            <filter id="blur" x="-100%" y="-150%" width="400" height="400">
              <feGaussianBlur stdDeviation="40" />
            </filter>
          </defs>
          <rect
            width="100"
            height="20"
            filter="url(#blur)"
            x="200"
            y="100"
            fill="#17A0A1"
          />
        </svg>
      ) : (
        <div className="shadow blur" />
      )}
    </div>
  );
};

// Button.defaultProps = {
//   disabled: false,
//   classNames: "",
//   theme: "default",
//   type: "primary",
//   children: null,
//   label: "",
//   size: "auto",
// };

// Button.propTypes = {
//   label: PropTypes.string,
//   onClick: PropTypes.func.isRequired,
//   classNames: PropTypes.string,
//   theme: PropTypes.string,
//   disabled: PropTypes.bool,
//   children: PropTypes.node,
//   type: PropTypes.oneOf(["primary", "secondary"]),
//   size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "auto"]),
// };

export default Button;
