import React, { useState, useEffect, useRef } from "react";
import { VideoPlayer } from "../../../components/video-player";
import ReactPlayer from "react-player";

const EmbeddedVideo = ({ videoUrl }) => {
  const playerRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  //   return <VideoPlayer url={videoUrl} isSelected />;

  useEffect(() => {
    // Démarrer la vidéo une fois que la page est chargée
    if (playerRef.current) {
      playerRef.current.seekTo(0);
      playerRef.current.play();
    }
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            // src="https://player.vimeo.com/video/744618279?h=d7c4aa24d7&badge=0&autopause=0&player_id=0&app_id=58479"
            src={videoUrl}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="Video"
          ></iframe>
        </div>

        {/* <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          width="100%"
          height="100%"
          playing={true} // Démarrage automatique (true) ou manuel (false)
          controls // Afficher les contrôles de la vidéo
        /> */}
      </div>
      {/* <div>
        <ReactPlayer
          url={videoUrl}
          //   controls
          playing
          onError={() => setError(true)}
          onReady={() => setLoaded(true)}
        />
      </div> */}
    </>
  );
};

export default EmbeddedVideo;
