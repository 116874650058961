import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import shortId from 'shortid';
import debounce from 'lodash.debounce';

// Components
import { Icons } from '../icon';
import './search-input.scss';
import { useEventListener, useOnClickOutside } from '../../helpers/hooks';

import { getUsersStartingWith } from "../../auth/MsGraphApiCall";
// Actions
//TODO import { fetchApi } from '../../actions/api.tp';
function fetchApi(url, action, method, params, options, apiKey) { return null; }

// Constants
// const MS_GRAPH_ENDPOINT = process.env.REACT_APP_MS_GRAPH_ENDPOINT;
const MS_GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0";
const EMPTY_RESULT = { displayName: '', mail: '' };

let lastQueryId;

const SearchInput = ({
  label,
  error,
  // onBlur,
  onChange,
  value //{displayName,country,mail}
}) => {
  const containerRef = useRef();

  const inputRef = React.useRef();
  const [focused, setFocused] = React.useState(false);
  const [filled, setFilled] = React.useState(!!value?.mail?.length);
  const [loading, setLoading] = React.useState(false);

  const [users, setUsers] = React.useState([]);
  const [selected, setSelected] = useState(value?.displayName);

  useEffect(() => {
    setSelected(value?.displayName);
    setFilled(!!value?.mail?.length);
  }, [value]);

  const searchForUsers = async (val) => {
    try {
      const result = await fetchApi(
        `${MS_GRAPH_ENDPOINT}/users`,
        { type: '[MS GRAPH] SEARCH_FOR_USERS' },
        'GET', {
        $filter: `startswith(displayName,'${val}') or startswith(mail,'${val}')`,
        $select: 'displayName,mail,id,country'
      });
      return result;
    } catch (error) {
      throw error;
    }
  }

  const delayedQuery = useRef(
    debounce(val => {
      const queryId = "sdfsdfsfds"; //TODO   shortId.generate();
      lastQueryId = queryId;
      setLoading(true);
      getUsersStartingWith(val, 40).then(response => {
        if (response.value && lastQueryId === queryId) {
          setLoading(false);
          setUsers(response.value.map((user, i) => {
            // const key = "sdfsfdsfdsfd"; //TODO lib shortId ==> shortId.generate();
            let { displayName } = user;
            displayName = displayName && displayName.replace
              ? displayName.replace('ext_', '').replace('EXT_', '')
              : displayName;
            return {
              ...user,
              displayName,
              i
            };
          }));
        }
      });
    }, 500)
  ).current;

  const handleChange = useCallback(e => {
    const val = e.target.value;
    setSelected(val);
    if (val.trim() !== '') {
      delayedQuery(e.target.value);
    }
  }, [delayedQuery]);

  const handleClickOutside = React.useCallback(() => {
    setUsers([]);
    setSelected(value?.displayName);
    setFocused(false);
  }, [value]);

  const handleKeyUp = React.useCallback(e => {
    if (e.key === 'Enter' && inputRef.current === document.activeElement && selected.trim() === '') {
      onChange(EMPTY_RESULT);
    }
    if (e.key === 'Escape' && inputRef.current === document.activeElement) {
      inputRef.current.blur();
      handleClickOutside();
    }
  }, [onChange, selected, handleClickOutside]);

  useOnClickOutside(containerRef, handleClickOutside);
  useEventListener('keyup', handleKeyUp);

  return (
    <div className='bc-search-input' ref={containerRef}>
      <div className={clsx('bc-input__wrapper', {
        focused, error: !!error, filled
      })}>
        <div className='label'>{label}</div>
        <div className='label-placeholder'>Placeholder</div>
        <input
          ref={inputRef}
          type='text'
          onFocus={() => setFocused(true)}
          value={selected}
          className={clsx(({ empty: selected === '' }))}
          placeholder=''
          onChange={handleChange} />
        {loading && (
          <div className='search-loader'>
            <svg width={24} height={24}>
              <circle cx={12} cy={12} r={9} />
            </svg>
          </div>
        )}
      </div>
      {!!error && (
        <div className='bc-input__status'>
          <div>{Icons.ERROR}</div>
          <div>{error}</div>
        </div>
      )}
      {users && !!users.length && (
        <div className='bc-search-input__users-result-wrapper'>
          {users.map(user => (
            <div
              key={user.id}
              onClick={() => {
                // setSelected(user.displayName);
                setUsers([]);
                onChange(user);
              }}
              className='bc-search-input__users-result-item'>
              <h5>{user.displayName}</h5>
              <div>{user.mail}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

SearchInput.defaultProps = {
  error: null,
  value: ' '
};

SearchInput.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  // onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({})
};

export default SearchInput;
