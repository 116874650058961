import { useMutation, gql } from "@apollo/client";
import { useState } from "react";

const UPLOAD_FILE_MUTATION = gql`
  mutation CreateUploadFile($file: Upload!) {
    upload(file: $file) {
      data {
        id
      }
    }
  }
`;
const DETELE_FILE_MUTATION = gql`
  mutation DeleteUploadFile($id: ID!) {
    deleteUploadFile(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation($input: LocationInput!) {
    createLocation(data: $input) {
      data {
        id
        attributes {
          description
          fun_facts
        }
      }
    }
  }
`;

export const UploadStory = () => {
  const [upload] = useMutation(UPLOAD_FILE_MUTATION);
  const [deleteUploadFile] = useMutation(DETELE_FILE_MUTATION);
  const [createLocation] = useMutation(CREATE_LOCATION_MUTATION);
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const fileInput = document.getElementById("coverImage");
    // const file = fileInput.files[0];

    try {
      const uploadResponse = await upload({
        variables: { file: image },
      });
      const imageId = uploadResponse?.data?.upload?.data?.id;

      try {
        const createLocationResponse = await createLocation({
          variables: {
            input: {
              name: e.target.title.value,
              description: e.target.description.value,
              fun_facts: {},
              cover_image: imageId,
            },
          },
        });

        console.log(
          "Location created:",
          createLocationResponse.data.createLocation.data
        );
      } catch (locationCreationError) {
        try {
          //If the location is not created properly then remove the uploaded image: it is on transaction!
          const errdfd = await deleteUploadFile({ variables: { id: imageId } });
          console.log(errdfd);
        } catch (deleteError) {
          if (deleteError.graphQLErrors[0].extensions.code === "FORBIDDEN") {
            //Do nothing! : The destroy of file are not allowed on the server side
          } else {
            throw deleteError;
          }
        }
        throw locationCreationError;
      }
    } catch (error) {
      //TODO: handle error
      throw error;
    }
  };

  const onImageChange = (event) => {
    console.log(event.target.files);

    setImage(event.target.files[0]);
  };
  return (
    <form onSubmit={handleSubmit}>
      <label>
        Title:
        <input type="text" name="title" />
      </label>
      <br />
      <label>
        Description:
        <input type="text" name="description" />
      </label>
      <br />
      <label>
        Cover Image:
        <input
          type="file"
          id="coverImage"
          name="coverImage"
          onChange={onImageChange}
        />
      </label>
      <br />
      <button type="submit">Submit</button>
    </form>
  );
};
