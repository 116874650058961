import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './footer.scss';

const Footer = ({ children, theme }) => (
  <div className={clsx('bc-footer', theme)}>{children}</div>
);

Footer.defaultProps = {
  theme: 'light'
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['dark', 'light'])
};

export default Footer;
