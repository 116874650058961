import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import Draggable from 'react-draggable';

import './text-area.scss';
import { useEventListener, useOnClickOutside } from '../../helpers/hooks';
import { Icons } from '../icon';

/**
 * Displays text area
 * @param outsideLabel
 * @param status
 * @param statusMessage
 * @param disabled
 * @param height
 * @param value
 * @param onChange
 * @param placeholder
 * @returns {*}
 * @constructor
 */
const TextArea = ({
  label,
  error,
  status,
  disabled,
  height,
  value,
  onBlur,
  onChange,
  placeholder,
  defaultValue
}) => {
  const containerRef = React.useRef();
  const [focused, setFocused] = React.useState(false);
  const [filled, setFilled] = React.useState(!!value);
  const [deltaPosition, setDeltaPosition] = useState(0);
  const inputRef = useRef(null);

  const handleClickOutside = React.useCallback(() => {
    if (value) {
      setFilled(true);
    } else {
      setFilled(false);
    }
    setFocused(false);
  }, [value]);

  const handleKeyUp = React.useCallback(e => {
    if (e.key === 'Escape' && inputRef.current === document.activeElement) {
      inputRef.current.blur();
    }
  }, []);

  useEventListener('keyup', handleKeyUp);
  useOnClickOutside(containerRef, handleClickOutside);
  useEffect(() => {
    if (focused) {
      inputRef.current.focus();
    }
  }, [focused]);

  const containerClasses = classnames(
    'bc-text-area-container',
    { disabled, error: !!error }
  );

  const wrapperClasses = classnames(
    'bc-text-area-wrapper',
    {
      'wrapper-focused': focused,
      filled,
      // disabled,
      error: status === 'error',
      success: status === 'success'
    }
  );

  const handeDrag = useCallback((e, ui) => {
    setDeltaPosition(deltaPosition + ui.deltaY);
  }, [deltaPosition]);

  return (
    <div className={containerClasses} ref={containerRef}>
      <div
        style={{ height: height + deltaPosition }}
        onClick={() => !disabled && setFocused(true)}
        className={wrapperClasses}>
        <div className='label'>{label}</div>
        <textarea
          disabled={disabled}
          style={{ minHeight: 'auto', height: '100%' }}
          ref={inputRef}
          onFocus={() => {
            containerRef.current.scrollIntoView(true);
            setFocused(true);
          }}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          onKeyDown={e => e.stopPropagation()}
          placeholder={placeholder}
          defaultValue={defaultValue} />
        <Draggable
          axis='y'
          onDrag={handeDrag}
          bounds={{ top: 0, left: 0, right: 0 }}>
          <div className='resize-handle'>
            <svg width={32} height={32}>
              <line x1={16} y1={0} x2={0} y2={16} />
              <line x1={16} y1={8} x2={8} y2={16} />
            </svg>
          </div>
        </Draggable>
      </div>
      {!!error && (
        <div className='bc-input__status'>
          <div>{Icons.ERROR}</div>
          <div>{error}</div>
        </div>
      )}
    </div>
  );
};

TextArea.defaultProps = {
  height: 116,
  label: null,
  error: null,
  status: null,
  onBlur: undefined,
  disabled: false,
  value: '',
  onChange: undefined,
  placeholder: '',
  defaultValue: ''
};

TextArea.propTypes = {
  height: PropTypes.number,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onBlur: PropTypes.func
};

export default TextArea;
