import React from "react";
import PropTypes from "prop-types";
import { Animate } from "react-move";
import { easeQuadOut } from "d3-ease";
import clsx from "clsx";
import { Grid, Row, Col } from "react-flexbox-grid";

import { interpolation } from "../../helpers/common";

import map from "../../assets/images/loader-bg/map.jpg";
import logo from "../../assets/images/logo-new-black.png";

import "./loader.scss";

const Loader = ({ show, mapView, startOpacity, showProgress }) => (
  <>
    <div
      data-testid="loader"
      style={{
        // display: show ? 'block' : 'none',
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "absolute",
        top: 0,
        left: 0,
        backdropFilter: show ? "blur(3px)" : "none",
        pointerEvents: show ? "all" : "none",
      }}
    >
      <Animate
        show={show}
        start={{ opacity: startOpacity }}
        enter={{
          opacity: [0.68],
          timing: { duration: 750, delay: 0, ease: easeQuadOut },
        }}
        update={{
          opacity: [0.68],
          timing: { duration: 750, ease: easeQuadOut, delay: 0 },
        }}
        leave={[
          {
            opacity: [0],
            timing: { delay: 0, duration: 500, ease: easeQuadOut },
          },
        ]}
        interpolation={interpolation}
      >
        {({ opacity }) => (
          <div
            className={clsx("bc-loader__container", { animated: mapView })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            style={{
              opacity,
              backgroundImage: mapView ? `url(${map})` : "none",
            }}
          >
            {mapView && (
              <>
                <div className="svg-container bc-loader__path path1" />
                <div className="bc-loader__path path2" />
                <div className="bc-loader__path path3" />
                <div className="bc-loader__path path4" />
                <div className="bc-loader__path path5" />
                <div className="bc-loader__path path6" />
              </>
            )}
            <div className={clsx("bc-loader__logo", { animated: !mapView })}>
              <img src={logo} alt="Logo" />
            </div>
            {!!showProgress && (
              <div style={{ width: "100%" }}>
                <Grid>
                  <Row center="xs">
                    <Col xs={10} md={4} lg={4}>
                      <div className="bc-loader__progress">
                        <div
                          className="bc-loader__progress-bar"
                          style={{ width: `${showProgress}%` }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </div>
            )}
          </div>
        )}
      </Animate>
    </div>
  </>
);

Loader.defaultProps = {
  mapView: false,
  startOpacity: 0,
  showProgress: 0,
};

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
  mapView: PropTypes.bool,
  startOpacity: PropTypes.number,
  showProgress: PropTypes.number,
};

export default Loader;
