import { Switch } from "../../../blent-system/Switch"
import styles from "./filter.module.scss";

export const StoriesFilter = ({ value, onChange }) => {

    return (<div className={styles.filterWrapper}>
        <div className={styles.winnerFilterW}>
            <Switch
                size="s"
                name="inputName"
                value={value.onlyWinners}
                onChange={(v) => {
                    onChange({ ...value, onlyWinners: v })
                }}
            />
            <div className={styles.winnerFilterLabel}>Only winner</div>
        </div>
    </div>);
}
