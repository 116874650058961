import { useMutation, useQuery, gql } from "@apollo/client";
import { apolloClient } from "../../../index";

export const UPLOAD_FILE_MUTATION = gql`
  mutation CreateUploadFile(
    $refId: ID
    $ref: String
    $field: String
    $file: Upload!
  ) {
    upload(refId: $refId, ref: $ref, field: $field, file: $file) {
      data {
        id
        attributes {
          mime
          formats
          size
          url
        }
      }
    }
  }
`;
export const MULTIPLE_UPLOAD_MUTATION = gql`
  mutation uploadMultipleFiles(
    $refId: ID
    $ref: String
    $field: String
    $files: [Upload]!
  ) {
    multipleUpload(refId: $refId, ref: $ref, field: $field, files: $files) {
      data {
        id
        attributes {
          mime
          formats
          size
          url
        }
      }
    }
  }
`;
export const DETELE_FILE_MUTATION = gql`
  mutation DeleteUploadFile($id: ID!) {
    deleteUploadFile(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;
export const FILE_INFO_QUERY = gql`
  query GetMediaInfo($id: ID!) {
    uploadFile(id: $id) {
      data {
        id
        attributes {
          formats
        }
      }
    }
  }
`;
export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation($input: LocationInput!) {
    createLocation(data: $input) {
      data {
        id
      }
    }
  }
`;

export const filesUploadToStory = async ({
  strapiCollectionRef,
  strapiFieldName,
  strapiItemIdInCollection,
  files,
}) => {
  try {
    const { error, data } = await apolloClient.mutate({
      mutation: MULTIPLE_UPLOAD_MUTATION,
      variables: {
        refId: strapiItemIdInCollection,
        ref: strapiCollectionRef,
        field: strapiFieldName,
        files,
      },
    });
    if (error) throw error;
    //else
    return data?.multipleUpload
      ?.map((x) => x.data)
      .map((x) => ({ id: x.id, ...x?.attributes }));
  } catch (error) {
    //handle error
    console.error(error.message);
    throw error;
  }
};
