import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import logoStyles from "./logo.module.scss";

const NavbarLogo = () => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx("bc-logo__wrapper", { clickable: true })}
      onClick={() => navigate("/")}
    >
      <div className={logoStyles.logoContainer} />
    </div>
  );
};

export default NavbarLogo;
