import React, { useContext } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import ProgressiveImage from "react-progressive-graceful-image";
import { Icons } from "../../../components/icon";
import { StoryContext } from "../explore-family-story-renderer";
import { AnimatedContent } from "../../../components/animated-content";

const ExploreStoryHeaderContent = ({ theme, coverImage, title, period }) => {
  return (
    <>
      <ProgressiveImage src={coverImage} placeholder={coverImage}>
        {(src) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0 }}
            className="bc-explore-story__header-background-image"
            style={{ backgroundImage: `url(${src})` }}
          />
        )}
      </ProgressiveImage>
      <motion.div
        className="bc-explore-story__header-background-overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.7 }}
        transition={{ duration: 3, delay: 0.35, ease: "backOut" }}
      />
      <div className="bc-explore-story__title-block" data-testid="title-block">
        <div className="bc-explore-story__title-block-row">
          <AnimatedContent className="title uppercase">{title}</AnimatedContent>
        </div>
        <div className="bc-explore-story__title-block-row">
          <AnimatedContent translate={0} delay={0.7} custom={1}>
            <div className="bc-explore-story__title-block-row_wrapper">
              <span style={{ fontSize: "1.3em" }}>{period}</span>
              {/* <span className="avatar">{Icons.BAT_OUTLINED}</span>
              <span className="label simple">{period}</span>
              <span className="calendar" style={{ paddingLeft: "26px" }}>
                {Icons.CALENDAR}
              </span>
              <span className="label simple">{period}</span> */}
            </div>
          </AnimatedContent>
        </div>
      </div>
    </>
  );
};

ExploreStoryHeaderContent.defaultProps = {
  rank: 0,
  nominatedAuthor: null,
};

ExploreStoryHeaderContent.propTypes = {
  coverImage: PropTypes.string.isRequired,
  coverImageThumb: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rank: PropTypes.number,
  nominatedAuthor: PropTypes.string,
};

export default ExploreStoryHeaderContent;
