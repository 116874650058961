import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Grid, Row, Col } from "react-flexbox-grid";
import clsx from "clsx";

// Components
import { Icons } from "../../icon";
import WizardNavbarSteps from "./wizard-navbar-steps";
import NavButton from "../../nav-button";
import NavbarLogo from "../navbar-logo";

// Styles
import "../navbar.scss";
import styles from "../navbar.module.scss";

const WizardNavbarOriginal = ({ showClose, step }) => {
  const navigate = useNavigate();
  return (
    <nav className={clsx("bc-navbar wizard")}>
      <Grid>
        <Row className="bc-navbar-row">
          <Col xs={12}>
            <div className="bc-navbar__content-wrapper">
              <NavbarLogo />
              <div className={styles.buttonsWrapper}>
                {showClose && step !== 3 && (
                  <div
                    className="bc-icon-button"
                    onClick={() => navigate("/homepage")}
                  >
                    <span className="label">CLOSE</span>
                    <span>{Icons.CLOSE}</span>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    </nav>
  );
};

// WizardNavbarOriginal.defaultProps = {
//   showClose: false
// };

// WizardNavbarOriginal.propTypes = {
//   showClose: PropTypes.bool,
//   steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired
// };

export default React.memo(WizardNavbarOriginal);
