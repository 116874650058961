import React, { useEffect, useState, useContext } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import NotFoundPage from "./page/error/NotFoundPage";
import ErrorPage from "./page/error/ErrorPage";
import Homepage from "./page/Homepage";
import Purposes from "./page/purposes";
import Stories from "./page/all-stories";
import PastWinners from "./page/past-winners";
import LikedStories from "./page/liked-stories";
import FamilyFounderPage from "./page/family-founder-page";
import CommitteeStories from "./page/committee-stories";

import { AuthContext } from "./auth/AuthProvider";

import ExploreStory from "./page/explore-story";
import Loader from "./components/loader";
import { isCommitteeMember } from "./api";

import { UploadLocation, UploadStory } from "./page/uploads";

import { BestStories, BestStoriesWinners } from "./page/best-stories";

import TellYourStoryWizard from "./components/tell-your-story-wizard";
// const TellYourStoryWizard = lazy(() =>
//   import('./components/tell-your-story-wizard')
// );

export const AppSettingsContext = React.createContext();
const SETTINGS_QUERY = gql`
  query GetSettings {
    preference {
      data {
        attributes {
          site_phase
          liking_enabled
          show_number_of_likes
          download_link
          nomination_year
          global_newsletter_sent
          ultimate_founder_tag {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

function AppBody() {
  const navigate = useNavigate();
  const [globalSettings, setGlobalSettings] = useState(null);
  const { email } = useContext(AuthContext);

  const { loading, error, data } = useQuery(SETTINGS_QUERY);

  useEffect(() => {
    if (data) {
      const _globalSetting = data?.preference?.data?.attributes
        ? {
            ...data?.preference?.data?.attributes,
            ultimate_founder_tag: {
              id: data?.preference?.data?.attributes?.ultimate_founder_tag?.data
                ?.id,
              name: data?.preference?.data?.attributes?.ultimate_founder_tag
                ?.data?.attributes?.name,
            },
          }
        : null;
      if (_globalSetting?.site_phase === "Compiling") {
        //is it a committee member?
        isCommitteeMember(email).then((val) =>
          setGlobalSettings({ ..._globalSetting, showCommitteeStories: val })
        );
      } else {
        setGlobalSettings(_globalSetting);
      }
    }
  }, [data, email]);
  //if error happened while loading site setting from API: Should not happen!
  if (error) {
    console.error(error.message);
    return navigate("/error");
  }
  if (loading || !globalSettings) {
    return <Loader show />;
  }

  return (
    <AppSettingsContext.Provider value={globalSettings}>
      <Routes>
        <Route
          exact
          path="/"
          element={<Homepage globalSettings={globalSettings} />}
        />

        <Route path="/past-winners" element={<PastWinners />} />
        <Route path="/liked-stories" element={<LikedStories />} />

        {globalSettings?.showCommitteeStories && (
          <Route path="/committee-stories" element={<CommitteeStories />} />
        )}

        {globalSettings?.site_phase === "Nomination" && (
          <Route path="/share-your-story/*" element={<TellYourStoryWizard />} />
        )}

        {globalSettings?.site_phase === "Voting" && (
          <Route
            path="/vote-for-ultimate-founders"
            element={<BestStories globalSettings={globalSettings} />}
          />
        )}

        {globalSettings?.site_phase === "Selection" && (
          <Route
            path="/ultimate-founders-winners"
            element={<BestStoriesWinners />}
          />
        )}
        {globalSettings?.site_phase === "Selection" && (
          <Route
            path="/bacardi-founder/:storyId"
            element={<FamilyFounderPage />}
          />
        )}

        <Route path="/upload-story" element={<UploadStory />} />
        <Route path="/upload-location" element={<UploadLocation />} />

        <Route path="/stories/:storyId" element={<ExploreStory />} />
        <Route
          path="/stories"
          element={<Stories globalSettings={globalSettings} />}
        />
        <Route
          path="/purpose"
          element={<Purposes globalSettings={globalSettings} />}
        />

        <Route path="/locations/:id/:storyId" element={<ExploreStory />} />

        {/* REDIRECTED PATHS */}
        <Route path="/locations/:id" element={<Navigate to="/" replace />} />
        <Route exact path="/locations" element={<Navigate to="/" replace />} />
        {/* REDIRECTED PATHS END */}

        <Route path="/not-found-page" element={<NotFoundPage />} />
        <Route exact path="/error" element={<ErrorPage />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AppSettingsContext.Provider>
  );
}

function App() {
  return (
    <Routes>
      <Route exact path="/error" element={<ErrorPage />} />
      <Route path="*" element={<AppBody />} />
    </Routes>
  );
}
export default App;
