import React, { useState } from "react";
import { UploadDropZone } from "./uploadDropZone";
import { filesUploadToStory } from "./gqlQueries";


/**
 * text: the text to be on the drop zone
 * fileUploaded : {id, formats},
 * onUploaded: callback to update the fileUpladed on change
 */
export const StrapiUploadDropZone = ({
  strapiCollectionRef,
  strapiFieldName,
  strapiItemIdInCollection,
  label,
  text,
  multiple,
  maxFilesNumber,
  maxSize,
  onUploaded,
  onError
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadingError, setUploadingError] = useState(false);

  const handleDopedFile = async (lst) => {
    setUploading(true);
    try {
      const _maxLst = (lst?.length && maxFilesNumber && lst.length > maxFilesNumber) ? lst.slice(0, maxFilesNumber) : lst;//the user can not add an infinit number of files
      const result = await filesUploadToStory({ strapiCollectionRef, strapiFieldName, strapiItemIdInCollection, files: _maxLst });
      if (result.error) throw result.error;
      // else 
      onUploaded(result);
    } catch (error) {
      setUploadingError(true);
      console.error(error);
      onError(error);
    } finally {
      setUploading(false);
    }
  };

  const size = (size) => {
    if (size < 1000) return `${size}B`;
    if (size < 1000000) return `${Math.floor(size / 1000)}KB`;
    else return `${Math.floor(size / 1000000)}MB`;
  }
  const handleRejectedFiles = (rejectedFiles) => {
    setUploadingError(true)
    console.error("Uploading error: Rejected files...")
  }
  return (
    <>
      <UploadDropZone
        text={text}
        label={label}
        multiple={multiple}
        maxSize={maxSize}
        uploading={uploading}
        onDroped={handleDopedFile}
        onRejectedFiles={handleRejectedFiles}
      />
      {maxSize && <div className="file-size-info">
        The file size must not exceed {size(maxSize)}.
      </div>}
      {uploadingError && <div className="file-upload-error">
        Error uploading file. Check format and size.
      </div>}
    </>
  );
};
