import { gql } from "@apollo/client";
import { apolloClient } from "../index";

export const updateMmmUser = async ({ email, userName, locationCode }) => {
  const getMmmUser = async (email) => {
    const GET_USER_BY_EMAIL = gql`
      query GetPrimo($email: String) {
        primos(filters: { email: { eqi: $email } }) {
          data {
            id
            attributes {
              email
              name
              location {
                data {
                  id
                  attributes {
                    name
                    code
                  }
                }
              }
              liked_stories {
                data {
                  id
                  attributes {
                    tags {
                      data {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    try {
      const result = await apolloClient.query({
        query: GET_USER_BY_EMAIL,
        variables: { email },
      });
      if (result.error) throw result.error;
      //else
      const emails = result?.data?.primos?.data?.map((x) => ({
        id: x.id,
        email: x.attributes?.email,
        name: x.attributes?.name,
        location: x.attributes?.location?.data?.attributes,
        liked_stories: x.attributes?.liked_stories?.data?.map((s) => s.id),
        liked_stories_categories: x.attributes?.liked_stories?.data?.map(
          (s) => ({
            id: s.id,
            categories: s?.attributes?.tags?.data?.map((t) => t.id),
          })
        ),
      }));
      return emails ? emails[0] : null;
    } catch (err) {
      console.error(
        "BACKEND ERROR: The backend server is currently offline or experiencing issues. Please try again later or contact support."
      );
      // window.location.href = "/error";
      throw err;
    }
  };
  const getLocationData = async (locationCode) => {
    try {
      let location = null;
      const nominatimApiUrl = `https://nominatim.openstreetmap.org/search?q=${locationCode}&format=json`;
      const response = await fetch(nominatimApiUrl, {
        headers: { "accept-language": "en" },
      });
      const data = await response.json();

      // Check if the API returned valid data
      if (data.length > 0) {
        const locationName = data[0].display_name;
        const latitude = data[0].lat;
        const longitude = data[0].lon;

        location = { name: locationName, coordinates: [latitude, longitude] };
      } else {
        console.error("Location data not found.");
      }
      return location;
    } catch (error) {
      throw error;
    }
  };
  const createLocation = async (locationCode) => {
    const locationData = await getLocationData(locationCode);

    const CREATE_LOCATION = gql`
      mutation createLocation($data: LocationInput!) {
        createLocation(data: $data) {
          data {
            id
          }
        }
      }
    `;
    try {
      const data = {
        code: locationCode,
        name: locationData ? locationData.name : null,
        coordinates: locationData ? locationData.coordinates : null,
        // description: null,
        publishedAt: new Date(),
      };

      const result = await apolloClient.mutate({
        mutation: CREATE_LOCATION,
        variables: { data },
      });

      if (result.error) throw result.errors;
      return result?.data?.createLocation?.data?.id;
    } catch (error) {
      //handle error
      console.error(error.message);
    }
  };
  const getOrCreateLocation = async (locationCode) => {
    const GET_LOCATION_BY_CODE = gql`
      query getLocationByCode($locationCode: String!) {
        locations(filters: { code: { eqi: $locationCode } }) {
          data {
            id
          }
        }
      }
    `;
    try {
      const result = await apolloClient.query({
        query: GET_LOCATION_BY_CODE,
        variables: { locationCode },
      });
      if (result.error) throw result.error;
      const locationId = result?.data?.locations?.data?.length
        ? result.data.locations.data[0]?.id
        : await createLocation(locationCode);
      return locationId;
    } catch (error) {
      //handle error
      console.error(error.message);
    }
  };
  const _cleanUserName = (userName) => {
    const extPrefix = "ext_";
    const regex = new RegExp(extPrefix, "gi");
    const newName = userName?.replace(regex, "");
    return newName;
  };
  const _createMmmUser = async ({ email, userName, locationCode }) => {
    const locationId = await getOrCreateLocation(locationCode);
    const CREATE_PRIMO = gql`
      mutation createPrimo($data: PrimoInput!) {
        createPrimo(data: $data) {
          data {
            id
          }
        }
      }
    `;
    try {
      const data = {
        email: email,
        name: _cleanUserName(userName),
        location: locationId,
        publishedAt: new Date(),
        last_visit: new Date(),
      };
      const result = await apolloClient.mutate({
        mutation: CREATE_PRIMO,
        variables: { data },
      });

      if (result.error) throw result.errors;
      return result?.data?.createPrimo?.data?.id;
    } catch (error) {
      //handle error
      console.error(error.message);
    }
  };
  const _updateMmmUser = async ({ mmmUser, userName, locationCode }) => {
    const newName = mmmUser.name ? null : _cleanUserName(userName);
    const newLocationId = mmmUser.location
      ? null
      : await getOrCreateLocation(locationCode);

    const UPDATE_PRIMO = gql`
      mutation updatePrimo($id: ID!, $data: PrimoInput!) {
        updatePrimo(id: $id, data: $data) {
          data {
            id
          }
        }
      }
    `;

    if (newName) {
      const result = await apolloClient.mutate({
        mutation: UPDATE_PRIMO,
        variables: {
          id: mmmUser.id,
          data: { name: newName },
        },
      });
    }
    if (newLocationId) {
      try {
        const result = await apolloClient.mutate({
          mutation: UPDATE_PRIMO,
          variables: {
            id: mmmUser.id,
            data: { location: newLocationId },
          },
        });
        if (result.error) throw result.error;
      } catch (error) {
        //handle error
        console.error(error.message);
      }
    }

    const result = await apolloClient.mutate({
      mutation: UPDATE_PRIMO,
      variables: {
        id: mmmUser.id,
        data: { last_visit: new Date() },
      },
    });

    console.log(mmmUser);
  };

  try {
    const mmmUser = await getMmmUser(email);
    if (!mmmUser) {
      _createMmmUser({ email, userName, locationCode });
    } else {
      _updateMmmUser({ mmmUser, userName, locationCode });
    }
    return mmmUser;
  } catch (error) {
    console.error(error);
    return null;
  }
};
