import React from "react";
import PropTypes from "prop-types";
import { Col, Grid, Row } from "react-flexbox-grid";
import { createMarkup } from "./helpers";
import { isFeatureAvailable } from "../../../helpers/common";
import Like from "../../../components/like";
import { AnimatedContent } from "../../../components/animated-content";
import StoryCarousel from "../../../components/story-carousel";
import EmbeddedVideo from "./EmbeddedVideo";

const ExploreStoryContentMobile = React.forwardRef(
  (
    {
      text,
      theme,
      photos,
      videoUrl,
      thumbnailUrl,
      thumbnail_message,
      embedded,
    },
    ref
  ) => {
    const { likeRef } = ref;

    return (
      <>
        {videoUrl && (
          <AnimatedContent
            rootMargin={0}
            threshold={0.1}
            delay={0.35}
            custom={1}
          >
            <EmbeddedVideo videoUrl={videoUrl} />
          </AnimatedContent>
        )}
        <Grid
          className="bc-explore-story__content-mobile"
          style={{ paddingRight: 16, paddingLeft: 16 }}
        >
          {/* {videoUrl && (
            <Row center="xs">
              <Col xs={12}></Col>
            </Row>
          )} */}
          <Row center="xs">
            <Col xs={12}>
              <AnimatedContent
                rootMargin={0}
                threshold={0.1}
                delay={0.35}
                custom={1}
              >
                <div
                  ref={likeRef}
                  dangerouslySetInnerHTML={createMarkup(text)}
                  className="bc-formatted-text"
                />
              </AnimatedContent>
            </Col>
          </Row>

          <Row center="xs">
            <Col xs={12}>
              <StoryCarousel photos={photos} theme={theme} />
            </Col>
          </Row>
        </Grid>
      </>
    );
  }
);

ExploreStoryContentMobile.defaultProps = {
  text: "",
  photos: [],
  videos: [],
  likes: 0,
};

ExploreStoryContentMobile.propTypes = {
  likes: PropTypes.number,
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  theme: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default ExploreStoryContentMobile;
