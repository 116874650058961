import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { UploadDropZone } from "./uploadDropZone";
import { UPLOAD_FILE_MUTATION } from "./../gqlQueries";

/**
 * text: the text to be on the drop zone
 * fileUploaded : {id, formats},
 * onFileUploaded: callback to update the fileUpladed on change
 */
export const StrapiUploadDropZone = ({
  text,
  fileUploaded,
  onFileUploaded,
}) => {
  const [upload] = useMutation(UPLOAD_FILE_MUTATION);
  const [uploading, setUploading] = useState(false);

  const handleDopedFile = async (lst) => {
    try {
      setUploading(true);
      const uploadResponse = await upload({
        variables: { file: lst[0] },
      });
      const uploadedData = uploadResponse?.data?.upload?.data;
      onFileUploaded({
        formats: uploadedData?.attributes?.formats,
        id: uploadedData?.id,
      });
    } catch (error) {
      //TODO: handle error
      throw error;
    } finally {
      setUploading(false);
    }
  };
  return (
    <UploadDropZone
      text={text}
      single={true}
      uploading={uploading}
      filesUploaded={fileUploaded ? [fileUploaded] : []}
      filesDroped={handleDopedFile}
    />
  );
};
