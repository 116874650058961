import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import clsx from 'clsx';

import styles from './best-stories-tabs.module.scss';

const BestStoriesTabs = ({ tabs, activeTab, setActiveTab }) => (
  <Row center='xs'>
    <Col xs={12}>
      <div className={styles.root}>
        {tabs.map(tab => (
          <div
            key={tab.id}
            className={clsx(styles.tab, {
              [styles.active]: +activeTab === +tab.id
            })}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div className={clsx(styles.root, styles.bottom)}>
        {tabs.map(tab => (
          <div
            key={tab.id}
            className={clsx(styles.tabUnderline, {
              [styles.active]: +activeTab === +tab.id
            })}
            onClick={() => setActiveTab(tab.id)}
          >
            <span>{tab.name}</span>
          </div>
        ))}
      </div>
    </Col>
  </Row>
);

BestStoriesTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setActiveTab: PropTypes.func.isRequired
};

export default BestStoriesTabs;
