import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
// Components
import ExploreStoryRenderer from "./explore-story-renderer";
import Loader from "../../components/loader";
import Copyright from "../../components/copyright/copyright";
import Support from "../../components/support";
import Footer from "../../components/footer";
import { useScrollTop } from "../../helpers/hooks";

// Styles
import "./explore-story.scss";

const STORY_QUERY = gql`
query GetStory($id: ID!) {
  story(id:$id){data{id
  	attributes{
      cover_image{data{attributes{formats}}}
      location{data{attributes{name}}}
      attached_files{data{attributes{
        mime
        formats
      	size
        url
      }}}
      video_links
      title
      text
      author_name
      nominated_primo_name
      is_winner
      nomination_year
      likes_number
      rank
      tags{data{id attributes{name}}}
    }
  }}
}
`;

const ExploreStory = () => {
  const navigate = useNavigate();
  const { storyId } = useParams();
  const { loading, error, data } = useQuery(STORY_QUERY, {
    variables: { id: storyId },
  });

  useScrollTop(null, ".bc-explore-story__container", false);

  if (loading) {
    return <Loader show />;
  }
  if (error || !data?.story) {
    //TODO LOG
    // navigate("/error");
    return <div>{error}</div>
  }

  const convert2story = (id, attributes) => {
    if (!attributes) return null;

    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    const _photos = attributes.attached_files?.data?.
      filter(x => x.attributes?.mime.startsWith('image'))?.
      map(x => {
        const _src = x.attributes?.formats?.large?.url ?? x.attributes?.formats?.medium?.url ?? x.attributes?.formats?.small?.url ?? x.attributes?.formats?.thumbnail?.url;
        const _thumbnail = x.attributes?.formats?.thumbnail?.url;
        return { 'src': `${apiBaseURL}${_src}`, 'thumbnail_src': `${apiBaseURL}${_thumbnail}` }
      });

    const _videos = attributes.attached_files?.data?.
      filter(x => x.attributes?.mime.startsWith('video'))?.
      map(x => ({ 'src': `${apiBaseURL}${x.attributes?.url}` }));

    const _videosLinks = attributes?.video_links?.map(x => ({ src: x })) ?? [];
    const _videosAndLinks = [..._videos, ..._videosLinks];

    //else
    return {
      id: id,
      title: attributes?.title,
      text: attributes.text,
      cover_image: attributes.cover_image?.data?.attributes?.formats?.medium?.url ? `${apiBaseURL}${attributes.cover_image.data.attributes.formats.medium.url}` : null,
      cover_image_thumbnail: attributes?.cover_image?.data?.attributes?.formats?.thumbnail?.url,
      location: attributes?.location?.data?.attributes?.name,
      author: attributes?.author_name ?? "",
      nominated_primo_name: attributes?.nominated_primo_name,
      is_winner: attributes.is_winner,
      date: attributes?.nomination_year?.toString(),
      likes: attributes?.likes_number,
      rank: attributes?.rank,
      photos: _photos,
      videos: _videosAndLinks,
      tags: attributes?.tags?.data?.map(x => ({id:x.id, name:x.attributes?.name}))
    }
  }

  if(!data?.story?.data){
    navigate("/not-found-page");
  }

  const story = convert2story(data?.story?.data?.id, data?.story?.data?.attributes);
  return (
    <>
      <ExploreStoryRenderer story={story} />
      <Footer theme="dark">
        <Support />
        <Copyright />
      </Footer>
    </>
  );
};

export default ExploreStory;
