import React from "react";
import PropTypes from "prop-types";
import { Col, Grid, Row } from "react-flexbox-grid";
import clsx from "clsx";

import StoryCarousel from "../../../components/story-carousel";
import Like from "../../../components/like";
import { AnimatedContent } from "../../../components/animated-content";

import { createMarkup } from "./helpers";
import { isFeatureAvailable, getStickyStyles } from "../../../helpers/common";

const ExploreStoryContentOriginal = React.forwardRef(
  (
    {
      text,
      theme,
      photos,
      likes,
      videos,
      id,
      stickyLike,
      storyTagsNames
    },
    ref
  ) => {
    const { likeRef } = ref;

    return (
      <Grid className="bc-explore-story__content">
        <Row center="lg">
          <Col lg={10}>
            <AnimatedContent
              rootMargin={0}
              threshold={0.1}
              delay={0.35}
              custom={1}
            >
              <div
                ref={likeRef}
                dangerouslySetInnerHTML={createMarkup(text)}
                className="bc-formatted-text"
              />
            </AnimatedContent>
          </Col>
        </Row>
        {isFeatureAvailable(1) && (
          <Grid style={getStickyStyles(stickyLike, "72px")}>
            <Row className={clsx("sticky-like")} center="lg">
              <Col lg={10}>
                <Like
                  likesNumber={likes}
                  theme={theme}
                  storyId={id}
                  storyTagsNames={storyTagsNames} 
                />
              </Col>
            </Row>
          </Grid>
        )}
        <Row center="lg">
          <Col lg={10}>
            <div>
              <StoryCarousel photos={photos} videos={videos} theme={theme} />
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
);

ExploreStoryContentOriginal.defaultProps = {
  text: "",
  photos: [],
  videos: [],
  likes: 0,
  stickyLike: false,
};

ExploreStoryContentOriginal.propTypes = {
  likes: PropTypes.number,
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  theme: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  stickyLike: PropTypes.bool,
};

export default ExploreStoryContentOriginal;
