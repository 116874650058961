import React, { useEffect, useState } from "react";
import { MsalProvider, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { loginRequest } from "./authConfig";
import { cleanToken } from "./MsGraphApiCall";
import { updateMmmUser } from "./updateMmmUser";
import Loader from "../components/loader";
import AuthErrorPage from "../page/error/AuthErrorPage";

export const AuthContext = React.createContext();
const AuthErrorComponent = () => <AuthErrorPage />;
const LoadingComponent = () => <Loader show />;


const MainContent = ({children}) => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const msalResult = useMsal();
    const [mmmUser, setMmmUser] = useState(null);
    const [authLoading, setAuthLoading] = useState(null);

    const onLike = (storyId) => {
        if (mmmUser) {
            const liked = mmmUser.liked_stories ?? [];
            if (!liked.includes(storyId)) {
                setMmmUser({ ...mmmUser, liked_stories: [...liked, storyId] });
            }
        }
    }
    const onUnlike = (storyId) => {
        if (mmmUser) {
            const liked = mmmUser.liked_stories ?? [];
            if (liked.includes(storyId)) {
                setMmmUser({ ...mmmUser, liked_stories: liked.filter(x => x !== storyId) });
            }
        }
    }
    
    useEffect(() => {
        setAuthLoading(msalResult.inProgress!=='none')

        if(msalResult?.accounts?.length){
            const _account = msalResult.accounts[0];
            const _msalUser = {
                email: _account.username,
                userName: _account.name,
                locationCode: _account.idTokenClaims?.ctry,
            };
            updateMmmUser(_msalUser).then(usr => {
                setMmmUser(usr);
            });
        }  else {
            if (msalResult.inProgress==='none'){
                //There is a connection problem to Azure AD Application
                // setAuthError(true);
                console.error("msalResult.inProgress==='none'")
            }
            setMmmUser(null);
        }
        // To remove the access token from SessionStorage
        cleanToken('ua-token');
    }, [msalResult]);
    
    return (authLoading || !mmmUser) ? <LoadingComponent /> :(

        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
            errorComponent={AuthErrorComponent}
            loadingComponent={LoadingComponent}>
            <AuthContext.Provider value={{ onLike, onUnlike, ...mmmUser }}>
                {children}
            </AuthContext.Provider>
        </MsalAuthenticationTemplate>
    );
};

const AuthProvider = ({msalInstance, children}) => {
    return (
        <MsalProvider instance={msalInstance}>
            <MainContent>{children}</MainContent>
        </MsalProvider>
    );
}

export default AuthProvider;