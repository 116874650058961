import React, { useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import Clamp from "react-multiline-clamp";
import ProgressiveImage from "react-progressive-graceful-image";
import { motion } from "framer-motion";
import { useClickAway, useMeasure } from "react-use";
import { usePopper } from "react-popper";
import { Icons } from "../../../components/icon";
import Like from "../../../components/like";
import { stripHtmlFromString } from "../../../helpers/common";
import imagePlaceholder from "../../../assets/images/loader-bg/map.jpg";

//style
import "./../best-stories.scss";
import s from "./best-story-item.module.scss";

const BestStoryItemOriginal = ({
  styles = {},
  simplified = false,
  showTags = false,
  data,
  onLiking,
  onClick,
}) => {
  const {
    id,
    nominated_primo_name,
    cover_image,
    title,
    location,
    likes,
    liked,
    text,
    tags,
  } = data;

  const sanitizedText = stripHtmlFromString(text);
  const [likesRef, { width: likesWidth }] = useMeasure();
  const [tagsRef, { width: tagsWidth }] = useMeasure();
  const [rootRef, { width: rootWidth }] = useMeasure();
  const [showMoreTags, setShowMoreTags] = useState();
  const [showTagsPanel, setShowTagsPanel] = useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement
  );

  const ref = useRef(null);
  useClickAway(ref, () => {
    setShowTagsPanel(false);
  });

  useLayoutEffect(() => {
    if (tags?.length > 1 && tagsWidth > likesWidth + 102 && !showMoreTags) {
      setShowMoreTags(true);
    }
  }, [tagsWidth, showMoreTags, likesWidth, tags]);

  return (
    <div
      className={clsx("bc-best-story-item")}
      style={styles}
      onClick={onClick}
      data-testid="best-story-item"
      ref={rootRef}
    >
      <div className="bc-best-story-item__row header-row">
        <div className={s.imageWrapper}>
          <ProgressiveImage
            src={`${process.env.REACT_APP_API_BASE_URL}${cover_image}`}
            placeholder={imagePlaceholder}
          >
            {(src) => (
              <img
                src={src}
                alt="Story cover"
                className="header-row-background-image"
              />
            )}
          </ProgressiveImage>
          <div className="header-row-background-overlay" />
        </div>

        <div className="header-row-content__wrapper">
          {!simplified && (
            <div className="location">
              <span>{Icons.MARKER}</span>
              <span>{location}</span>
            </div>
          )}
          <div className="story-title">
            <Clamp lines={2}>
              <div>{title}</div>
            </Clamp>
          </div>
          {!simplified && (
            <div className="author">
              <span>{Icons.BAT_OUTLINED}</span>
              <span>{nominated_primo_name}</span>
            </div>
          )}
        </div>
      </div>
      <div className="bc-best-story-item__row content-row">
        <div style={{ margin: "16px 24px", position: "relative" }}>
          <Clamp lines={3}>
            <div>{sanitizedText}</div>
          </Clamp>
        </div>
      </div>
      <div
        ref={setReferenceElement}
        className={clsx("bc-best-story-item__row footer-row", {
          simplified,
        })}
      >
        {!simplified && (
          <>
            <div className="like" ref={likesRef}>
              <Like likesNumber={likes} storyId={id} storyTagsNames={tags} liked={liked} onClick={onLiking} thumbnail />
            </div>
            {showTags && tags && tags.length && (
              <div className={s.tagsContainer}>
                <ul
                  className={clsx(s.tagsWrapper, s.tagsMeasure)}
                  ref={tagsRef}
                >
                  {tags.map((tag) => (
                    <li className={s.tag} key={tag}>
                      {tag}
                    </li>
                  ))}
                </ul>
                <ul className={s.tagsWrapper}>
                  {tags.slice(0, showMoreTags ? 1 : undefined).map((tag) => (
                    <li className={s.tag} key={tag}>
                      {tag}
                    </li>
                  ))}
                  {showMoreTags && (
                    <li className={s.moreTags}>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setShowTagsPanel((prev) => !prev);
                        }}
                        type="button"
                      >
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul>
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
      {showTagsPanel &&
        ReactDOM.createPortal(
          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ width: rootWidth, ...popperStyles.popper }}
            ref={setPopperElement}
            {...attributes.popper}
          >
            <ul ref={ref} className={s.tagsPanel}>
              {tags.slice(1).map((tag) => (
                <li className={s.tag} key={tag}>
                  {tag}
                </li>
              ))}
            </ul>
          </motion.div>,
          document.body
        )}
    </div>
  );
};

BestStoryItemOriginal.propTypes = {
  data: PropTypes.shape({
    nominated_primo_name: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    likes: PropTypes.number,
    id: PropTypes.string,
    liked: PropTypes.bool,
    cover_image: PropTypes.string,
    cover_image_thumbnail: PropTypes.string,
    text: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.shape({}),
  simplified: PropTypes.bool,
  showTags: PropTypes.bool,
};

BestStoryItemOriginal.defaultProps = {
  styles: {},
  simplified: false,
  showTags: false,
};

export default BestStoryItemOriginal;
