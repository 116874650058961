import React from 'react';
import PropTypes from 'prop-types';
import {
  isLaptopDevice,
  isTabletDevice,
  isBiggerThanLaptop,
  isMobileDevice
} from 'responsive-react';


// Components
import WizardNavbarMobile from './wizard-navbar-mobile';
import WizardNavbarOriginal from './wizard-navbar-original';

const WizardNavbar = ({ showClose, onGoBack, step }) => (
  <>
    {isMobileDevice() && (
      <WizardNavbarMobile showClose={showClose} step={step} onGoBack={onGoBack} />
    )}
    {(isLaptopDevice() || isBiggerThanLaptop() || isTabletDevice()) && (
      <WizardNavbarOriginal showClose={showClose} step={step} onGoBack={onGoBack} />
    )}
  </>
);

// WizardNavbar.defaultProps = {
//   showClose: false
// };

// WizardNavbar.propTypes = {
//   showClose: PropTypes.bool,
//   steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   onGoBack: PropTypes.func.isRequired
// };

export default React.memo(WizardNavbar);
