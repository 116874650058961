import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Icons } from "../icon";
import { AppSettingsContext } from "../../App";
import { AuthContext } from "../../auth/AuthProvider";

import { likeStory, unlikeStory, storyWithSameTagAlreadyLiked } from "./utils";
// import { isLikeAvailable } from "../../selectors/stories";

import "./like.scss";


/* eslint-disable */
const Like = ({ likesNumber, theme, storyId, storyTagsNames, mobile, thumbnail, onClick }) => {
  const { liking_enabled: likeAvailable, show_number_of_likes: showLikesCount, nomination_year, ultimate_founder_tag:ultimateFounderTag } = useContext(AppSettingsContext);
  const me = useContext(AuthContext);
  const [liked, setLiked] = useState(false);
  const [_storyLikesNumber, setStoryLikesNumber] = useState(likesNumber);
  const [likedStories, setLikedStories] = useState([]);
  const [likedWithSameTag, setLikedWithSameTag] = useState(null);
  const [otherStoryAlreadyLiked, setOtherStoryAlreadyLiked] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!me) return; //we should not have that, but if happened we have no info concerning the user, and he can not vote/like.
  //else

  useEffect(() => {
    setLikedStories(me?.liked_stories);
  }, [me]);

  useEffect(() => {
    setLiked(likedStories?.includes(storyId));
  }, [likedStories]);

  useEffect(() => {
    if(me?.id && storyTagsNames && ultimateFounderTag && nomination_year){
      storyWithSameTagAlreadyLiked({ userId: me.id, storyId, storyTagsNames, ultimateFounderTag, nominationYear:nomination_year})
      .then(storyAlreadyLiked => {
        setOtherStoryAlreadyLiked((storyAlreadyLiked && storyAlreadyLiked.id !== storyId) ? storyAlreadyLiked : null)
        setLiked(storyAlreadyLiked && storyAlreadyLiked.id === storyId)
      })
      .catch(err => {
        //handle error
        console.error(err)
      })
    }
  }, [me, storyId, storyTagsNames, ultimateFounderTag, nomination_year]);



  const handleUnlikeStory = async () => {
    setLoading(true);
    const { likedStories, storyLikesNumber } = await unlikeStory({ userId: me.id, storyId, nominationYear:nomination_year, storyLikes: _storyLikesNumber});
    setStoryLikesNumber(storyLikesNumber);
    me.liked_stories = likedStories;
    setLikedStories(me.liked_stories);    
    // onClick && onClick(false);
    me.onUnlike(storyId);
    setLoading(false);
  }

  const handleLikeStory = async () => {
    
    setLoading(true);
    const { likedStories, storyLikesNumber, alreadyLikedWithSameMainTag } = await likeStory({ userId: me.id, storyId, storyTagsNames, nominationYear:nomination_year, ultimateFounderTag, storyLikes: _storyLikesNumber});
    //setLikedWithSameTag(null);
    if(alreadyLikedWithSameMainTag){
      setLikedWithSameTag(alreadyLikedWithSameMainTag);      
    } 
    else {
      setStoryLikesNumber(storyLikesNumber);
      me.liked_stories = likedStories;
      setLikedStories(me.liked_stories);
      // onClick && onClick(true);
      me.onLike(storyId);
      setLikedWithSameTag(null);
    }
    setLoading(false);
  }

  const likeCount = showLikesCount ? (
    <span className="value">{_storyLikesNumber}</span>
  ) : null;

  return thumbnail ? (
    <div
      className={clsx("bc-like", theme, { liked, unavailable: !likeAvailable, disabled: otherStoryAlreadyLiked || loading })}
      onClick={(e) => {
        e.stopPropagation();
        if (likeAvailable) {
          liked
            ? handleUnlikeStory()
            : handleLikeStory();
        }
      }}
    >
      <span className="icon">{Icons.LIKE}</span>
      {!showLikesCount || !_storyLikesNumber ? (
        <span className="value">{liked ? "Liked" : mobile ? "Like" : "I like this story"}</span>
      ) : (
        likeCount
      )}
    </div>
  ) : (
  <>
    <div className="bc-like-story">
      <div
        className={clsx("bc-like-story__control", theme, {
          liked, unavailable: !likeAvailable, disabled: loading //|| otherStoryAlreadyLiked 
        })}
        onClick={(e) => {
          e.stopPropagation();
          if (likeAvailable) {
            liked
              ? handleUnlikeStory()
              : handleLikeStory();
          }
        }}
      >
        <div>{Icons.LIKE}</div>
        <div>{liked ? "I liked" : "I like this story"}</div>
      </div>
      {showLikesCount && _storyLikesNumber > 0 && (
        <div className="bc-like-story__info">
          ({_storyLikesNumber} {_storyLikesNumber > 1 ? "likes" : "like"})
        </div>
      )}
    </div>
    {likedWithSameTag && <div className="same-category-already-liked">You have already voted for <a href={`/stories/${likedWithSameTag.id}`} target='_blank'>another nominee</a> of the same category.</div>}
  </>
      );
};

Like.defaultProps = {
  likesNumber: 0,
  theme: "mint",
  liked: false,
  mobile: false,
  thumbnail: false,
};

Like.propTypes = {
  likesNumber: PropTypes.number,
  theme: PropTypes.string,
  liked: PropTypes.bool,
  storyId: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  thumbnail: PropTypes.bool,
};

export default Like;
/* eslint-enable */
