import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import RichTextEditor from 'react-rte';

import { Icons } from '../icon';

import { useEventListener, useOnClickOutside } from '../../helpers/hooks';

import './text-editor.scss';

const toolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'BLOCK_TYPE_DROPDOWN'
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled', className: 'ignore-blur' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
};

const TextEditor = ({ onChange, onBlur, label, error, defaultValue }) => {
  const container = React.useRef();
  const inputRef = React.useRef();
  const [focused, setFocused] = React.useState(false);
  const [filled, setFilled] = React.useState(!!defaultValue);
  const [value, setValue] = React.useState(RichTextEditor.createEmptyValue());

  useEffect(() => {
    const _value = defaultValue
      ? RichTextEditor.createValueFromString(defaultValue, 'html')
      : RichTextEditor.createEmptyValue();
    setValue(_value);
  }, [defaultValue]);

  const handleChange = useCallback(
    val => {
      setValue(val);
    },
    [setValue]
  );

  const handleClickOutside = React.useCallback(() => {
    const val = value.toString('html');
    let editorValue;
    switch (val) {
      case '<p><br></p>':
      case '<h1><br></h1>':
      case '<h2><br></h2>':
      case '<h3><br></h3>': {
        editorValue = '';
        break;
      }
      default: {
        editorValue = val;
      }
    }

    if (editorValue) {
      setFilled(true);
    } else {
      setFilled(false);
    }
    setFocused(false);
    onChange(editorValue);
  }, [value, onChange]);

  const handleBlur = React.useCallback(
    e => {
      if (e.target.closest('.bc-text-editor__container')) {
        return;
      }
      onBlur();
    },
    [onBlur]
  );

  const handleClick = useCallback(() => {
    if (!focused) {
      container.current.scrollIntoView(true);
      setFocused(true);
      inputRef.current.focus();
    }
  }, [focused, inputRef]);

  const handleKeyUp = React.useCallback(e => {
    if (e.key === 'Escape' && inputRef.current === document.activeElement) {
      inputRef.current.blur();
    }
  }, []);

  useEventListener('keyup', handleKeyUp);
  useOnClickOutside(container, handleClickOutside);

  React.useEffect(() => {
    const editorInput = container.current.querySelector('[contenteditable]');
    if (editorInput) {
      inputRef.current = editorInput;
    }
  }, []);

  return (
    <div className='bc-text-editor__wrapper'>
      <div
        ref={container}
        onClick={handleClick}
        className={clsx('bc-text-editor__container', {
          focused,
          filled,
          error: !!error
        })}
      >
        <div className='bc-text-editor__label'>{label}</div>
        <RichTextEditor
          tabIndex={0}
          onFocus={() => handleClick()}
          className='bc-text-editor'
          toolbarClassName='bc-text-editor__toolbar'
          toolbarConfig={toolbarConfig}
          editorClassName='bc-text-editor__editor'
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
      {!!error && (
        <div className='bc-input__status'>
          <div>{Icons.ERROR}</div>
          <div>{error}</div>
        </div>
      )}
    </div>
  );
};

TextEditor.defaultProps = {
  label: '',
  defaultValue: '',
  error: null
};

TextEditor.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default TextEditor;
