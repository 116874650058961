import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const WizardNavbarSteps = ({ step, steps }) => {
  const activeStep = steps.findIndex(item => item.id === step);

  return (
    <div className='bc-wizard-step__wrapper'>
      {steps
        .filter((item, i) => i !== 0)
        .map((item, i) => (
          <div
            key={item.id}
            className={clsx('step', { active: activeStep >= i + 1 })}
          />
        ))}
    </div>
  );
};

WizardNavbarSteps.defaultProps = {
  step: '1'
};

WizardNavbarSteps.propTypes = {
  step: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default WizardNavbarSteps;
