import React from "react";
import "./notFoundPage.scss"; // Import the CSS file for styling

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-heading">404</h1>
      <p className="not-found-text">
        Oops! The page you're looking for doesn't exist.
      </p>
      {/* <img
        src="/path/to/error-image.png"
        alt="Error"
        className="not-found-image"
      /> */}
      <a href="/" className="not-found-link">
        Go back to homepage
      </a>
    </div>
  );
};

export default NotFoundPage;
