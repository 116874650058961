import { useEffect } from "react";

export const useCleanBeforeUnload = (performCleaning) => {
  useEffect(() => {
    if (!performCleaning) return; //no cleaning required

    function confirmBeforeUnload(event) {
      //if (formModified) {
      event.preventDefault();
      event.returnValue =
        "Are you sure you want to leave? Your changes may not be saved.";
    }

    // Adding the event listener
    window.addEventListener("beforeunload", confirmBeforeUnload);

    // Add event listener to unload event
    // Execute the method before leaving, regardless of the user's decision
    window.addEventListener("unload", performCleaning);

    // Removing the event listener
    return () => {
      window.removeEventListener("beforeunload", confirmBeforeUnload);
      window.removeEventListener("unload", performCleaning);
    };
  }, [performCleaning]);
};
