//@ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import * as S from "./switch.style";

export interface ISwitch {
  /**The boolean value of the checkbox. */
  value: boolean;
  /**Fired on the value changing. */
  onChange: (v: boolean) => void;
  /**The name of the control */
  name: string;
  /**The label when the value of the checkbox is true. */
  labelOn?: string;
  /**The label when the value of the checkbox is false. */
  labelOff?: string;
  /**If true, the switch will be disabled. */
  disabled?: boolean;
  /**The height of the component. */
  size?: "s" | "m";
  id?: string;
  /**If true the component will fit the size of the container. */
  autoWidth?: boolean;
}

export const Switch = ({
  value,
  onChange,
  name,
  labelOn = "",
  labelOff = "",
  disabled = false,
  size = "m",
  id = name,
  autoWidth = false,
}: ISwitch) => {
  // const [checkboxValue, setCheckboxValue] = useState(value);
  //
  const [circleIndicatorTop, setCircleIndicatorTop] = useState(null);
  const [circleIndicatorLeft, setCircleIndicatorLeft] = useState(null);
  const [textIndicatorTop, setTextIndicatorTop] = useState(null);
  const [textIndicatorLeft, setTextIndicatorLeft] = useState(null);
  const [textIndicatorWidth, setTextIndicatorWidth] = useState(null);
  //
  const circleRef = useRef(null);
  const textRef = useRef(null);
  //
  useEffect(() => {
    setCircleIndicatorTop(circleRef.current.offsetTop);
    setCircleIndicatorLeft(circleRef.current.offsetLeft);
    setTextIndicatorTop(textRef.current.offsetTop);
    setTextIndicatorLeft(textRef.current.offsetLeft);
    setTextIndicatorWidth(textRef.current.offsetWidth);
  }, [value]);

  const tryToggleCheckbox = (e) => {
    if (!disabled) {
      const _checked = e.target.checked;
      // setCheckboxValue(_checked);
      onChange(_checked);
    }
  };

  return (
    <S.SwContainer $size={size} $autoWidth={autoWidth} disabled={disabled}>
      <S.SwInput
        type="checkbox"
        disabled={disabled}
        name={name}
        id={id}
        onClick={tryToggleCheckbox}
        onChange={() => { }}
        checked={value}
      />

      <S.SwLabel
        htmlFor={id}
        value={value}
        $size={size}
        $withOffLabel={labelOff?.length > 0}
      >
        <S.SwCircleContainer ref={circleRef} $size={size} />
        <S.SwGrow />
        <S.SwTextContainer ref={textRef}>
          {value ? labelOn : labelOff}
        </S.SwTextContainer>
        <S.SwEmpty />

        <S.SwCircleIndicator
          $size={size}
          offset={{ top: circleIndicatorTop, left: circleIndicatorLeft }}
        />
        <S.SwTextIndicator
          offset={{
            top: textIndicatorTop,
            left: textIndicatorLeft,
            width: textIndicatorWidth,
          }}
        >
          {value ? labelOn : labelOff}
        </S.SwTextIndicator>
      </S.SwLabel>
    </S.SwContainer>
  );
};
