import { interpolate, interpolateTransformSvg } from "d3-interpolate";
import { stripHtml } from "string-strip-html";
import { getLocalStorageItem, setLocalStorageItem } from "./localStorage";

const SHARE_STORY_EMAIL = process.env.REACT_APP_SHARE_STORY_EMAIL;

export function bool(v) {
  return v === "false" ||
    v === "null" ||
    v === "NaN" ||
    v === "undefined" ||
    v === "0"
    ? false
    : !!v;
}

export const interpolation = (begValue, endValue, attr) => {
  if (attr === "transform") {
    return interpolateTransformSvg(begValue, endValue);
  }

  return interpolate(begValue, endValue);
};

/**
 * Detect IE
 */
export function isInternetExplorer() {
  // eslint-disable-next-line
  return /*@cc_on!@*/ false || !!document.documentMode;
}

export function randomInteger(min, max) {
  if (min === undefined || max === undefined) {
    return null;
  }

  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

export function getTheme() {
  const key = "bc-litq-theme";
  const themeOptions = ["mint", "purple", "blue"];
  const currentColor = getLocalStorageItem(key, 0);
  if (currentColor === themeOptions.length - 1) {
    setLocalStorageItem(key, 0);
  } else {
    setLocalStorageItem(key, currentColor + 1);
  }
  switch (currentColor) {
    case 0: {
      return "mint";
    }
    case 1: {
      return "purple";
    }
    default: {
      return "blue";
    }
  }
}

export function askForSupport() {
  window.open(`mailto:${SHARE_STORY_EMAIL}`, "_blank");
}

export function isFeatureAvailable(stage) {
  const configuredStage = process.env.REACT_APP_APPLICATION_STAGE;

  if (!configuredStage) return true;

  return +stage <= +configuredStage;
}

/**
 * Check sticky position support by browser
 * @returns {boolean}
 */
export const positionStickySupport = () => {
  const el = document.createElement("a");
  const mStyle = el.style;

  mStyle.cssText =
    "position:sticky;position:-webkit-sticky;position:-ms-sticky;";

  return mStyle.position.indexOf("sticky") !== -1;
};

/**
 * Check if browser is safari
 * @returns {boolean}
 */
export const isSafariBrowser = () => {
  const chrome = navigator.userAgent.indexOf("Chrome") > -1;
  let safari = navigator.userAgent.indexOf("Safari") > -1;
  if (chrome && safari) safari = false;

  return safari;
};

/**
 * Get position style
 * @returns {string}
 * @private
 */
export const getPositionStyleValue = () => {
  const isStickySupported = positionStickySupport();

  return isStickySupported && !isSafariBrowser() ? "sticky" : "-webkit-sticky";
};

export const getStickyStyles = (isSticky, top) => {
  if (!isInternetExplorer() && positionStickySupport()) {
    return {
      position: getPositionStyleValue(),
      top,
      zIndex: 101,
      transform: "translate3d(0,0,0)",
    };
  }

  if (isSticky) {
    return {
      position: "fixed",
      top,
      zIndex: 101,
      transform: "translate3d(0,0,0)",
    };
  }

  return {
    position: "relative",
    top: 0,
    zIndex: 101,
    transform: "translate3d(0,0,0)",
  };
};

export default function sortStringBy(
  primaryField,
  secondaryField,
  sortDirection = "ASC"
) {
  const ASC = sortDirection === "ASC";
  return (a, b) => {
    if (a[primaryField] < b[primaryField]) return ASC ? -1 : 1;
    if (a[primaryField] > b[primaryField]) return ASC ? 1 : -1;

    if (secondaryField) {
      if (a[secondaryField] < b[secondaryField]) return ASC ? -1 : 1;
      if (a[secondaryField] > b[secondaryField]) return ASC ? 1 : -1;
    }
    return 0;
  };
}

export function stripHtmlFromString(text) {
  if (!text) return text;

  try {
    return stripHtml(text).result;
  } catch (e) {
    return text;
  }
}
