import React from "react";
import PropTypes from "prop-types";

import ExploreStoryHeaderContent from "./explore-story-header-content";

import "./explore-story-header-tablet.scss";

const ExploreStoryHeaderTablet = ({
  theme,
  minimized,
  coverImage,
  title,
  period,
}) => (
  <div className="bc-explore-story__header-background-tablet">
    <ExploreStoryHeaderContent
      theme={theme}
      coverImage={coverImage}
      title={title}
      period={period}
    />
  </div>
);

ExploreStoryHeaderTablet.defaultProps = {
  rank: 0,
  nominatedAuthor: null,
  theme: "mint",
};

ExploreStoryHeaderTablet.propTypes = {
  coverImage: PropTypes.string.isRequired,
  coverImageThumb: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rank: PropTypes.number,
  nominatedAuthor: PropTypes.string,
  theme: PropTypes.string,
};

export default ExploreStoryHeaderTablet;
