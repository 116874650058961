const data = [
  {
    name: "Albania",
    alpha2: "AL",
    latitude: 41,
    longitude: 20
  },
  {
    name: "Algeria",
    alpha2: "DZ",
    latitude: 28,
    longitude: 3
  },
  {
    name: "American Samoa",
    alpha2: "AS",
    latitude: -14.3333,
    longitude: -170
  },
  {
    name: "Andorra",
    alpha2: "AD",
    latitude: 42.5,
    longitude: 1.6
  },
  {
    name: "Angola",
    alpha2: "AO",
    latitude: -12.5,
    longitude: 18.5
  },
  {
    name: "Anguilla",
    alpha2: "AI",
    latitude: 18.25,
    longitude: -63.1667
  },
  {
    name: "Antarctica",
    alpha2: "AQ",
    latitude: -90,
    longitude: 0
  },
  {
    name: "Antigua and Barbuda",
    alpha2: "AG",
    latitude: 17.05,
    longitude: -61.8
  },
  {
    name: "Argentina",
    alpha2: "AR",
    latitude: -34,
    longitude: -64
  },
  {
    name: "Armenia",
    alpha2: "AM",
    latitude: 40,
    longitude: 45
  },
  {
    name: "Aruba",
    alpha2: "AW",
    latitude: 12.5,
    longitude: -69.9667
  },
  {
    name: "Australia",
    alpha2: "AU",
    latitude: -27,
    longitude: 133
  },
  {
    name: "Austria",
    alpha2: "AT",
    latitude: 47.3333,
    longitude: 13.3333
  },
  {
    name: "Azerbaijan",
    alpha2: "AZ",
    latitude: 40.5,
    longitude: 47.5
  },
  {
    name: "Bahamas",
    alpha2: "BS",
    latitude: 24.25,
    longitude: -76
  },
  {
    name: "Bahrain",
    alpha2: "BH",
    latitude: 26,
    longitude: 50.55
  },
  {
    name: "Bangladesh",
    alpha2: "BD",
    latitude: 24,
    longitude: 90
  },
  {
    name: "Barbados",
    alpha2: "BB",
    latitude: 13.1667,
    longitude: -59.5333
  },
  {
    name: "Belarus",
    alpha2: "BY",
    latitude: 53,
    longitude: 28
  },
  {
    name: "Belgium",
    alpha2: "BE",
    latitude: 50.8333,
    longitude: 4
  },
  {
    name: "Belize",
    alpha2: "BZ",
    latitude: 17.25,
    longitude: -88.75
  },
  {
    name: "Benin",
    alpha2: "BJ",
    latitude: 9.5,
    longitude: 2.25
  },
  {
    name: "Bermuda",
    alpha2: "BM",
    latitude: 32.3333,
    longitude: -64.75
  },
  {
    name: "Bhutan",
    alpha2: "BT",
    latitude: 27.5,
    longitude: 90.5
  },
  {
    name: "Bolivia, Plurinational State of",
    alpha2: "BO",
    latitude: -17,
    longitude: -65
  },
  {
    name: "Bosnia and Herzegovina",
    alpha2: "BA",
    latitude: 44,
    longitude: 18
  },
  {
    name: "Botswana",
    alpha2: "BW",
    latitude: -22,
    longitude: 24
  },
  {
    name: "Bouvet Island",
    alpha2: "BV",
    latitude: -54.4333,
    longitude: 3.4
  },
  {
    name: "Brazil",
    alpha2: "BR",
    latitude: -10,
    longitude: -55
  },
  {
    name: "British Indian Ocean Territory",
    alpha2: "IO",
    latitude: -6,
    longitude: 71.5
  },
  {
    name: "Brunei Darussalam",
    alpha2: "BN",
    latitude: 4.5,
    longitude: 114.6667
  },
  {
    name: "Bulgaria",
    alpha2: "BG",
    latitude: 43,
    longitude: 25
  },
  {
    name: "Burkina Faso",
    alpha2: "BF",
    latitude: 13,
    longitude: -2
  },
  {
    name: "Burundi",
    alpha2: "BI",
    latitude: -3.5,
    longitude: 30
  },
  {
    name: "Cambodia",
    alpha2: "KH",
    latitude: 13,
    longitude: 105
  },
  {
    name: "Cameroon",
    alpha2: "CM",
    latitude: 6,
    longitude: 12
  },
  {
    name: "Canada",
    alpha2: "CA",
    latitude: 60,
    longitude: -95
  },
  {
    name: "Cape Verde",
    alpha2: "CV",
    latitude: 16,
    longitude: -24
  },
  {
    name: "Cayman Islands",
    alpha2: "KY",
    latitude: 19.5,
    longitude: -80.5
  },
  {
    name: "Central African Republic",
    alpha2: "CF",
    latitude: 7,
    longitude: 21
  },
  {
    name: "Chad",
    alpha2: "TD",
    latitude: 15,
    longitude: 19
  },
  {
    name: "Chile",
    alpha2: "CL",
    latitude: -30,
    longitude: -71
  },
  {
    name: "China",
    alpha2: "CN",
    latitude: 35,
    longitude: 105
  },
  {
    name: "Christmas Island",
    alpha2: "CX",
    latitude: -10.5,
    longitude: 105.6667
  },
  {
    name: "Cocos (Keeling) Islands",
    alpha2: "CC",
    latitude: -12.5,
    longitude: 96.8333
  },
  {
    name: "Colombia",
    alpha2: "CO",
    latitude: 4,
    longitude: -72
  },
  {
    name: "Comoros",
    alpha2: "KM",
    latitude: -12.1667,
    longitude: 44.25
  },
  {
    name: "Congo",
    alpha2: "CG",
    latitude: -1,
    longitude: 15
  },
  {
    name: "Congo, the Democratic Republic of the",
    alpha2: "CD",
    latitude: 0,
    longitude: 25
  },
  {
    name: "Cook Islands",
    alpha2: "CK",
    latitude: -21.2333,
    longitude: -159.7667
  },
  {
    name: "Costa Rica",
    alpha2: "CR",
    latitude: 10,
    longitude: -84
  },
  {
    name: "Côte d'Ivoire",
    alpha2: "CI",
    latitude: 8,
    longitude: -5
  },
  {
    name: "Croatia",
    alpha2: "HR",
    latitude: 45.1667,
    longitude: 15.5
  },
  {
    name: "Cuba",
    alpha2: "CU",
    latitude: 21.5,
    longitude: -80
  },
  {
    name: "Cyprus",
    alpha2: "CY",
    latitude: 35,
    longitude: 33
  },
  {
    name: "Czech Republic",
    alpha2: "CZ",
    latitude: 49.75,
    longitude: 15.5
  },
  {
    name: "Denmark",
    alpha2: "DK",
    latitude: 56,
    longitude: 10
  },
  {
    name: "Djibouti",
    alpha2: "DJ",
    latitude: 11.5,
    longitude: 43
  },
  {
    name: "Dominica",
    alpha2: "DM",
    latitude: 15.4167,
    longitude: -61.3333
  },
  {
    name: "Dominican Republic",
    alpha2: "DO",
    latitude: 19,
    longitude: -70.6667
  },
  {
    name: "Ecuador",
    alpha2: "EC",
    latitude: -2,
    longitude: -77.5
  },
  {
    name: "Egypt",
    alpha2: "EG",
    latitude: 27,
    longitude: 30
  },
  {
    name: "El Salvador",
    alpha2: "SV",
    latitude: 13.8333,
    longitude: -88.9167
  },
  {
    name: "Equatorial Guinea",
    alpha2: "GQ",
    latitude: 2,
    longitude: 10
  },
  {
    name: "Eritrea",
    alpha2: "ER",
    latitude: 15,
    longitude: 39
  },
  {
    name: "Estonia",
    alpha2: "EE",
    latitude: 59,
    longitude: 26
  },
  {
    name: "Ethiopia",
    alpha2: "ET",
    latitude: 8,
    longitude: 38
  },
  {
    name: "Falkland Islands (Malvinas)",
    alpha2: "FK",
    latitude: -51.75,
    longitude: -59
  },
  {
    name: "Faroe Islands",
    alpha2: "FO",
    latitude: 62,
    longitude: -7
  },
  {
    name: "Fiji",
    alpha2: "FJ",
    latitude: -18,
    longitude: 175
  },
  {
    name: "Finland",
    alpha2: "FI",
    latitude: 64,
    longitude: 26
  },
  {
    name: "France",
    alpha2: "FR",
    latitude: 46,
    longitude: 2
  },
  {
    name: "French Guiana",
    alpha2: "GF",
    latitude: 4,
    longitude: -53
  },
  {
    name: "French Polynesia",
    alpha2: "PF",
    latitude: -15,
    longitude: -140
  },
  {
    name: "French Southern Territories",
    alpha2: "TF",
    latitude: -43,
    longitude: 67
  },
  {
    name: "Gabon",
    alpha2: "GA",
    latitude: -1,
    longitude: 11.75
  },
  {
    name: "Gambia",
    alpha2: "GM",
    latitude: 13.4667,
    longitude: -16.5667
  },
  {
    name: "Georgia",
    alpha2: "GE",
    latitude: 42,
    longitude: 43.5
  },
  {
    name: "Germany",
    alpha2: "DE",
    latitude: 51,
    longitude: 9
  },
  {
    name: "Ghana",
    alpha2: "GH",
    latitude: 8,
    longitude: -2
  },
  {
    name: "Gibraltar",
    alpha2: "GI",
    latitude: 36.1833,
    longitude: -5.3667
  },
  {
    name: "Greece",
    alpha2: "GR",
    latitude: 39,
    longitude: 22
  },
  {
    name: "Greenland",
    alpha2: "GL",
    latitude: 72,
    longitude: -40
  },
  {
    name: "Grenada",
    alpha2: "GD",
    latitude: 12.1167,
    longitude: -61.6667
  },
  {
    name: "Guadeloupe",
    alpha2: "GP",
    latitude: 16.25,
    longitude: -61.5833
  },
  {
    name: "Guam",
    alpha2: "GU",
    latitude: 13.4667,
    longitude: 144.7833
  },
  {
    name: "Guatemala",
    alpha2: "GT",
    latitude: 15.5,
    longitude: -90.25
  },
  {
    name: "Guernsey",
    alpha2: "GG",
    latitude: 49.5,
    longitude: -2.56
  },
  {
    name: "Guinea",
    alpha2: "GN",
    latitude: 11,
    longitude: -10
  },
  {
    name: "Guinea-Bissau",
    alpha2: "GW",
    latitude: 12,
    longitude: -15
  },
  {
    name: "Guyana",
    alpha2: "GY",
    latitude: 5,
    longitude: -59
  },
  {
    name: "Haiti",
    alpha2: "HT",
    latitude: 19,
    longitude: -72.4167
  },
  {
    name: "Heard Island and McDonald Islands",
    alpha2: "HM",
    latitude: -53.1,
    longitude: 72.5167
  },
  {
    name: "Holy See (Vatican City State)",
    alpha2: "VA",
    latitude: 41.9,
    longitude: 12.45
  },
  {
    name: "Honduras",
    alpha2: "HN",
    latitude: 15,
    longitude: -86.5
  },
  {
    name: "Hong Kong",
    alpha2: "HK",
    latitude: 22.25,
    longitude: 114.1667
  },
  {
    name: "Hungary",
    alpha2: "HU",
    latitude: 47,
    longitude: 20
  },
  {
    name: "Iceland",
    alpha2: "IS",
    latitude: 65,
    longitude: -18
  },
  {
    name: "India",
    alpha2: "IN",
    latitude: 20,
    longitude: 77
  },
  {
    name: "Indonesia",
    alpha2: "ID",
    latitude: -5,
    longitude: 120
  },
  {
    name: "Iran, Islamic Republic of",
    alpha2: "IR",
    latitude: 32,
    longitude: 53
  },
  {
    name: "Iraq",
    alpha2: "IQ",
    latitude: 33,
    longitude: 44
  },
  {
    name: "Ireland",
    alpha2: "IE",
    latitude: 53,
    longitude: -8
  },
  {
    name: "Isle of Man",
    alpha2: "IM",
    latitude: 54.23,
    longitude: -4.55
  },
  {
    name: "Israel",
    alpha2: "IL",
    latitude: 31.5,
    longitude: 34.75
  },
  {
    name: "Italy",
    alpha2: "IT",
    latitude: 42.8333,
    longitude: 12.8333
  },
  {
    name: "Jamaica",
    alpha2: "JM",
    latitude: 18.25,
    longitude: -77.5
  },
  {
    name: "Japan",
    alpha2: "JP",
    latitude: 36,
    longitude: 138
  },
  {
    name: "Jersey",
    alpha2: "JE",
    latitude: 49.21,
    longitude: -2.13
  },
  {
    name: "Jordan",
    alpha2: "JO",
    latitude: 31,
    longitude: 36
  },
  {
    name: "Kazakhstan",
    alpha2: "KZ",
    latitude: 48,
    longitude: 68
  },
  {
    name: "Kenya",
    alpha2: "KE",
    latitude: 1,
    longitude: 38
  },
  {
    name: "Kiribati",
    alpha2: "KI",
    latitude: 1.4167,
    longitude: 173
  },
  {
    name: "Korea, Democratic People's Republic of",
    alpha2: "KP",
    latitude: 40,
    longitude: 127
  },
  {
    name: "Korea, Republic of",
    alpha2: "KR",
    latitude: 37,
    longitude: 127.5
  },
  {
    name: "Kuwait",
    alpha2: "KW",
    latitude: 29.3375,
    longitude: 47.6581
  },
  {
    name: "Kyrgyzstan",
    alpha2: "KG",
    latitude: 41,
    longitude: 75
  },
  {
    name: "Lao People's Democratic Republic",
    alpha2: "LA",
    latitude: 18,
    longitude: 105
  },
  {
    name: "Latvia",
    alpha2: "LV",
    latitude: 57,
    longitude: 25
  },
  {
    name: "Lebanon",
    alpha2: "LB",
    latitude: 33.8333,
    longitude: 35.8333
  },
  {
    name: "Lesotho",
    alpha2: "LS",
    latitude: -29.5,
    longitude: 28.5
  },
  {
    name: "Liberia",
    alpha2: "LR",
    latitude: 6.5,
    longitude: -9.5
  },
  {
    name: "Libyan Arab Jamahiriya",
    alpha2: "LY",
    latitude: 25,
    longitude: 17
  },
  {
    name: "Liechtenstein",
    alpha2: "LI",
    latitude: 47.1667,
    longitude: 9.5333
  },
  {
    name: "Lithuania",
    alpha2: "LT",
    latitude: 56,
    longitude: 24
  },
  {
    name: "Luxembourg",
    alpha2: "LU",
    latitude: 49.75,
    longitude: 6.1667
  },
  {
    name: "Macao",
    alpha2: "MO",
    latitude: 22.1667,
    longitude: 113.55
  },
  {
    name: "Macedonia, the former Yugoslav Republic of",
    alpha2: "MK",
    latitude: 41.8333,
    longitude: 22
  },
  {
    name: "Madagascar",
    alpha2: "MG",
    latitude: -20,
    longitude: 47
  },
  {
    name: "Malawi",
    alpha2: "MW",
    latitude: -13.5,
    longitude: 34
  },
  {
    name: "Malaysia",
    alpha2: "MY",
    latitude: 2.5,
    longitude: 112.5
  },
  {
    name: "Maldives",
    alpha2: "MV",
    latitude: 3.25,
    longitude: 73
  },
  {
    name: "Mali",
    alpha2: "ML",
    latitude: 17,
    longitude: -4
  },
  {
    name: "Malta",
    alpha2: "MT",
    latitude: 35.8333,
    longitude: 14.5833
  },
  {
    name: "Marshall Islands",
    alpha2: "MH",
    latitude: 9,
    longitude: 168
  },
  {
    name: "Martinique",
    alpha2: "MQ",
    latitude: 14.6667,
    longitude: -61
  },
  {
    name: "Mauritania",
    alpha2: "MR",
    latitude: 20,
    longitude: -12
  },
  {
    name: "Mauritius",
    alpha2: "MU",
    latitude: -20.2833,
    longitude: 57.55
  },
  {
    name: "Mayotte",
    alpha2: "YT",
    latitude: -12.8333,
    longitude: 45.1667
  },
  {
    name: "Mexico",
    alpha2: "MX",
    latitude: 23,
    longitude: -102
  },
  {
    name: "Micronesia, Federated States of",
    alpha2: "FM",
    latitude: 6.9167,
    longitude: 158.25
  },
  {
    name: "Moldova, Republic of",
    alpha2: "MD",
    latitude: 47,
    longitude: 29
  },
  {
    name: "Monaco",
    alpha2: "MC",
    latitude: 43.7333,
    longitude: 7.4
  },
  {
    name: "Mongolia",
    alpha2: "MN",
    latitude: 46,
    longitude: 105
  },
  {
    name: "Montenegro",
    alpha2: "ME",
    latitude: 42,
    longitude: 19
  },
  {
    name: "Montserrat",
    alpha2: "MS",
    latitude: 16.75,
    longitude: -62.2
  },
  {
    name: "Morocco",
    alpha2: "MA",
    latitude: 32,
    longitude: -5
  },
  {
    name: "Mozambique",
    alpha2: "MZ",
    latitude: -18.25,
    longitude: 35
  },
  {
    name: "Myanmar",
    alpha2: "MM",
    latitude: 22,
    longitude: 98
  },
  {
    name: "Namibia",
    alpha2: "NA",
    latitude: -22,
    longitude: 17
  },
  {
    name: "Nauru",
    alpha2: "NR",
    latitude: -0.5333,
    longitude: 166.9167
  },
  {
    name: "Nepal",
    alpha2: "NP",
    latitude: 28,
    longitude: 84
  },
  {
    name: "Netherlands",
    alpha2: "NL",
    latitude: 52.5,
    longitude: 5.75
  },
  {
    name: "Netherlands Antilles",
    alpha2: "AN",
    latitude: 12.25,
    longitude: -68.75
  },
  {
    name: "New Caledonia",
    alpha2: "NC",
    latitude: -21.5,
    longitude: 165.5
  },
  {
    name: "New Zealand",
    alpha2: "NZ",
    latitude: -41,
    longitude: 174
  },
  {
    name: "Nicaragua",
    alpha2: "NI",
    latitude: 13,
    longitude: -85
  },
  {
    name: "Niger",
    alpha2: "NE",
    latitude: 16,
    longitude: 8
  },
  {
    name: "Nigeria",
    alpha2: "NG",
    latitude: 10,
    longitude: 8
  },
  {
    name: "Niue",
    alpha2: "NU",
    latitude: -19.0333,
    longitude: -169.8667
  },
  {
    name: "Norfolk Island",
    alpha2: "NF",
    latitude: -29.0333,
    longitude: 167.95
  },
  {
    name: "Northern Mariana Islands",
    alpha2: "MP",
    latitude: 15.2,
    longitude: 145.75
  },
  {
    name: "Norway",
    alpha2: "NO",
    latitude: 62,
    longitude: 10
  },
  {
    name: "Oman",
    alpha2: "OM",
    latitude: 21,
    longitude: 57
  },
  {
    name: "Pakistan",
    alpha2: "PK",
    latitude: 30,
    longitude: 70
  },
  {
    name: "Palau",
    alpha2: "PW",
    latitude: 7.5,
    longitude: 134.5
  },
  {
    name: "Palestinian Territory, Occupied",
    alpha2: "PS",
    latitude: 32,
    longitude: 35.25
  },
  {
    name: "Panama",
    alpha2: "PA",
    latitude: 9,
    longitude: -80
  },
  {
    name: "Papua New Guinea",
    alpha2: "PG",
    latitude: -6,
    longitude: 147
  },
  {
    name: "Paraguay",
    alpha2: "PY",
    latitude: -23,
    longitude: -58
  },
  {
    name: "Peru",
    alpha2: "PE",
    latitude: -10,
    longitude: -76
  },
  {
    name: "Philippines",
    alpha2: "PH",
    latitude: 13,
    longitude: 122
  },
  {
    name: "Pitcairn",
    alpha2: "PN",
    latitude: -24.7,
    longitude: -127.4
  },
  {
    name: "Poland",
    alpha2: "PL",
    latitude: 52,
    longitude: 20
  },
  {
    name: "Portugal",
    alpha2: "PT",
    latitude: 39.5,
    longitude: -8
  },
  {
    name: "Puerto Rico",
    alpha2: "PR",
    latitude: 18.25,
    longitude: -66.5
  },
  {
    name: "Qatar",
    alpha2: "QA",
    latitude: 25.5,
    longitude: 51.25
  },
  {
    name: "Réunion",
    alpha2: "RE",
    latitude: -21.1,
    longitude: 55.6
  },
  {
    name: "Romania",
    alpha2: "RO",
    latitude: 46,
    longitude: 25
  },
  {
    name: "Russian Federation",
    alpha2: "RU",
    latitude: 60,
    longitude: 100
  },
  {
    name: "Rwanda",
    alpha2: "RW",
    latitude: -2,
    longitude: 30
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2: "SH",
    latitude: -15.9333,
    longitude: -5.7
  },
  {
    name: "Saint Kitts and Nevis",
    alpha2: "KN",
    latitude: 17.3333,
    longitude: -62.75
  },
  {
    name: "Saint Lucia",
    alpha2: "LC",
    latitude: 13.8833,
    longitude: -61.1333
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha2: "PM",
    latitude: 46.8333,
    longitude: -56.3333
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha2: "VC",
    latitude: 13.25,
    longitude: -61.2
  },
  {
    name: "Samoa",
    alpha2: "WS",
    latitude: -13.5833,
    longitude: -172.3333
  },
  {
    name: "San Marino",
    alpha2: "SM",
    latitude: 43.7667,
    longitude: 12.4167
  },
  {
    name: "Sao Tome and Principe",
    alpha2: "ST",
    latitude: 1,
    longitude: 7
  },
  {
    name: "Saudi Arabia",
    alpha2: "SA",
    latitude: 25,
    longitude: 45
  },
  {
    name: "Senegal",
    alpha2: "SN",
    latitude: 14,
    longitude: -14
  },
  {
    name: "Serbia",
    alpha2: "RS",
    latitude: 44,
    longitude: 21
  },
  {
    name: "Seychelles",
    alpha2: "SC",
    latitude: -4.5833,
    longitude: 55.6667
  },
  {
    name: "Sierra Leone",
    alpha2: "SL",
    latitude: 8.5,
    longitude: -11.5
  },
  {
    name: "Singapore",
    alpha2: "SG",
    latitude: 1.3667,
    longitude: 103.8
  },
  {
    name: "Slovakia",
    alpha2: "SK",
    latitude: 48.6667,
    longitude: 19.5
  },
  {
    name: "Slovenia",
    alpha2: "SI",
    latitude: 46,
    longitude: 15
  },
  {
    name: "Solomon Islands",
    alpha2: "SB",
    latitude: -8,
    longitude: 159
  },
  {
    name: "Somalia",
    alpha2: "SO",
    latitude: 10,
    longitude: 49
  },
  {
    name: "South Africa",
    alpha2: "ZA",
    latitude: -29,
    longitude: 24
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha2: "GS",
    latitude: -54.5,
    longitude: -37
  },
  {
    name: "Spain",
    alpha2: "ES",
    latitude: 40,
    longitude: -4
  },
  {
    name: "Sri Lanka",
    alpha2: "LK",
    latitude: 7,
    longitude: 81
  },
  {
    name: "Sudan",
    alpha2: "SD",
    latitude: 15,
    longitude: 30
  },
  {
    name: "Suriname",
    alpha2: "SR",
    latitude: 4,
    longitude: -56
  },
  {
    name: "Svalbard and Jan Mayen",
    alpha2: "SJ",
    latitude: 78,
    longitude: 20
  },
  {
    name: "Swaziland",
    alpha2: "SZ",
    latitude: -26.5,
    longitude: 31.5
  },
  {
    name: "Sweden",
    alpha2: "SE",
    latitude: 62,
    longitude: 15
  },
  {
    name: "Switzerland",
    alpha2: "CH",
    latitude: 47,
    longitude: 8
  },
  {
    name: "Syrian Arab Republic",
    alpha2: "SY",
    latitude: 35,
    longitude: 38
  },
  {
    name: "Taiwan, Province of China",
    alpha2: "TW",
    latitude: 23.5,
    longitude: 121
  },
  {
    name: "Tajikistan",
    alpha2: "TJ",
    latitude: 39,
    longitude: 71
  },
  {
    name: "Tanzania, United Republic of",
    alpha2: "TZ",
    latitude: -6,
    longitude: 35
  },
  {
    name: "Thailand",
    alpha2: "TH",
    latitude: 15,
    longitude: 100
  },
  {
    name: "Timor-Leste",
    alpha2: "TL",
    latitude: -8.55,
    longitude: 125.5167
  },
  {
    name: "Togo",
    alpha2: "TG",
    latitude: 8,
    longitude: 1.1667
  },
  {
    name: "Tokelau",
    alpha2: "TK",
    latitude: -9,
    longitude: -172
  },
  {
    name: "Tonga",
    alpha2: "TO",
    latitude: -20,
    longitude: -175
  },
  {
    name: "Trinidad and Tobago",
    alpha2: "TT",
    latitude: 11,
    longitude: -61
  },
  {
    name: "Tunisia",
    alpha2: "TN",
    latitude: 34,
    longitude: 9
  },
  {
    name: "Turkey",
    alpha2: "TR",
    latitude: 39,
    longitude: 35
  },
  {
    name: "Turkmenistan",
    alpha2: "TM",
    latitude: 40,
    longitude: 60
  },
  {
    name: "Turks and Caicos Islands",
    alpha2: "TC",
    latitude: 21.75,
    longitude: -71.5833
  },
  {
    name: "Tuvalu",
    alpha2: "TV",
    latitude: -8,
    longitude: 178
  },
  {
    name: "Uganda",
    alpha2: "UG",
    latitude: 1,
    longitude: 32
  },
  {
    name: "Ukraine",
    alpha2: "UA",
    latitude: 49,
    longitude: 32
  },
  {
    name: "United Arab Emirates",
    alpha2: "AE",
    latitude: 24,
    longitude: 54
  },
  {
    name: "United Kingdom",
    alpha2: "GB",
    latitude: 54,
    longitude: -2
  },
  {
    name: "United States",
    alpha2: "US",
    latitude: 38,
    longitude: -97
  },
  {
    name: "United States Minor Outlying Islands",
    alpha2: "UM",
    latitude: 19.2833,
    longitude: 166.6
  },
  {
    name: "Uruguay",
    alpha2: "UY",
    latitude: -33,
    longitude: -56
  },
  {
    name: "Uzbekistan",
    alpha2: "UZ",
    latitude: 41,
    longitude: 64
  },
  {
    name: "Vanuatu",
    alpha2: "VU",
    latitude: -16,
    longitude: 167
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    alpha2: "VE",
    latitude: 8,
    longitude: -66
  },
  {
    name: "Viet Nam",
    alpha2: "VN",
    latitude: 16,
    longitude: 106
  },
  {
    name: "Virgin Islands, British",
    alpha2: "VG",
    latitude: 18.5,
    longitude: -64.5
  },
  {
    name: "Virgin Islands, U.S.",
    alpha2: "VI",
    latitude: 18.3333,
    longitude: -64.8333
  },
  {
    name: "Wallis and Futuna",
    alpha2: "WF",
    latitude: -13.3,
    longitude: -176.2
  },
  {
    name: "Western Sahara",
    alpha2: "EH",
    latitude: 24.5,
    longitude: -13
  },
  {
    name: "Yemen",
    alpha2: "YE",
    latitude: 15,
    longitude: 48
  },
  {
    name: "Zambia",
    alpha2: "ZM",
    latitude: -15,
    longitude: 30
  },
  {
    name: "Zimbabwe",
    alpha2: "ZW",
    latitude: -20,
    longitude: 30
  },
  {
    name: "Afghanistan",
    alpha2: "AF",
    latitude: 33,
    longitude: 65
  }
];

export default data;