import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Grid, Row, Col } from "react-flexbox-grid";
import clsx from "clsx";

// Components
import { Icons } from "../icon";
// import LocationsListFilter from "../locations-list/locations-list-filter";
import DropdownPanelMobile from "../dropdown-panel/dropdown-panel-mobile";
import NavButton from "../nav-button";
import NavbarLogo from "./navbar-logo";

// Styles
import "./navbar-mobile.scss";
import { isFeatureAvailable } from "../../helpers/common";
import styles from "./navbar.module.scss";
import { MobileNavigationDropdown } from "../mobile-navigation-dropdown";

const NavbarMobile = ({
  sitePhase,
  newsletterSent,
  downloadLink,
  //
  transparent,
  light,
  minimized,
  noSearch,
  showBack,
  likedStoriesLength,
}) => {
  const navigate = useNavigate();
  const [filterOpened, setFilterOpened] = useState();

  return (
    <nav
      id="bc-navbar-mobile"
      className={clsx("bc-navbar-mobile", {
        transparent,
        light: transparent && light,
        minimized,
        filterOpened,
        noSearch,
      })}
    >
      <Grid fluid>
        <Row className="bc-navbar-row">
          <Col xs={12}>
            <div className="bc-navbar__content-wrapper">
              <NavbarLogo />
              <div className={styles.buttonsWrapper}>
                {(() => {
                  switch (sitePhase) {
                    case "Nomination":
                      return (
                        <NavButton
                          label={Icons.NOMINEE} //PLUS_OUTLINED
                          to="/share-your-story"
                        />
                      );
                    case "Voting":
                      return (
                        <NavButton
                          label={Icons.NOMINEE}
                          to="/vote-for-ultimate-founders"
                        />
                      );
                    case "Selection":
                      return (
                        <NavButton
                          label={Icons.CUP}
                          to="/ultimate-founders-winners"
                        />
                      );
                    default:
                      return null;
                  }
                })()}

                {showBack ? (
                  <div className="bc-icon-button" onClick={() => navigate(-1)}>
                    <span className="icon">{Icons.LEFT_ARROW}</span>
                    <span className="label">BACK</span>
                  </div>
                ) : (
                  <DropdownPanelMobile
                    label=""
                    onChangeState={setFilterOpened}
                    minimized={minimized}
                    noSearch={noSearch}
                  >
                    <MobileNavigationDropdown
                      sitePhase={sitePhase}
                      newsletterSent={newsletterSent}
                      downloadLink={downloadLink}
                      likedStoriesLength={likedStoriesLength}
                    />
                  </DropdownPanelMobile>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row className={filterOpened || noSearch ? "hidden" : ""}>
          <Col xs={12}>
            <LocationsListFilter minimized={minimized} />
          </Col>
        </Row> */}
      </Grid>
    </nav>
  );
};

NavbarMobile.defaultProps = {
  transparent: false,
  minimized: false,
  noSearch: true,
  showBack: false,
  expandedVisible: false,
};

NavbarMobile.propTypes = {
  transparent: PropTypes.bool,
  minimized: PropTypes.bool,
  noSearch: PropTypes.bool,
  showBack: PropTypes.bool,
  expandedVisible: PropTypes.bool,
};

export default NavbarMobile;
