import React from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import { isMobileDevice } from 'responsive-react';

import { Icons } from '../icon';
import FadeInWrapper from '../fade-in-wrapper';
import Button from '../button';
import { useScrollTop } from '../../helpers/hooks';
import {
  SUCCESS_DESCRIPTION
} from '../../constants/common';

const WizardStepFour = () => {
  const navigate = useNavigate();
  const isMobile = isMobileDevice();
  useScrollTop(null, '.bc-tell-story-wizard', false);

  return (
    <div className={clsx('bc-wizard-success', { mobile: isMobile })}>
      <div className='bc-wizard-success__icons-wrapper'>
        <div className='success-marker'>{Icons.MARKER_NEW}</div>
      </div>
      <FadeInWrapper duration={500} scaleFrom={0.95}>
        <div className='bc-success-title'>
          Story Submitted!
        </div>
      </FadeInWrapper>
      <FadeInWrapper delay={500} duration={500}>
        <p>{SUCCESS_DESCRIPTION}</p>
      </FadeInWrapper>
      <FadeInWrapper delay={1500} duration={500}>
        <div className='step-submit'>
          <Button
            newDesign
            onClick={() => navigate('/homepage')}
            label='Back to the site'
          />
        </div>
      </FadeInWrapper>
    </div>
  );
};

export default WizardStepFour;
