import React from 'react';

import './background.scss';

const stroke = (
  <svg xmlns='http://www.w3.org/2000/svg' width='1355' height='1061' viewBox='0 0 1355 1061'>
    <path fill='none' fillRule='evenodd' stroke='#2CCCE5' strokeDasharray='10' d='M1355.347 519.721c0 292.515-640.465 540.566-894.456 540.566C206.901 1060.287 1 823.159 1 530.644 1 238.13 206.901 1 460.891 1c253.991 0 894.456 226.207 894.456 518.721z' />
  </svg>
);

const Background = () => (
  <>
    <div style={{ position: 'fixed', top: '-500px', left: '-1000px' }} className='bg-path-1 bg'>
      <div className='svg-container'>
        {stroke}
      </div>
    </div>
    <div style={{ position: 'fixed', bottom: 0, right: '-1000px' }} className='bg-path-2 bg'>
      <div className='svg-container'>
        {stroke}
      </div>
    </div>
    <div style={{ position: 'fixed', bottom: '-600px', left: '-800px' }} className='bg-path-1 bg'>
      <div className='svg-container'>
        {stroke}
      </div>
    </div>
  </>
);

export default Background;
