
import ImageItem from './image-item';
import "./multiple-images-selector.scss";

const areas = ['img-1', 'img-2', 'img-3', 'img-4', 'img-5', 'img-6', 'img-7', 'img-8'];


export function MultipleFiles({ files, onRemove }) {
    return (<div className='bc-files-upload__preview-wrapper'>
        {files.map((item, i) => (
            <ImageItem
                key={i}
                onRemove={onRemove}
                // isMobileOrTablet={isMobileOrTablet}
                className={areas[i] || ''}
                item={item} />
        ))}
    </div>
    );
}
