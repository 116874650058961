import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icons } from '../../icon';

const ImageItem = ({
  item,
  className,
  isMobileOrTablet,
  onRemove
}) => {
  if (!item?.url)
    return null;
  //else
  const isVideo = item.mime?.indexOf('video/') !== -1 || item.mime?.indexOf('audio/') !== -1;

  return (
    <>
      {isVideo
        ? (
          <div
            className={clsx('bc-file-upload__preview', className, { mobile: isMobileOrTablet })}
            key={item.id}>
            <video preload='metadata'>
              <track kind='captions' />
              <source src={`${item.url}#t=0.5`} type={item.mime} />
            </video>
            <div className='video-play'>
              {Icons.VIDEO_PLACEHOLDER}
            </div>
            <div
              onClick={() => {
                onRemove(item.id);
              }}
              className='close-preview'>
              {Icons.CLOSE}
            </div>
          </div>
        )
        : (
          <div
            style={{ backgroundImage: `url(${item.url})` }}
            className={clsx('bc-file-upload__preview', className, { mobile: isMobileOrTablet })}
            key={item.name}>
            <div className='close-preview'
              onClick={() => {
                onRemove(item.id);
              }}
            >
              {Icons.CLOSE}
            </div>
          </div>
        )}
    </>
  );
};

ImageItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  className: PropTypes.string.isRequired,
  isMobileOrTablet: PropTypes.bool,
  onRemove: PropTypes.func.isRequired
};

export default React.memo(ImageItem);
