import { apolloClient } from "../index";
import { gql } from "@apollo/client";

// export const isCommitteeMember = async (userEmail) => {
//   const COMMITTEE_MEMEBERS = gql`
//     query GetCommittee {
//       committee(publicationState: PREVIEW) {
//         data {
//           attributes {
//             members_emails
//           }
//         }
//       }
//     }
//   `;
export const isCommitteeMember = async (userEmail) => {
  const COMMITTEE_MEMEBERS = gql`
    query GetCommittee {
      committee(publicationState: PREVIEW) {
        data {
          attributes {
            comittee_member {
              data {
                attributes {
                  email
                }
              }
            }
          }
        }
      }
    }
  `;
  try {
    const dbResult = await apolloClient.query({
      query: COMMITTEE_MEMEBERS,
    });
    if (dbResult.error) throw dbResult.error;
    //else
    let committeeMembers =
      dbResult?.data?.committee?.data?.attributes?.comittee_member?.data?.map(
        (x) => x?.attributes?.email
      );
    // dbResult?.data?.committee?.data?.attributes?.members_emails;
    const _isCommitteeMember = committeeMembers?.includes(userEmail);
    return _isCommitteeMember;
  } catch (error) {
    console.error(error.message);
    return false; //Do not show wird error to user. May be handled in an other way!
  }
};
