import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LIKED_STORIES } from "../../constants/common";
import Navbar from "../../components/navbar";
import Support from "../../components/support";
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer";
import { Col, Grid, Row } from "react-flexbox-grid";
import clsx from "clsx";
import { isMobileDevice } from "responsive-react";
import { AnimatedContent } from "../../components/animated-content";
import ThumbnailsList from "../best-stories/best-stories-content";
import { AuthContext } from "../../auth/AuthProvider";

import { gql } from "@apollo/client";
import { apolloClient } from '../../index';


import "../best-stories/best-stories.scss";

const getLikedStories = async (userId) => {
    const GET_LIKED_STORIES = gql`
    query GetLikedStories($userId:ID!) {
        primo(id:$userId){data{id attributes{
        liked_stories{ data{
          id 
          attributes{ 
            nomination_year
            author_name
            nominated_primo_name
            cover_image{data{attributes{url}}}
            title
            text
            likes_number
            rank
            location {data {id attributes{name}}}
            tags {data {id attributes {name}}}
          }}}
      }}}
    }
    `;

    try {
        const storiesResult = await apolloClient.query({
            query: GET_LIKED_STORIES,
            variables: { userId },
        });
        if (storiesResult.error) throw storiesResult.error
        //else
        let stories = storiesResult?.data?.primo?.data?.attributes?.liked_stories?.data;
        stories = stories?.length ? stories.map(x => {
            return {
                "id": x.id,
                "nomination_year": x.attributes?.nomination_year,
                "nominated_primo_name": x.attributes?.nominated_primo_name,//TODO
                "cover_image": x.attributes?.cover_image?.data?.attributes?.url,
                "title": x.attributes?.title,
                "text": x.attributes?.text,
                "location": x.attributes?.location?.data?.attributes?.name,
                "likes": x.attributes?.likes_number,
                "liked": false, //TODO
                "tags": x.attributes?.tags?.data?.map(t => t.attributes?.name),
            }
        }) : null;
        if (stories) {
            // Sort people by birth year
            // stories.sort((a, b) => b.nomination_year - a.nomination_year);

            // Group by nomination_year
            const groupedStories = stories.reduce((acc, story) => {
                const year = story.nomination_year;
                if (!acc[year]) {
                    acc[year] = [];
                }
                acc[year].push(story);
                return acc;
            }, {});
            //return groupedStories;
            const groupedStoriesByYear = Object.keys(groupedStories).map(x => ({ year: x, stories: groupedStories[x] }));
            groupedStoriesByYear.sort((a, b) => b.year - a.year);
            return groupedStoriesByYear;
        }
        return null;

    } catch (error) {
        console.error(error.message);
        return null;//Do not show wird error to user. May be handled in an other way!
    }
}

function LikedStories() {
    const me = useContext(AuthContext);
    const navigate = useNavigate();
    const [likedStoriesByYear, setLikedStoriesByYear] = useState(null);
    useEffect(() => {
        if (me?.id) {
            getLikedStories(me.id).then((stories) => {
                setLikedStoriesByYear(stories);
                if (!stories?.length) {
                    navigate("/");
                }
            }).catch(error => {
                //handle error
                console.error(error.message);
            })
        }
    }, [me]);

    const handleClick = useCallback((item) => {
        if (item) {
            navigate(`/stories/${item.id}`);
        }
    }, []);

    const handleLiking = useCallback((newValue) => {
        window.location.reload();
    }, []);

    return (
        <div className="best-stories__background">
            <div
                className={clsx("best-stories__container", {
                    mobile: isMobileDevice(),
                })}
            >
                <Navbar />
                <Grid>
                    <Row center="xs">
                        <AnimatedContent>
                            <div className="title">{LIKED_STORIES}</div>
                        </AnimatedContent>
                    </Row>
                    <Row center="xs">
                        <Col lg={10} md={10} xs={12}>
                            <AnimatedContent custom={1} delay={0.1}>
                                <div className="description">
                                    {/* <p>{PAST_WINNERS_DESCRIPTION}</p> */}
                                </div>
                            </AnimatedContent>
                        </Col>
                    </Row>

                    <AnimatedContent
                        custom={2}
                        delay={0.1}
                        threshold={0.1}
                        rootMargin={0}
                    >
                        {!!likedStoriesByYear?.length &&
                            likedStoriesByYear.map((x, i) => {
                                return <div key={i}>
                                    <div className="periodLabel">{x.year}</div>
                                    <ThumbnailsList
                                        items={x.stories}
                                        onClick={handleClick}
                                        onLiking={handleLiking}
                                        showTags />
                                </div>
                            })
                        }
                    </AnimatedContent>

                </Grid>
            </div>
            <Footer>
                <Support />
                <Copyright />
            </Footer>
        </div>
    );
}

export default LikedStories;