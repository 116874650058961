import Dropzone from "react-dropzone";
import { DROP_YOUR_DOCUMENTS_HERE } from "../../../constants/common";
import "./uploadDropZone.scss";

// const SingleImg = styled.div`
//   ${(props) =>
//     props.$url &&
//     `background: url(${process.env.REACT_APP_API_BASE_URL}${props.$url}) center center no-repeat;`}
//   /* ${(props) => props.$height && `height: ${props.$height}px;`}
//   ${(props) => props.$width && `width: ${props.$width}px;`} */
//   height:150px;
//   border-radius: 20px;
// `;

//async function uploadFile(file, token, folder, documentType) {}

/**
 * single, true if accept a single file
 * uploading: true while uploading files
 * uploaded: list of file uploaded
 * filesDroped: are the list of file droped
 */
export const UploadDropZone = ({
  text,
  label,
  multiple,
  maxFiles,
  maxSize,
  disabled,
  uploading,
  onDroped,
  onRejectedFiles
}) => {
  const handleDrop = (acceptedFiles) => {
    // setFileNames(acceptedFiles.map((file) => file.name));
    onDroped(acceptedFiles);
  };
  const handleRejectedFiles = (rejectedFiles) =>{
    //...
    onRejectedFiles(rejectedFiles)
  }

  return (
    <Dropzone
      onDrop={handleDrop}
      onDropRejected={handleRejectedFiles}
      maxFiles={maxFiles}
      maxSize={maxSize}
      multiple={multiple}
      disabled={disabled}
      // accept="image/*"
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
      }) => {
        const additionalClass = isDragAccept
          ? "acceptUploadZone"
          : isDragReject
            ? "rejectUploadZone"
            : isDragActive
              ? "isdragUploadZone"
              : "";

        return (
          <div
            {...getRootProps({
              className: `dropzoneUploadZone ${additionalClass}`
            })}
          >
            <input {...getInputProps()} />
            <div>
              {uploading && <div className="zoneLabel">UPLOADING...</div>}
              {!uploading && (
                <>
                  {/* {isDragActive ? "📂" : "📁"} */}
                  {label && <div className="zoneLabel">
                    {label}
                  </div>}
                  <span>{text ?? DROP_YOUR_DOCUMENTS_HERE}</span>
                </>
              )}
              {/* {!uploading && filesUploaded && filesUploaded[0]?.formats && (
                <SingleImg
                  $url={filesUploaded[0].formats.small.url}
                  $height={filesUploaded[0].formats.small.height}
                  $width={filesUploaded[0].formats.small.width}
                />
              )} */}
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};
