import React from "react";
import PropTypes from "prop-types";

import BestStoryItemOriginal from "./best-story-item-original";

const Thumbnail = (props) => (
  <>
    <BestStoryItemOriginal {...props} />
  </>
);

Thumbnail.propTypes = {
  data: PropTypes.shape({
    rank: PropTypes.number,
    author: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    likes: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Thumbnail;
