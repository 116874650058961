import React, { useLayoutEffect, useState, useEffect, useContext } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { AppSettingsContext } from "../../../App";

// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import clsx from "clsx";
import { isMobileDevice } from "responsive-react";

import Loader from "../../../components/loader";

// Components
import Navbar from "../../../components/navbar";
import Support from "../../../components/support";
import Copyright from "../../../components/copyright/copyright";
import Footer from "../../../components/footer";
import ThumbnailsList from "../best-stories-content";
import Button from "../../../components/button";
import { AnimatedContent } from "../../../components/animated-content";
import PastWinnersGrid from "../../past-winners/PastWinnersGrid";

// Styles
import "./../best-stories.scss";
import styles from "./best-stories-winners.module.scss";
import { useTransparent } from "../../../helpers/hooks";

import {
  PAST_WINNERS,
  ULTIMATE_FOUNDER_WINNERS_DESCRIPTION,
  ULTIMATE_FOUNDER_WINNERS_TITLE,
} from "../../../constants/common";

export const BestStoriesWinners = () => {
  const { transparent } = useTransparent();
  const { nomination_year, download_link, site_phase } =
    useContext(AppSettingsContext);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="scroller" className="best-stories__background">
      <div
        className={clsx("best-stories__container", {
          mobile: isMobileDevice(),
        })}
      >
        <Navbar />

        <AnimatedContent>
          <div className="title">{ULTIMATE_FOUNDER_WINNERS_TITLE}</div>
        </AnimatedContent>
        <Grid>
          <Row center="xs">
            <Col lg={10} md={10} xs={12}>
              <AnimatedContent custom={1} delay={0.1}>
                <div className="description">
                  <p>{ULTIMATE_FOUNDER_WINNERS_DESCRIPTION}</p>
                </div>
              </AnimatedContent>
            </Col>
          </Row>
          <div>
            <PastWinnersGrid firstNominationPeriod={nomination_year} />
          </div>
        </Grid>
      </div>
      <Footer>
        <Support />
        <Copyright />
      </Footer>
    </div>
  );
};
