import Dropzone from "react-dropzone";
import { DROP_YOUR_DOCUMENTS_HERE } from "../../../constants/common";
import "../../../components/tell-your-story-wizard/uploadDropZone/uploadDropZone.scss";
import styled from "styled-components";

const SingleImg = styled.div`
  ${(props) =>
    props.$url &&
    `background: url(${process.env.REACT_APP_API_BASE_URL}${props.$url}) center center no-repeat;`}
  /* ${(props) => props.$height && `height: ${props.$height}px;`}
  ${(props) => props.$width && `width: ${props.$width}px;`} */
  height:150px;
  border-radius: 20px;
`;

//async function uploadFile(file, token, folder, documentType) {}

/**
 * single, true if accept a single file
 * uploading: true while uploading files
 * uploaded: list of file uploaded
 * filesDroped: are the list of file droped
 */
export const UploadDropZone = ({
  text,
  single,
  uploading,
  filesUploaded,
  filesDroped,
}) => {
  const handleDrop = (acceptedFiles) => {
    // setFileNames(acceptedFiles.map((file) => file.name));
    filesDroped(acceptedFiles);
  };

  return (
    <Dropzone
      onDrop={handleDrop}
    // accept="image/*"
    // minSize={1024}
    // maxSize={3072000}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
      }) => {
        const additionalClass = isDragAccept
          ? "acceptUploadZone"
          : isDragReject
            ? "rejectUploadZone"
            : "";

        return (
          <div
            {...getRootProps({
              className: `dropzoneUploadZone ${additionalClass}`,
            })}
          >
            <input {...getInputProps()} />
            <div>
              {uploading && <p>UPLOADING...</p>}
              {!uploading && !filesUploaded?.length && (
                <>
                  <span>{isDragActive ? "📂" : "📁"}</span>
                  <p>{text ?? DROP_YOUR_DOCUMENTS_HERE}</p>
                </>
              )}
              {!uploading && filesUploaded && filesUploaded[0]?.formats && (
                <SingleImg
                  $url={filesUploaded[0].formats.small.url}
                  $height={filesUploaded[0].formats.small.height}
                  $width={filesUploaded[0].formats.small.width}
                />
              )}
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};
