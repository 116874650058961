import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { COMMITTEE_STORIES } from "../../constants/common";
import Navbar from "../../components/navbar";
import Support from "../../components/support";
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer";
import { Col, Grid, Row } from "react-flexbox-grid";
import clsx from "clsx";
import { isMobileDevice } from "responsive-react";
import { AnimatedContent } from "../../components/animated-content";
import ThumbnailsList from "../best-stories/best-stories-content";
import { AppSettingsContext } from "../../App";

import { gql } from "@apollo/client";
import { apolloClient } from '../../index';

import "../best-stories/best-stories.scss";


const getCommitteeStories = async (nominationYear) => {
    const GET_COMMITTEE_STORIES = gql`
    query GetCommitteeStories {
        committee(publicationState:PREVIEW) {
          data {
            attributes {
              stories(publicationState:PREVIEW) {
                data{
                  id
                  attributes{ 
                    author_name
                    nominated_primo_name
                    cover_image{data{attributes{url}}}
                    title
                    text
                    likes_number
                    rank
                    location {data {id attributes{name}}}
                    tags {data {id attributes {name}}}
                  }
                }
              }
            }
          }
        }
      }
    `;
    const GET_COMMITTEE_STORIES_FILTRED = gql`
    query GetCommitteeStories($nominationYear:Long!) {
        committee(publicationState:PREVIEW) {
          data {
            attributes {
              stories(filters:{nomination_year:{eq:$nominationYear}}, publicationState:PREVIEW) {
                data{
                  id
                  attributes{ 
                    author_name
                    nominated_primo_name
                    cover_image{data{attributes{url}}}
                    title
                    text
                    likes_number
                    rank
                    location {data {id attributes{name}}}
                    tags {data {id attributes {name}}}
                  }
                }
              }
            }
          }
        }
      }
    `;
    try {
        const storiesResult = nominationYear ?
        await apolloClient.query({
            query: GET_COMMITTEE_STORIES_FILTRED,
            variables: { nominationYear },
        })
        :
        await apolloClient.query({
            query: GET_COMMITTEE_STORIES
        })
        ;
        if (storiesResult.error) throw storiesResult.error
        //else
        let stories = storiesResult?.data?.committee?.data?.attributes?.stories?.data;
        stories = stories?.length ? stories.map(x => {
            return {
                "id": x.id,
                "nomination_year": x.attributes?.nomination_year,
                "nominated_primo_name": x.attributes?.nominated_primo_name,//TODO
                "cover_image": x.attributes?.cover_image?.data?.attributes?.url,
                "title": x.attributes?.title,
                "text": x.attributes?.text,
                "location": x.attributes?.location?.data?.attributes?.name,
                "likes": x.attributes?.likes_number,
                "liked": false, //TODO
                "tags": x.attributes?.tags?.data?.map(t => t.attributes?.name),
            }
        }) : null;
        return stories;

    } catch (error) {
        console.error(error.message);
        return null;//Do not show wird error to user. May be handled in an other way!
    }
}

function CommitteeStories() {
    //const {nomination_year} = useContext(AppSettingsContext);
    const navigate = useNavigate();
    const [committeeStories, setCommitteeStories] = useState(null);
    useEffect(() => {
        //if (nomination_year) { //TODO: needs better spec : Disable filtring with nomination year for committee stories
            getCommitteeStories().then((stories) => {
                setCommitteeStories(stories);
            }).catch(error => {
                //handle error
                console.error(error.message);
            })
        //}
    }, []);

    const handleClick = useCallback((item) => {
        if (item) {
            navigate(`/stories/${item.id}`);
        }
    }, []);

    const handleLiking = useCallback((newValue) => {
        window.location.reload();
    }, []);

    return (
        <div className="best-stories__background">
            <div
                className={clsx("best-stories__container", {
                    mobile: isMobileDevice(),
                })}
            >
                <Navbar />
                <Grid>
                    <Row center="xs">
                        <AnimatedContent>
                            <div className="title">{COMMITTEE_STORIES}</div>
                        </AnimatedContent>
                    </Row>
                    <Row center="xs">
                        <Col lg={10} md={10} xs={12}>
                            <AnimatedContent custom={1} delay={0.1}>
                                <div className="description">
                                    {/* <p>{PAST_WINNERS_DESCRIPTION}</p> */}
                                </div>
                            </AnimatedContent>
                        </Col>
                    </Row>

                    <AnimatedContent
                        custom={2}
                        delay={0.1}
                        threshold={0.1}
                        rootMargin={0}
                    >

                        

                        {committeeStories ? 
                        <>
                            {/* <div className="periodLabel">{nomination_year}</div> */}
                            <ThumbnailsList items={committeeStories}
                                        onClick={handleClick}
                                        onLiking={handleLiking}
                                        showTags />
                                        </> :
                                        <div className="periodLabel">Comming soon...</div>
                                        // <div className="periodLabel">Exciting Updates Await...</div>

                                        
                                    }
                    </AnimatedContent>

                </Grid>
            </div>
            <Footer>
                <Support />
                <Copyright />
            </Footer>
        </div>
    );
}

export default CommitteeStories;