import React, { useState, useCallback, useEffect, useLayoutEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Pagination } from "../../blent-system/pagination";
import { Col, Grid, Row } from "react-flexbox-grid";
import clsx from "clsx";
import { isMobileDevice } from "responsive-react";
import { maxThumbsNbr } from "./../../serverConfig";
import { apolloClient } from '../../index';

// Components
import Loader from "../../components/loader";
import Navbar from "../../components/navbar";
import Support from "../../components/support";
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer";
import ThumbnailsList from "../best-stories/best-stories-content";
import { gql } from "@apollo/client";

// Styles
import "../best-stories/best-stories.scss";
import styles from "./purposes.module.scss";

import { PURPOSE_DESCRIPTION } from "../../constants/common";
import { AnimatedContent } from "../../components/animated-content";
// import { getCommonData, getHomePageGlobalData } from "./../../data";

export async function loadPurposesThumb(page, pageSize) {
  const PURPOSES_QUERY = gql`
  query GetPurposes($page: Int = 1, $pageSize: Int) {
    purposes(pagination: { page: $page, pageSize: $pageSize }) {
      data {
        id
        attributes {
          title
          description
          url
          cover_image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          pageCount
          page
          pageSize
        }
      }
    }
  }
`;
  try {
    const purposesResult = await apolloClient.query({
      query: PURPOSES_QUERY,
      variables: { page, pageSize },
    });
    if (purposesResult.error) {
      throw purposesResult.error
    };
    //else
    const { data, meta } = purposesResult?.data?.purposes;
    const purposes = data?.length ? data.map((x) => ({
      id: x.id,
      cover_image: x.attributes.cover_image.data[0].attributes?.url,
      text: x.attributes.description,
      title: x.attributes.title,
      url: x.attributes.url,
    })) : null;

    return { purposes, pagination: meta?.pagination }

  } catch (error) {
    console.error(error.message);
    return null;//Do not show wird error to user. May be handled in an other way!
  }
}

const Purposes = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [purposesPageData, setPurposesPageData] = useState();//{ purposes, pagination }



  // const { loading, error, data } = useQuery(PURPOSES_QUERY, {
  //   variables: {
  //     page: !page || page < 0 ? 1 : page,
  //     pageSize: maxThumbsNbr ?? 12,
  //   },
  // });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      const _page = +searchParams.get("page");
      const page = !_page || _page < 0 ? 1 : _page;
      const pageSize = maxThumbsNbr ?? 12; //TODO: put 12 in a config file
      loadPurposesThumb(page, pageSize).then(({ purposes, pagination }) => {
        setPurposesPageData({ purposes, pagination });
        setLoading(false);
      });
    } catch (error) {
      console.error(error.message);
      setErrorMessage(error.message);
      setLoading(false);
    }
  }, []);

  const handleClick = useCallback((item) => {
    if (item?.url) {
      const newWindow = window.open(item.url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  }, []);

  if (loading) {
    return <Loader show />;
  }
  if (errorMessage || !purposesPageData) {
    //TODO LOG
    // navigate("/error");
    return <div>{errorMessage}</div>
  }

  // const { data: purposes, meta } = data?.purposes;
  // const _purposes = purposes.map((x) => ({
  //   id: x.id,
  //   cover_image: x.attributes.cover_image.data[0].attributes?.url,
  //   text: x.attributes.description,
  //   title: x.attributes.title,
  //   url: x.attributes.url,

  //   // id,
  //   // nominated_primo_name,
  //   // cover_image,
  //   // title,
  //   // location,
  //   // likes,
  //   // liked,
  //   // text,
  //   // tags,
  // }));

  return (
    !purposesPageData ? null : <div className={styles.background} data-testid="Purposes">
      <div
        id="best-stories__container"
        className={clsx("best-stories__container", {
          mobile: isMobileDevice(),
        })}
      >
        <Navbar />

        <Grid>
          <Row center="xs">
            <AnimatedContent>
              <div className="title">PURPOSE</div>
            </AnimatedContent>
          </Row>
          <Row center="xs">
            <Col lg={10} md={10} xs={12}>
              <AnimatedContent custom={1} delay={0.1}>
                <div className="description">
                  <p>{PURPOSE_DESCRIPTION}</p>
                </div>
              </AnimatedContent>
            </Col>
          </Row>
          <AnimatedContent
            custom={2}
            delay={0.1}
            threshold={0.1}
            rootMargin={0}
          >
            <ThumbnailsList
              items={purposesPageData?.purposes}
              simplified
              accent="green"
              onClick={handleClick}
            />
          </AnimatedContent>
          {purposesPageData?.pagination &&
            purposesPageData?.pagination?.total > purposesPageData?.pagination?.pageSize && (
              <Row center="xs">
                <AnimatedContent>
                  <Pagination
                    activePage={purposesPageData.pagination.page}
                    numberOfPages={purposesPageData.pagination.pageCount}
                    onPageChange={(n) => {
                      setSearchParams({ page: n });
                    }}
                  />
                </AnimatedContent>
              </Row>
            )}
        </Grid>
      </div>
      <Footer>
        <Support />
        <Copyright />
      </Footer>
    </div>
  );
};

export default Purposes;
