import React, { useCallback, useLayoutEffect, useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Pagination } from "../../blent-system/pagination";
import { Col, Grid, Row } from "react-flexbox-grid";
import clsx from "clsx";
import { isMobileDevice } from "responsive-react";
import { maxThumbsNbr } from "../../serverConfig";
// Components
import Loader from "../../components/loader";
import Navbar from "../../components/navbar";
import Support from "../../components/support";
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer";
import ThumbnailsList from "../best-stories/best-stories-content";
import { gql, useApolloClient } from "@apollo/client";

import { StoriesFilter } from "./StoriesFilter"

// Styles
import "../best-stories/best-stories.scss";
import styles from "./stories.module.scss";

import { ALL_STORIES } from "../../constants/common";
import { AnimatedContent } from "./../../components/animated-content";
// import { getCommonData, getHomePageGlobalData } from "./../../data";

const ALL_STORIES_QUERY = gql`
query GetAllStories($filter:StoryFiltersInput!, $page: Int = 1, $pageSize: Int = ${maxThumbsNbr}) {
  stories(pagination: { page: $page, pageSize: $pageSize } 
    filters:$filter     
    sort: "nomination_year:desc") {
    data {
      id
      attributes {
        nomination_year
        author_name
        nominated_primo_name
        cover_image{data{attributes{url}}}
        title
        text
        likes_number
        location {data {id attributes{name}}}
        tags {data {id attributes {name}}}
      }
    }
    meta {
      pagination {
        total
        pageCount
        page
        pageSize
      }
    }
  }
}
`;


const Stories = ({ globalSettings }) => {
  // const [loading, error, data] = useData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState({ onlyWinners: true });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const page = +searchParams.get("page");

  // Access the ApolloClient instance from the ApolloProvider
  const client = useApolloClient();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { loading, error, data } = await client.query({
          query: ALL_STORIES_QUERY,
          variables: {
            page: !page || page < 0 ? 0 : page,
            pageSize: maxThumbsNbr ?? 12,
            filter: filter.onlyWinners ? { is_winner: { eq: true } } : {}
          },
        });

        // Update the state variables
        setLoading(loading);
        setError(error);
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [filter, client, page]);

  // const { loading, error, data } = useQuery(ALL_STORIES_QUERY, {
  //   variables: {
  //     page: !page || page < 0 ? 0 : page,
  //     pageSize: maxThumbsNbr ?? 12,
  //     onlyWinners: filter.onlyWinners
  //   },
  // });



  const handleClick = useCallback((item) => {
    if (item?.id) {
      navigate(`/stories/${item.id}`);
    }
    // const newWindow = window.open(`/stories/${itemId}`, "_blank", "noopener, noreferrer");
    // if (newWindow) newWindow.opener = null;
  }, []);

  if (!globalSettings || loading) {
    return <Loader show />;
  }
  if (error || !data) {
    //TODO LOG
    // navigate("/error");
    return <div>{error}</div>
  }

  const pagination = data?.stories?.meta.pagination;
  const stories = data?.stories?.data;

  const _stories = stories?.map(x => {
    return {
      "id": x.id,
      "nominated_primo_name": x.attributes?.nominated_primo_name,//TODO
      "cover_image": x.attributes?.cover_image?.data?.attributes?.url,
      "title": x.attributes?.title,
      "text": x.attributes?.text,
      "location": x.attributes?.location?.data?.attributes?.name,
      "likes": x.attributes?.likes_number,
      "liked": false, //TODO
      "tags": x.attributes?.tags?.data?.map(t => t.attributes?.name),
    }
    // return {
    //   "id": x.id,
    //   "title": x.attributes?.title,
    //   "text": x.attributes?.text,
    //   "cover_image": x.attributes?.cover_image?.data?.attributes?.url,
    // }
  })

  return (
    <div className={styles.background} data-testid="Purposes">
      <div
        id="best-stories__container"
        className={clsx("best-stories__container", {
          mobile: isMobileDevice(),
        })}
      >
        <Navbar />

        <Grid>
          <Row center="xs">
            <AnimatedContent>
              <div className="title">{ALL_STORIES}</div>
            </AnimatedContent>
          </Row>
          <Row center="xs">
            <Col xs={12}>
              <AnimatedContent custom={1} delay={0.1}>
                <StoriesFilter value={filter} onChange={(v) => {
                  setFilter(v)
                }} />
              </AnimatedContent>
            </Col>
          </Row>
          <AnimatedContent
            custom={2}
            delay={0.1}
            threshold={0.1}
            rootMargin={0}
          >
            <ThumbnailsList
              items={_stories}
              simplified
              accent="green"
              onClick={handleClick}
              showTags
            />
          </AnimatedContent>
          {pagination &&
            pagination?.total > pagination?.pageSize && (
              <Row center="xs">
                <AnimatedContent>
                  <Pagination
                    activePage={pagination.page}
                    numberOfPages={pagination.pageCount}
                    onPageChange={(n) => {
                      setSearchParams({ page: n });
                    }}
                  />
                </AnimatedContent>
              </Row>
            )}
        </Grid>
      </div>
      <Footer>
        <Support />
        <Copyright />
      </Footer>
    </div>
  );
};

export default Stories;
