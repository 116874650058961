import React, { useState } from "react";

import { VideoPlayer } from "../../../../components/video-player";
import Popup from "../../../../components/popup";
import { Icons } from "../../../../components/icon";

import styles from "./second-section.module.scss";

const VideoSlide = ({ imgUrl, videoUrl, message, onClick }) => {
  const [show, setShow] = useState(false);
  return (
    <div className={styles.root} onClick={onClick}>
      <img
        className={styles.background}
        src={imgUrl}
        id="video-image"
        alt="Page background - group of people"
      />
      {videoUrl && (
        <div className={styles.play} onClick={() => setShow(true)}>
          {Icons.PLAY_BUTTON}
          {Icons.PLAY_BUTTON}

          {message && <div className={styles.text}>{message}</div>}
        </div>
      )}
      {!videoUrl && (
        <div className={styles.play} onClick={() => onClick && onClick()}>
          {message && <div className={styles.text}>{message}</div>}
        </div>
      )}

      {videoUrl && (
        <Popup
          buttons={[]}
          size="xl"
          isShown={show}
          handleClose={() => setShow(false)}
        >
          <VideoPlayer url={videoUrl} isSelected />
        </Popup>
      )}
    </div>
  );
};

export default VideoSlide;
