import React, { useCallback, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import clsx from 'clsx';
import { isMobileDevice } from 'responsive-react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Components
import WizardNavbar from '../navbar/wizard-navbar';
import WizardStepOne from './wizard-step-one';
import WizardStepTwo from './wizard-step-two';
import WizardStepThree from './wizard-step-three';
import WizardStepFour from './wizard-step-four';
import WizardStepUltimate from './wizard-step-ultimate';
import { UploadProgress } from '../loader';
import UnsavedDialog from '../unsaved-dialog';

// Styles
import './tell-your-story-wizard.scss';
import Support from '../support';
import Copyright from '../copyright/copyright';
import Footer from '../footer';


const TellYourStoryWizard = ({ id = null }) => { //
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  const [step, setStep] = useState(1);//(2);//
  const [storyId, setStoryId] = useState(id);//

  const data = {}; //useSelector(getWizardData);

  const handleLeave = useCallback(() => {
    alert("handleLeave"); //TODO
    //dispatch(resetWizardData());
  }, []);


  return (
    <div className='bc-tell-story-wizard__background'>
      <div className='bc-tell-story-wizard__background_overlay' />
      <div
        className={clsx('bc-tell-story-wizard', { mobile: isMobileDevice() })}
      >
        <WizardNavbar
          step={step}
          showClose={!isLoading}
          onGoBack={() => {
            if(step===2){
              //We need to be sure if the user would like to submit without images or to remove the whole story
              
            }
            navigate(-1);
          }}
        />
        {step === 1 && <WizardStepTwo
          storyId={storyId}
          onGoNext={newId => {
            setStoryId(newId)
            setStep(2);
           
          }} />}

        {step === 2 && storyId && <WizardStepThree storyId={storyId} onGoNext={() => {
          setStep(3);
        }} />}

        {step === 3 && <Grid className='wizard-step__wrapper'>
          <Row center='xs'>
            <Col xs={10} sm={8} md={6} lg={4}>
              <WizardStepFour />
            </Col>
          </Row>
        </Grid>}

      

        <UploadProgress show={isLoading} progress={progress} />
        <UnsavedDialog
          onLeave={handleLeave}
          onNavigate={nextLocation => navigate(nextLocation)}
          hasUnsaved={
            !!Object.keys(data).filter(key => key !== 'nominateUltimateFounder')
              .length
          }
          tabsCommonPath='/share-your-story'
          allowTabs
        />
      </div>
      <Footer theme="dark">
        <Support theme="dark"/>
        <Copyright theme="dark" />
      </Footer>
    </div>
  );
};

export default TellYourStoryWizard;
