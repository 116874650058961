import { useMutation, useQuery, gql } from "@apollo/client";

export const UPLOAD_FILE_MUTATION = gql`
  mutation CreateUploadFile($file: Upload!) {
    upload(file: $file) {
      data {
        id
        attributes {
          formats
        }
      }
    }
  }
`;
export const DETELE_FILE_MUTATION = gql`
  mutation DeleteUploadFile($id: ID!) {
    deleteUploadFile(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;
export const FILE_INFO_QUERY = gql`
  query GetMediaInfo($id: ID!) {
    uploadFile(id: $id) {
      data {
        id
        attributes {
          formats
        }
      }
    }
  }
`;
export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation($input: LocationInput!) {
    createLocation(data: $input) {
      data {
        id
      }
    }
  }
`;
