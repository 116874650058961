import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Col, Grid, Row } from "react-flexbox-grid";
import { Icons } from "../../../../components/icon";
import styles from "./first-section.module.scss";
import sharedStyles from "../shared.module.scss";
import { AnimatedContent } from "../../../../components/animated-content";

export const FirstSection = ({ text }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={clsx(styles.root, {
        [styles.hovered]: hovered,
      })}
    >
      <div className={styles.background} />
      <div className={`${styles.containerW} ${sharedStyles.globalWidth}`}>
        <div className={styles.container}>
          <div className={styles.headerLockupW}>
            <div className={styles.headerLockup} />
          </div>
          <div className={styles.headerImageW}>
            <div className={styles.headerImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

FirstSection.defaultProps = {
  text: `Since 1862, Bacardi’s created a more authentically connected and
              joyful world. But how do we make that happen today? We realized it
              wasn’t exactly by “celebrating moments that matter, one drink at a
              time.” Not all moments are for celebrating, and we don’t always
              have a drink in our hand. That is why Bacardi is always in pursuit
              of a world full of authenticity, joy and connection.`,
};

FirstSection.propTypes = {
  text: PropTypes.string,
};
