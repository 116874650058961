import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageSlide = ({ src, thumbnail, visible, height }) => {
  const [imageSource, setImageSource] = useState(thumbnail);

  React.useEffect(() => {
    let mounted = true;
    if (visible) {
      const fullSizeImage = new Image();
      fullSizeImage.src = src;
      fullSizeImage.onload = () => {
        if (mounted) {
          setImageSource(src);
        }
      };
    }
    return () => {
      mounted = false;
    };
  }, [visible, src]);

  return (
    <div
      className='bc-explore-story__image-wrapper'
      style={{ height }}>
      <img src={imageSource} alt='Img' />
    </div>
  );
};

ImageSlide.defaultProps = {
  visible: false,
  height: 600
};

ImageSlide.propTypes = {
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  visible: PropTypes.bool
  // onClick: PropTypes.func.isRequired
};

export { ImageSlide };
export default React.memo(ImageSlide);
