import styled, { css } from "styled-components";
import * as COLOR from "../color";
import * as CONST from "../const";

const noselect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
`;

export const SwEmpty = styled.div`
  width: 6px;
`;
export const SwGrow = styled.div`
  flex-grow: 1;
`;

export const SwContainer = styled.div`
  position: relative;
  color: ${COLOR.white};
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_semi_bold};
  font-size: ${(props) => (props.$size === "m" ? "16px" : "14px")};
  height: ${(props) => (props.$size === "m" ? "40px" : "32px")};
  width: ${(props) => (props.$autoWidth ? "auto" : "fit-content")};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  max-width: 100%;
`;

export const SwInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

export const SwLabel = styled.label`
  position: relative;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-radius: 100px;
  padding: 4px;
  cursor: pointer;
  background-color: ${(props) =>
    props.value
      ? COLOR.interaction
      : props.$withOffLabel
        ? COLOR.brand
        : COLOR.smoke};

  flex-direction: ${(props) => (props.value ? "row" : "row-reverse")};
  min-width: ${(props) => (props.$size === "m" ? "64px" : "51px")};
`;
export const SwCircleContainer = styled.div`
  background-color: transparent;
  border-radius: 50%;
  content: "";
  height: ${(props) => (props.$size === "m" ? "32px" : "24px")};
  width: ${(props) => (props.$size === "m" ? "32px" : "24px")};
  display: block;
  flex-shrink: 0;
`;
export const SwTextContainer = styled.div`
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2em;
  color: transparent;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */

  ${noselect}
`;

export const SwTextIndicator = styled(SwTextContainer)`
  position: absolute;
  transition: 0.5s;
  z-index: 1;
  color: #fff;
  ${(props) =>
    props.offset &&
    `
    left: ${props.offset.left}px;
    top: ${props.offset.top}px;
    width: ${props.offset.width}px;
    `}
`;
export const SwCircleIndicator = styled(SwCircleContainer)`
  position: absolute;
  transition: 0.5s;
  z-index: 1;
  background-color: #fff;
  ${(props) =>
    props.offset &&
    `
    left: ${props.offset.left}px;
    top: ${props.offset.top}px;
    `}
`;
