import React, { useContext } from "react";
import NavbarLinks from "../../../../components/navbar/navbar-links";
import { AppSettingsContext } from "../../../../App";
import styles from "./footer-section.module.scss";
import sharedStyles from "../shared.module.scss";

export const FooterSection = () => {
  const {
    site_phase: sitePhase,
    global_newsletter_sent: newsletterSent,
    download_link: downloadLink,
  } = useContext(AppSettingsContext);

  return (
    <div className={styles.background}>
      <div className={`${styles.footerContainer}`}>
        {/* <div className={styles.logoContainer} /> */}
        <div />
        <div className={styles.linksWrap}>
          <NavbarLinks
            sitePhase={sitePhase}
            newsletterSent={newsletterSent}
            downloadLink={downloadLink}
            likedStoriesLength={0}
          />
        </div>
      </div>
    </div>
  );
};
