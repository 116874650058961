import { useMutation, gql } from "@apollo/client";
import { useState, useEffect } from "react";
import { StrapiUploadDropZone } from "./uploadDropZone";
import { useCleanBeforeUnload } from "./useCleanBeforeUnload";
import {
  UPLOAD_FILE_MUTATION,
  DETELE_FILE_MUTATION,
  CREATE_LOCATION_MUTATION,
} from "./gqlQueries";

export const UploadLocation = () => {
  const [upload] = useMutation(UPLOAD_FILE_MUTATION);
  const [deleteUploadFile] = useMutation(DETELE_FILE_MUTATION);
  const [createLocation] = useMutation(CREATE_LOCATION_MUTATION);
  const [image, setImage] = useState(null);
  const [fileUploaded, setFileUploaded] = useState();

  //If Image is loaded and the windows will be closed before ending adding
  //of the location we need to clean the server from that uploaded media = "leak"
  useCleanBeforeUnload(() => {
    if (fileUploaded?.id) {
      deleteUploadFile({ variables: { id: fileUploaded?.id } });
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const fileInput = document.getElementById("coverImage");
    // const file = fileInput.files[0];

    try {
      const uploadResponse = await upload({
        variables: { file: image },
      });

      const imageId = uploadResponse?.data?.upload?.data?.id;

      try {
        const createLocationResponse = await createLocation({
          variables: {
            input: {
              name: e.target.title.value,
              description: e.target.description.value,
              code: e.target.code.value,
              coordinates: [e.target.lng.value, e.target.lat.value],
              fun_facts: {},
              cover_image: imageId,
            },
          },
        });

        console.log(
          "Location created:",
          createLocationResponse.data.createLocation.data
        );
      } catch (locationCreationError) {
        try {
          //If the location is not created properly then remove the uploaded image: it is on transaction!
          const errdfd = await deleteUploadFile({ variables: { id: imageId } });
          console.log(errdfd);
        } catch (deleteError) {
          if (deleteError.graphQLErrors[0].extensions.code === "FORBIDDEN") {
            //Do nothing! : The destroy of file are not allowed on the server side
          } else {
            throw deleteError;
          }
        }
        throw locationCreationError;
      }
    } catch (error) {
      //TODO: handle error
      throw error;
    }
  };

  const onImageChange = (event) => {
    console.log(event.target.files);
    setImage(event.target.files[0]);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          margin: "30px 80px",
          maxWidth: "700px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          justifyContent: "stretch",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          <div>
            <label>Name</label>
            <br />
            <input type="text" name="title" />
          </div>
          <div>
            <label>Code</label>
            <br />
            <input type="text" name="code" />
          </div>
        </div>
        <div>
          <label>Description:</label>
          <br />
          <textarea name="description" style={{ width: "100%" }} />
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <div>
            <label>Lng</label>
            <br />
            <input type="text" name="lng" />
          </div>
          <div>
            <label>Lat</label>
            <br />
            <input type="text" name="lat" />
          </div>
        </div>
        <div>
          <label>Fun Facts</label>
          <br />
          <b>TODO!!!</b>
        </div>
        {/* <label>
        fun_facts:
        <input type="text" name="fun_facts" />
      </label>
      <br /> */}
        {/* <label>
          
          <input
            type="file"
            id="coverImage"
            name="coverImage"
            onChange={onImageChange}
          />
        </label> */}
        <div>
          <label>Cover Image:</label>
          <StrapiUploadDropZone
            text="Drop your cover image here or browse your files..."
            fileUploaded={fileUploaded}
            onFileUploaded={(fileChanged) => {
              console.log(fileChanged);
              setFileUploaded(fileChanged);
            }}
          />
        </div>
        <br />
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};
