import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useScrollTop } from '../../helpers/hooks';

const WizardStepOne = ({ onGoNext }) => {
  const data = {};//TODO useSelector(getWizardData);
  // const { user, country } = useMsal();
  const user = {
    name: 'ramzi', //TODO
    username: 'ramzi' //TODO
  };
  const country = 'spain'; //TODO
  useScrollTop(null, '.bc-tell-story-wizard', false);
  useEffect(() => {
    const values = {
      primoName: data.primoName || user.name || '',
      email: data.email || user.username || '',
      country: data.country || country || '-'
    };
    onGoNext(values);
  }, [
    data.primoName,
    user.name,
    data.email,
    user.username,
    data.country,
    country,
    onGoNext
  ]);

  return null;
};

WizardStepOne.propTypes = {
  onGoNext: PropTypes.func.isRequired
};

export default WizardStepOne;
