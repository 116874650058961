import React from 'react';
import PropTypes from 'prop-types';
// import shortid from 'shortid';

// Components
import Input from '../../input';
import { Icons } from '../../icon';

// Styles
import './multi-links-selector.scss';

const MultiLinksSelector = ({ error, onChange, selected }) => {
  const handleClickAdd = React.useCallback(() => {
    if (selected) {
      onChange([...selected, '']);
    } else {
      onChange(['']);
    }
  }, [selected, onChange]);

  return (
    <div className='bc-multi-links'>
      <div className="file-size-info">Must be a valid URL starting with <strong>http://</strong> or <strong>https://</strong></div>
      {selected && selected.map((item, i) => (
        <div key={i} className='bc-multi-link__item'>
          <Input
            label='Video URL'
            type='url'
            onChange={v => {
              selected[i] = v;
              onChange([...selected]);
            }}
            value={item}
            debounceDelay={0} />
          {error && error[i] && (
            <div className='bc-multi-link__item-error'>
              <div>{Icons.ERROR}</div>
              <div>{error[i]}</div>
            </div>
          )}
          <div
            className='bc-multi-link__item-close'
            onClick={() => {
              selected.splice(i, 1);
              onChange([...selected]);
            }}>
            {Icons.CLOSE}
          </div>
        </div>
      ))}
      {(!selected || selected?.length < 5) && (
        <div onClick={handleClickAdd} className='bc-multi-link_item_add'>
          <span>{Icons.PLUS_OUTLINED}</span>
          <span>Add another URL</span>
        </div>
      )}
    </div>
  );
};

MultiLinksSelector.defaultProps = {
  error: null
};

MultiLinksSelector.propTypes = {
  error: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MultiLinksSelector;
