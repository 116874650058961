import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../../auth/AuthProvider";
import { apolloClient } from "../../index";
import { gql } from "@apollo/client";
import { useFormik } from "formik";
import { isTabletDevice, isMobileDevice } from "responsive-react";
import { Col, Grid, Row } from "react-flexbox-grid";

import Input from "../input";
import DropdownSelect, { DropdownSelectMultiple } from "../dropdown-select";
import Button from "../button";
import TextEditor from "../text-editor";
import TextArea from "../text-area";
import SearchInput from "../search-input";

import { useValidateStep } from "./helpers";
import { useScrollTop } from "../../helpers/hooks";
import countriesDetails from "../../helpers/countries-info";
import { STEP_TWO_NOMINATE_DESCRIPTION } from "../../constants/common";
import { AnimatedContent } from "../animated-content";
import { AppSettingsContext } from "../../App";

const createLocationByName = async (locationName) => {
  const CREATE_LOCATION = gql`
    mutation createLocation($data: LocationInput!) {
      createLocation(data: $data) {
        data {
          id
        }
      }
    }
  `;

  try {
    const locationData = countriesDetails.find(
      (x) => x.name.toLowerCase() === locationName.toLowerCase()
    );
    if (!locationData) throw new Error("Unknown location name.");
    //else
    const data = {
      code: locationData.alpha2,
      name: locationData.name,
      coordinates: [locationData.latitude, locationData.longitude],
      publishedAt: new Date(),
    };

    const result = await apolloClient.mutate({
      mutation: CREATE_LOCATION,
      variables: { data },
    });

    if (result.error) throw result.errors;
    return result?.data?.createLocation?.data?.id;
  } catch (error) {
    //handle error
    console.error(error.message);
  }
};

const getOrCreateLocation = async (locationName) => {
  const GET_LOCATION_BY_NAME = gql`
    query getLocationByCode($locationName: String!) {
      locations(filters: { name: { eqi: $locationName } }) {
        data {
          id
        }
      }
    }
  `;
  try {
    const result = await apolloClient.query({
      query: GET_LOCATION_BY_NAME,
      variables: { locationName },
    });
    if (result.error) throw result.error;
    //else
    const exists = !!result?.data?.locations?.data?.length;
    if (exists) {
      return result.data.locations.data[0].id;
    } else {
      //Does not exists yet
      return await createLocationByName(locationName);
    }
  } catch (error) {
    //handle error
    console.error(error.message);
  }
};

const UPDATE_STORY = gql`
  mutation updateStory($storyId: ID!, $data: StoryInput!) {
    updateStory(id: $storyId, data: $data) {
      data {
        id
      }
    }
  }
`;
const CREATE_STORY = gql`
  mutation updateRank($data: StoryInput!) {
    createStory(data: $data) {
      data {
        id
      }
    }
  }
`;
const GET_ULTIMATE_TAGS = gql`
  query getTags {
    preference {
      data {
        attributes {
          ultimate_tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          ultimate_founder_tag {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

const emptyData = {
  title: "",
  text: "",

  author_name: "",
  author_country: "",
  author_email: "",

  nominated_primo_name: "",
  nominated_primo_email: "",
  nominated_primo_country: "",

  tags: [],
};

const loadData = async (storyId, authorEmail) => {
  if (!storyId || !authorEmail) return emptyData;
  //else
  const GET_STORY_INFO = gql`
    query getStoryInfo($storyId: ID!, $authorEmail: String!) {
      stories(
        publicationState: PREVIEW
        filters: {
          and: [
            { id: { eq: $storyId } }
            { author_email: { eqi: $authorEmail } }
          ]
        }
      ) {
        data {
          attributes {
            title
            text
            tags {
              data {
                id
              }
            }
            author_name
            author_email
            author_country
            nominated_primo_name
            nominated_primo_email
            nominated_primo_country
          }
        }
      }
    }
  `;
  try {
    const result = await apolloClient.query({
      query: GET_STORY_INFO,
      variables: { storyId, authorEmail },
    });
    if (result.error) throw result.error;
    if (result?.data?.stories?.data?.length === 1) {
      let _data = result.data.stories.data[0].attributes;
      _data = {
        ..._data,
        tags: _data.tags?.data?.map((x) => x.id) ?? [],
      };
      return _data;
    }
  } catch (error) {
    //handle error
    console.error(error.message);
  }
  return null;
};

const WizardStepTwo = ({ storyId, onGoNext }) => {
  const me = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const [ultimateFounderTagId, setUltimateFounderTagId] = useState(null);
  const [uploading, setUploading] = useState(false);

  const { nomination_year } = useContext(AppSettingsContext);

  useEffect(() => {
    //If storyId != null => update of story or it is a creation of new story
    if (storyId) {
      loadData(storyId, me?.email)
        .then((result) => {
          formik.setValues({
            // ...result,
            author_name: me?.name ?? result?.author_name ?? "",
            author_country: me?.location?.name ?? result?.author_country ?? "",
            author_email: me?.email ?? result?.author_email ?? "",

            nominated_primo_name: result?.nominated_primo_name,
            nominated_primo_country: result?.nominated_primo_country,
            nominated_primo_email: result?.nominated_primo_email,

            text: result?.text,
            title: result?.title,
            tags: result?.tags,
          });
        })
        .catch((error) => {
          //Handle error
          console.error(error.message);
        });
    } else {
      formik.setValues({
        ...emptyData,
        author_name: me?.name ?? "",
        author_country: me?.location?.name ?? "",
        author_email: me?.email ?? "",
      });
    }
  }, [storyId, me]);

  useEffect(() => {
    //author_name
    const _name = me?.name?.length ? me.name : "";
    formik.setFieldValue("author_name", _name, false);

    //author_country
    const _location = me?.location?.name?.length ? me.location.name : "";
    formik.setFieldValue("author_country", _location, false);

    //author_email
    const _email = me?.email?.length ? me.email : "";
    formik.setFieldValue("author_email", _email, false);
  }, [me]);

  useEffect(() => {
    apolloClient
      .query({ query: GET_ULTIMATE_TAGS })
      .then((result) => {
        const _tags =
          result?.data?.preference?.data?.attributes?.ultimate_tags?.data?.map(
            (x) => ({ value: x.id, label: x.attributes?.name })
          );
        setCategories(_tags ?? []);
        const _uFounderTagId =
          result?.data?.preference?.data?.attributes?.ultimate_founder_tag?.data
            ?.id;
        setUltimateFounderTagId(_uFounderTagId);
      })
      .catch((error) => {
        //handle error
        console.error(error.message);
      });
  }, []);

  const locations = countriesDetails?.map((x) => ({
    label: x.name,
    value: x.name,
  }));

  useScrollTop(null, ".bc-tell-story-wizard", false);

  const isAndroidMobileOrTablet =
    (isMobileDevice() || isTabletDevice()) &&
    /(android)/i.test(navigator.userAgent);

  const _validate = (values) => {
    const errors = {};
    if (!values.tags.length) {
      errors.tags = "This field cannot be empty";
    }
    if (!values.title) {
      errors.title = "This field cannot be empty";
    }
    if (!values.nominated_primo_country) {
      errors.nominated_primo_country = "This field cannot be empty";
    }
    if (!values.text) {
      errors.text = "This field cannot be empty";
    }

    if (!values.nominated_primo_name) {
      errors.nominated_primo_name = "This field cannot be empty";
    }
    if (!values.nominated_primo_email) {
      errors.nominated_primo_email = "This field cannot be empty";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: { ...emptyData },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,

    onSubmit: async (values) => {
      try {
        setUploading(true);
        const _location = await getOrCreateLocation(
          values.nominated_primo_country
        );
        let _values = {
          ...values,
          nomination_year,
          location: _location,
          tags: [...values.tags, ultimateFounderTagId],
        };
        apolloClient
          .mutate(
            storyId
              ? {
                  mutation: UPDATE_STORY,
                  variables: { storyId, data: _values },
                }
              : { mutation: CREATE_STORY, variables: { data: _values } }
          )
          .then((result) => {
            const _newStoryId = result?.data?.createStory?.data?.id;
            onGoNext(_newStoryId);
          });
      } catch (error) {
        //handle error
        console.error(error.message);
      } finally {
        setUploading(false);
      }
    },
    validate: _validate,
  });

  useValidateStep(2);

  let stepTitle;
  let storyDescriptionLabel;

  stepTitle = "NOMINATE A PRIMO";
  storyDescriptionLabel = "Description";

  return (
    <Grid className="wizard-step__wrapper">
      <Row center="xs">
        <Col xs={12}>
          <AnimatedContent>
            <div className="step-title">{stepTitle}</div>
          </AnimatedContent>
        </Col>
        <Col xs={11} sm={10} md={10} lg={10}>
          <AnimatedContent custom={1} delay={0.1}>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <AnimatedContent custom={1} delay={0.1}>
                <p>{STEP_TWO_NOMINATE_DESCRIPTION}</p>
              </AnimatedContent>

              <div className="step-double-col-row">
                <div className="step-element">
                  <SearchInput
                    label="Name of Nominated Primo"
                    error={
                      formik.touched.nominated_primo_name &&
                      formik.errors.nominated_primo_name
                    }
                    onBlur={() =>
                      formik.setFieldTouched(
                        "nominated_primo_name",
                        true,
                        false
                      )
                    }
                    onChange={(user) => {
                      formik.setFieldValue(
                        "nominated_primo_name",
                        user.displayName,
                        false
                      );
                      formik.setFieldValue(
                        "nominated_primo_country",
                        user.country,
                        false
                      );
                      formik.setFieldValue(
                        "nominated_primo_email",
                        user.mail,
                        true
                      );
                    }}
                    value={{
                      displayName: formik.values.nominated_primo_name,
                      country: formik.values.nominated_primo_country,
                      mail: formik.values.nominated_primo_email,
                    }}
                  />
                </div>

                <div className="step-element">
                  <DropdownSelect
                    label="Location"
                    error={
                      formik.touched.nominated_primo_country &&
                      formik.errors.nominated_primo_country
                    }
                    selected={formik.values.nominated_primo_country}
                    onBlur={() => {
                      formik.setFieldTouched("nominated_primo_country", true);
                    }}
                    onChange={(option) => {
                      formik.setFieldValue(
                        "nominated_primo_country",
                        option.value
                      );
                    }}
                    options={locations}
                  />
                </div>
              </div>

              <div className="step-double-col-row">
                <div className="step-element">
                  <Input
                    label="Nomination Story Title"
                    error={
                      formik.touched.title &&
                      !formik.values.title &&
                      formik.errors.title
                    }
                    onBlur={() => {
                      formik.setFieldTouched("title", true);
                    }}
                    onChange={(value) => formik.setFieldValue("title", value)}
                    value={formik.values.title}
                  />
                </div>

                <div className="step-element">
                  <DropdownSelectMultiple
                    // max={isMine ? 3 : 1}
                    max={1}
                    label="Category"
                    error={formik.touched.tags && formik.errors.tags}
                    selected={formik.values.tags}
                    onBlur={() => formik.setFieldTouched("tags", true)}
                    onChange={(option) => formik.setFieldValue("tags", option)}
                    options={categories}
                  />
                </div>
              </div>

              <div className="step-element">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {isAndroidMobileOrTablet ? (
                    <TextArea
                      label={storyDescriptionLabel}
                      error={formik.touched.text && formik.errors.text}
                      defaultValue={formik.values.text}
                      value={formik.values.text}
                      onBlur={() => {
                        formik.setFieldTouched("text", true);
                      }}
                      onChange={(value) => formik.setFieldValue("text", value)}
                    />
                  ) : (
                    <TextEditor
                      label={storyDescriptionLabel}
                      error={formik.touched.text && formik.errors.text}
                      defaultValue={formik.values.text}
                      onBlur={() => {
                        formik.setFieldTouched("text", true);
                      }}
                      onChange={(value) => formik.setFieldValue("text", value)}
                    />
                  )}

                  <p className="step-hint">
                    In 250 words or less, explain why this Primo is an Ultimate
                    Founder.
                  </p>
                </div>
              </div>

              <div className="step-submit">
                <Button
                  disabled={uploading}
                  newDesign={true}
                  type="submit"
                  onClick={formik.handleSubmit}
                  label="Next"
                  size="auto"
                />
              </div>
            </form>
          </AnimatedContent>
        </Col>
      </Row>
    </Grid>
  );
};

WizardStepTwo.propTypes = {
  onGoNext: PropTypes.func.isRequired,
};

export default WizardStepTwo;
