import React from 'react';
import { DateTime } from 'luxon';

import './copyright.scss';

const Copyright = ({theme}) => (
  <div className={theme}>
    <div className='copyright'>
      &copy; {DateTime.local().toFormat('yyyy')} BACARDI
    </div>
  </div>
);

export default Copyright;
