import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import simpleParallax from "simple-parallax-js";
import VideoSlide from "./videoSlide";
import { gql } from "@apollo/client";
import { apolloClient } from "../../../../index";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import "./local-swiper.scss";

const getMainThumbsInfo = async () => {
  const MainThumbInfosQuery = gql`
    query {
      homepage {
        data {
          attributes {
            main_video_thumbnail {
              data {
                attributes {
                  url
                  formats
                }
              }
            }
          }
        }
      }
    }
  `;
  try {
    const dbResult = await apolloClient.query({
      query: MainThumbInfosQuery,
    });
    if (dbResult.error) throw dbResult.error;
    //else
    const format =
      dbResult?.data?.homepage?.data?.attributes?.main_video_thumbnail?.data
        ?.attributes?.formats;
    // dbResult?.data?.committee?.data?.attributes?.members_emails;
    // const _isCommitteeMember = committeeMembers?.includes(userEmail);
    return format;
  } catch (error) {
    console.error(error.message);
    return false; //Do not show wird error to user. May be handled in an other way!
  }
};

const getFamilyMembers = async () => {
  const FamilyInfosQuery = gql`
    query {
      families(sort: "order") {
        data {
          id
          attributes {
            thumbnail_message
            thumbnail {
              data {
                attributes {
                  url
                  formats
                }
              }
            }
          }
        }
      }
    }
  `;
  try {
    const dbResult = await apolloClient.query({
      query: FamilyInfosQuery,
    });
    if (dbResult.error) throw dbResult.error;
    //else
    const members = dbResult?.data?.families?.data?.map((x) => ({
      id: x.id,
      thumbnail_message: x.attributes?.thumbnail_message,
      // thumbnailUrl: `${process.env.REACT_APP_API_BASE_URL}${x.attributes?.thumbnail?.data?.attributes?.url}`,
      thumbnailUrl: `${process.env.REACT_APP_API_BASE_URL}${x.attributes?.thumbnail?.data?.attributes?.formats?.large?.url}`,
    }));
    // dbResult?.data?.committee?.data?.attributes?.members_emails;
    // const _isCommitteeMember = committeeMembers?.includes(userEmail);
    return members;
  } catch (error) {
    console.error(error.message);
    return false; //Do not show wird error to user. May be handled in an other way!
  }
};

export const SecondSection = ({ link }) => {
  const [slidesProps, setSlidesProps] = useState(null);
  const [mainThumbsInfo, setMainThumbsInfo] = useState(null);
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  useEffect(() => {
    getFamilyMembers()
      .then((members) => {
        const _slidesProps = members?.map((x) => ({
          id: x.id,
          imgUrl: x.thumbnailUrl,
          message: x.thumbnail_message,
        }));
        setSlidesProps(_slidesProps);
      })
      .catch((error) => {});

    getMainThumbsInfo()
      .then((format) => {
        setMainThumbsInfo(format);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const image = document.getElementById("video-image");
    // eslint-disable-next-line new-cap,no-new
    const instance = new simpleParallax(image, {
      scale: 1.2,
    });

    return () => {
      instance.destroy();
    };
  }, []);

  // const img1 = "/assets/img/hp/image-video-1.jpg";
  // const img2 = "/assets/img/hp/image-video-2.jpg";
  const slidesData = slidesProps?.map((x, i) => (
    <VideoSlide
      imgUrl={x.imgUrl}
      message={x.message}
      onClick={() => navigate(`/bacardi-founder/${x.id}`)}
    />
  ));

  //make the slide turning every 3seconds

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideNext();
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const mainImgUrl = mainThumbsInfo
    ? `${process.env.REACT_APP_API_BASE_URL}${mainThumbsInfo?.large?.url}`
    : null;

  return !mainImgUrl ? null : (
    <>
      <div className="sliders-wrapper">
        <VideoSlide imgUrl={mainImgUrl} videoUrl={link} />
        {slidesData?.length > 0 && (
          <div>
            <Swiper
              ref={swiperRef}
              pagination={{
                type: "bullets",
                clickable: true,
              }}
              navigation={true}
              // autoplay={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {slidesData.map((slide, index) => (
                <SwiperSlide key={index}>{slide}</SwiperSlide>
              ))}
              {/* <SwiperSlide key={30}>
                <VideoSlide
                  imgUrl="/assets/img/family/BCI-14180-Founders-Website---Video-Headers-6.jpg"
                  onClick={() => navigate(`/bacardi-founder/now`)}
                />
              </SwiperSlide> */}
            </Swiper>
          </div>
        )}
      </div>
    </>
  );
};

SecondSection.propTypes = {
  link: PropTypes.string.isRequired,
};
