import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { apolloClient } from "../../../index";

import clsx from "clsx";
import { isMobileDevice } from "responsive-react";

import { useNavigate } from "react-router-dom";
import { gql, useApolloClient } from "@apollo/client";
import Loader from "../../../components/loader";
import { maxThumbsNbr } from "../../../serverConfig";
import Button from "../../../components/button";
import styles from "../best-stories-winners/best-stories-winners.module.scss";
// Components
import Navbar from "../../../components/navbar";
import Support from "../../../components/support";
import Copyright from "../../../components/copyright/copyright";
import Footer from "../../../components/footer";
import BestStoriesTabs from "../best-stories-tabs";
// import ThumbnailsList from '../best-stories-content';
import Thumbnail from "../best-story-item";
import NoStoriesInfo from "../../../components/no-stories-info";

// Styles
import "./best-stories.scss";

import {
  BEST_STORIES_DESCRIPTION,
  ULTIMATE_FOUNDERS_SHORTLIST,
} from "../../../constants/common";
// import sortStringBy from '../../../helpers/common';
import { useIntersect } from "../../../helpers/hooks";
import { AnimatedContent } from "../../../components/animated-content";

const MAX_STORY_NUMBER = 9999999;
const QUERY_STORIES_TAGS = gql`
query GetStoriesTags($filter:StoryFiltersInput!) {
  stories(
    pagination: {limit:${MAX_STORY_NUMBER}}
    filters:$filter
    ) {
    data {
      id
      attributes {
        tags {data {id attributes {name}}}
      }
    }
  }
}
`;

const GET_ULTIMATE_TAGS = gql`
  query getTags {
    preference {
      data {
        attributes {
          ultimate_tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          ultimate_founder_tag {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

const NEW_STORIES_QUERY = gql`
query GetAllStories($filter:StoryFiltersInput!, $page: Int = 1, $pageSize: Int = ${maxThumbsNbr}) {
  stories(pagination: { page: $page, pageSize: $pageSize } 
    filters:$filter     
    sort: "nomination_year:desc") {
    data {
      id
      attributes {
        nomination_year
        author_name
        nominated_primo_name
        cover_image{data{attributes{url}}}
        title
        text
        likes_number
        location {data {id attributes{name}}}
        tags {data {id attributes {name}}}
      }
    }
    meta {
      pagination {
        total
        pageCount
        page
        pageSize
      }
    }
  }
}
`;

const StoriesList = ({ activeTag, nominationYear }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stories, setStories] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  // const [searchParams, setSearchParams] = useSearchParams();
  // const page = +searchParams.get("page");

  const handleItemClick = useCallback((itemId) => {
    if (itemId) {
      navigate(`/stories/${itemId}`);
    }
  }, []);

  const getNext = async (page, callback) => {
    try {
      const { error, data } = await apolloClient.query({
        query: NEW_STORIES_QUERY,
        variables: {
          page: page,
          pageSize: maxThumbsNbr ?? 12,
          filter: {
            and: {
              tags: { id: { eq: activeTag } },
              nomination_year: { eq: nominationYear },
            },
          },
        },
      });

      if (error) throw error;

      //else
      let stories = data?.stories?.data;
      stories = stories
        ? stories.map((x) => {
            return {
              id: x.id,
              nominated_primo_name: x.attributes?.nominated_primo_name, //TODO
              cover_image: x.attributes?.cover_image?.data?.attributes?.url,
              title: x.attributes?.title,
              text: x.attributes?.text,
              location: x.attributes?.location?.data?.attributes?.name,
              likes: x.attributes?.likes_number,
              liked: false, //TODO
              tags: x.attributes?.tags?.data?.map((t) => t.attributes?.name),
            };
          })
        : [];

      //pagination
      const pagination = data?.stories?.meta.pagination;

      //check if more pages to load exists
      const nextPage =
        pagination.page < pagination.pageCount ? pagination.page + 1 : null;

      callback(stories, nextPage);
    } catch (error) {
      throw error;
    }
  };
  const fetchNextPage = () => {
    if (nextPage === null) return; //no page to load
    //else
    setLoading(true);
    try {
      getNext(nextPage, (pgStories, nextPg) => {
        if (stories?.length) {
          setStories((prev) => [...prev, ...pgStories]);
        } else {
          setStories(pgStories); //First page
        }
        setNextPage(nextPg);
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchFirstPage = () => {
      setNextPage(1);
      setLoading(true);
      try {
        getNext(1, (pgStories, nextPg) => {
          setStories(pgStories); //First page
          setNextPage(nextPg);
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchFirstPage(); //loade page 0
  }, [activeTag]);

  if (!stories || loading) {
    return <div style={{ margin: "20px 0", color: "#fff" }}>Loading...</div>; //<Loader show />;
  }
  if (error) {
    //TODO LOG
    // navigate("/error");
    return <div>{error}</div>;
  }

  return (
    <Row center="xs">
      <Col xs={12}>
        {stories?.length ? (
          <div className="best-stories__list">
            {stories.map((item) => (
              <Thumbnail
                key={item.id}
                data={item}
                onClick={() => handleItemClick(item.id)}
                // accent={accent}
                // simplified={simplified}
                showTags
              />
            ))}
          </div>
        ) : (
          <NoStoriesInfo
            title={null}
            message={
              <p>Unfortunately, there are no stories from this category</p>
            }
          />
        )}
      </Col>

      {nextPage !== null && (
        <Col xs={8} md={5} lg={3}>
          {nextPage !== null && (
            <Button
              classNames={styles.moreContentButton}
              onClick={fetchNextPage}
            >
              Load more
            </Button>
          )}
        </Col>
      )}
    </Row>
  );
};
const GetUltimateTags = async () => {
  try {
    const result = await apolloClient.query({ query: GET_ULTIMATE_TAGS });
    const _tags =
      result?.data?.preference?.data?.attributes?.ultimate_tags?.data?.map(
        (x) => ({ value: x.id, label: x.attributes?.name })
      );
    return _tags;
  } catch (error) {
    //handle error
    console.error(error.message);
    return null;
  }
};

export const BestStories = ({ globalSettings }) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const client = useApolloClient();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState(null);
  const [activeTag, setActiveTag] = useState(null);
  // const [activeTag, setActiveTag] = useLocalStorage(
  //   'bc-best-stories-active-tab',
  //   null
  // );

  // const tagFromUrl = searchParams.get("activeTag");

  // const tabs = useSelector(getMomentsOptionsForTabs);
  // const tabs = [{ id: 0, name: "aaa" }, { id: 0, name: "bbb" }, { id: 0, name: "ccc" }, { id: 0, name: "ddd" }]; //TODO

  // React.useState(tabs[0]?.id);
  // const stories = useSelector(getUltimateStories);
  // const isShowUltimateFounders = useSelector(
  //   state => state.common.showUltimateStories
  // );
  // if (!isShowUltimateFounders) {
  //   return <Redirect to='/locations' />;
  // }

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    GetUltimateTags()
      .then((tags) => {
        if (tags?.length) {
          const _tags = tags.map((x) => ({ id: x.value, name: x.label }));
          setTags(_tags);
          setActiveTag(_tags[0].id);
        }
      })
      .catch((error) => {
        console.error(error.message); // handle error
      })
      .finally(() => setLoading(false));
  }, []);

  const [observerEntry, entryRef] = useIntersect({
    threshold: "0.9, 1",
    rootMargin: "-20px 0px 0px 0px",
  });

  ////

  if (!globalSettings?.nomination_year || loading) {
    return <Loader show />;
  }
  if (error || !tags) {
    //TODO LOG
    // navigate("/error");
    return <div>{error}</div>;
  }

  return (
    <div className="best-stories__background">
      <div
        className={clsx("best-stories__container", {
          mobile: isMobileDevice(),
        })}
      >
        <Navbar />
        {/* <BestStoriesNavbar
          minimized={
            observerEntry.isIntersecting !== undefined &&
            !observerEntry.isIntersecting
          }
        /> */}

        <Grid>
          <Row center="xs">
            <AnimatedContent>
              <div className="title" ref={entryRef}>
                {ULTIMATE_FOUNDERS_SHORTLIST}
              </div>
            </AnimatedContent>
          </Row>
          <Row center="xs">
            <Col lg={10} md={10} xs={12}>
              <AnimatedContent custom={1} delay={0.1}>
                <div className="description">
                  <p>{BEST_STORIES_DESCRIPTION}</p>
                </div>
              </AnimatedContent>
            </Col>
          </Row>
          <AnimatedContent
            custom={2}
            delay={0.1}
            threshold={0.1}
            rootMargin={0}
          >
            {tags?.length && (
              <BestStoriesTabs
                activeTab={activeTag}
                tabs={tags}
                setActiveTab={setActiveTag}
              />
            )}
            <StoriesList
              activeTag={activeTag}
              nominationYear={globalSettings?.nomination_year}
            />
          </AnimatedContent>
        </Grid>
      </div>
      <Footer>
        <Support />
        <Copyright />
      </Footer>
    </div>
  );
};
