import React from "react";
import PropTypes from "prop-types";
import { isTabletDevice, isMobileDevice } from "responsive-react";

import ExploreStoryHeaderOriginal from "./explore-story-header-original";
import ExploreStoryHeaderMobile from "./explore-story-header-mobile";
import ExploreStoryHeaderTablet from "./explore-story-header-tablet";

const ExploreStoryHeader = ({
  theme,
  minimized,
  coverImage,
  title,
  period,
}) => {
  const isIpad = navigator.platform.match(/iPad/i);

  if (isMobileDevice()) {
    return (
      <ExploreStoryHeaderMobile
        theme={theme}
        minimized={minimized}
        coverImage={coverImage}
        title={title}
        period={period}
      />
    );
  }

  return (
    <>
      {isTabletDevice() || isIpad ? (
        <ExploreStoryHeaderTablet
          theme={theme}
          minimized={minimized}
          coverImage={coverImage}
          title={title}
          period={period}
        />
      ) : (
        <ExploreStoryHeaderOriginal
          theme={theme}
          minimized={minimized}
          coverImage={coverImage}
          title={title}
          period={period}
        />
      )}
    </>
  );
};

ExploreStoryHeader.defaultProps = {
  minimized: false,
  rank: 0,
  nominatedAuthor: null,
  theme: "mint",
};

ExploreStoryHeader.propTypes = {
  coverImage: PropTypes.string.isRequired,
  coverImageThumb: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  minimized: PropTypes.bool,
  rank: PropTypes.number,
  nominatedAuthor: PropTypes.string,
  theme: PropTypes.string,
};

export default ExploreStoryHeader;
