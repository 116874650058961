import { loginRequest, graphEndpoints } from "./authConfig";
import { msalInstance } from "../index";

export function cleanToken() {
    // To remove the access token from SessionStorage
    sessionStorage.removeItem('ua-token');
}

async function getAccessToken() {
    // To retrieve the access token from SessionStorage
    let accessToken = sessionStorage.getItem('ua-token');

    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;

        // To save an access token to SessionStorage
        sessionStorage.setItem('ua-token', accessToken);
    }
    return accessToken;
}

export async function getHeaders() {
    const accessToken = await getAccessToken();

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    //   headers: {
    //     'Authorization': `Bearer ${accessToken}`,
    //     'Content-Type': 'application/json'
    //   }

    return headers;
}

export async function aboutMe() {
    const headers = await getHeaders();
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphEndpoints.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function endPointResult(endpoint) {
    const headers = await getHeaders();
    const options = {
        method: "GET",
        headers
    };
    return fetch(endpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getUsersStartingWith(prefix, limit) {
    const headers = await getHeaders();
    const topLimit = limit; //Can put it in config file

    const filters = [
        `startswith(displayName,'${prefix}')`,
        `startswith(mail,'${prefix}')`
    ];

    const _filter = filters.join(' or ');
    const _top = topLimit;
    const _select = 'displayName,mail,id,country,countryCode,Country,region';
    const url = `https://graph.microsoft.com/v1.0/users?$filter=${_filter}&$top=${_top}&$select=${_select}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const data = await response.json();
        return data;

    } catch (error) {
        //Handling errors
        console.error('Erreur:', error);
    }

}
