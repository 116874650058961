import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
// Components
import NavbarLogo from "./navbar-logo";
import NavbarLinks from "./navbar-links";

import { useTransparent } from "../../helpers/hooks";

// Styles
import "./navbar.scss";

const NavbarOriginal = ({
  sitePhase,
  newsletterSent,
  downloadLink,
  likedStoriesLength,
  light,
}) => {
  const { transparent } = useTransparent();
  const navigate = useNavigate();
  return (
    <nav
      className={clsx("bc-navbar", {
        transparent,
        light: transparent && light,
      })}
    >
      <div className="bc-navbar__content-wrapper">
        <NavbarLogo />
        <NavbarLinks
          sitePhase={sitePhase}
          newsletterSent={newsletterSent}
          downloadLink={downloadLink}
          likedStoriesLength={likedStoriesLength}
        />
      </div>
    </nav>
  );
};

NavbarOriginal.defaultProps = {
  expandedVisible: false,
};

NavbarOriginal.propTypes = {
  expandedVisible: PropTypes.bool,
};

export default React.memo(NavbarOriginal);
