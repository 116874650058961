import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import clsx from "clsx";
// import Lightbox from 'react-image-lightbox';
import ResizeObserver from "resize-observer-polyfill";
import { useMediaQuery } from "react-responsive";

// Components
import { Icons } from "../icon";
import ImageSlideComponent from "./image-slide";
import { VideoPlayer } from "../video-player";

import "react-responsive-carousel/lib/styles/carousel.min.css";
// import 'react-image-lightbox/style.css';
import "./story-carousel.scss";

export function handleEntries(entries, mounted, setSlideHeight, dimensionsRef) {
  let { w, h } = dimensionsRef.current;
  for (const entry of entries) {
    if (entry.target === document.body) {
      h = entry?.contentRect?.height ?? 0;
    }
    if (entry.target !== document.body) {
      w = entry?.contentRect?.width ?? 0;
    }
  }
  if (h && w) {
    const space = h > w ? w : h;
    const availableArea = space - 140;

    if (availableArea > 0 && mounted) {
      // eslint-disable-next-line no-param-reassign
      dimensionsRef.current = { w, h };
      setSlideHeight(availableArea);
    }
  }
}

const StoryCarousel = ({ photos, videos, theme }) => {
  const isMobileDevice = useMediaQuery({
    maxWidth: "767px",
  });

  const wrapper = useRef();
  const dimensionsRef = useRef({ h: 0, w: 0 });
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [slideHeight, setSlideHeight] = useState(isMobileDevice ? 330 : 600);

  const next = useCallback(() => {
    setCurrentSlide(currentSlide + 1);
  }, [currentSlide]);

  const prev = useCallback(() => {
    setCurrentSlide(currentSlide - 1);
  }, [currentSlide]);

  const updateCurrentSlide = useCallback(
    (index) => {
      if (currentSlide !== index) {
        setCurrentSlide(index);
      }
    },
    [currentSlide]
  );

  const handleKeyPress = React.useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        prev();
      } else if (e.key === "ArrowRight") {
        next();
      }
    },
    [prev, next]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleKeyPress]);

  //TODO : carousel - Problem with this code !!
  // const customRenderItem = (item, props) => (
  //   <item.type {...item.props} {...props} />
  // );

  useLayoutEffect(() => {
    let mounted = true;
    const ro = new ResizeObserver((entries) => {
      handleEntries(entries, mounted, setSlideHeight, dimensionsRef);
    });

    ro.observe(wrapper.current);
    ro.observe(document.body);

    return () => {
      mounted = false;
      ro.disconnect();
    };
  }, [wrapper, isMobileDevice]);

  if (!photos && !videos) {
    return null;
  }

  let numberOfMedia = 0;
  if (photos) numberOfMedia += photos.length;
  if (videos) numberOfMedia += videos.length;

  return (
    <div className={clsx("bc-carousel__wrapper", theme)} ref={wrapper}>
      <Carousel
        // renderIndicator={customRenderIndicator}
        //renderItem={customRenderItem} //TODO : carousel - Problem with this code !!
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        // dynamicHeight
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
      >
        {photos &&
          photos.map((item, i) => (
            <ImageSlideComponent
              height={slideHeight}
              key={item.src}
              src={item.src}
              visible={currentSlide === i}
              thumbnail={item.thumbnail_src}
            />
          ))}

        {videos &&
          videos.map((item, i) => (
            <VideoPlayer
              key={item.src}
              url={item.src}
              isSelected={currentSlide === photos.length + i}
            />
          ))}
      </Carousel>
      <div
        className={clsx("bc-carousel__controls", {
          hidden: numberOfMedia < 2,
        })}
      >
        <span onClick={prev} className={clsx({ disabled: currentSlide === 0 })}>
          {Icons.TIMELINE_ARROW}
        </span>
        <span className="bold" style={{ width: "50px" }}>
          {currentSlide + 1}/{numberOfMedia}
        </span>
        <span
          onClick={next}
          className={clsx({
            disabled: currentSlide === numberOfMedia - 1,
          })}
        >
          {Icons.TIMELINE_ARROW}
        </span>
      </div>
    </div>
  );
};

StoryCarousel.defaultProps = {
  photos: null,
  videos: null,
};

StoryCarousel.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      thumbnail_src: PropTypes.string,
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
    })
  ),
  theme: PropTypes.string.isRequired,
};

export { StoryCarousel };
export default React.memo(StoryCarousel);
