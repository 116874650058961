import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  ULTIMATE_FOUNDER_WINNERS,
  PAST_WINNERS,
  VOTE,
  NOMINATE,
  ASSETS,
  GLOBAL_ACTIVATION,
  PURPOSE,
  DOWNLOAD,
  ALL_STORIES,
  LIKED_STORIES_NAV,
  COMMITTEE_STORIES_NAV,
} from "../../constants/common";
import { AppSettingsContext } from "../../App";

import styles from "./mobile-navigation-dropdown.module.scss";

export const MobileNavigationDropdown = ({
  sitePhase,
  newsletterSent,
  downloadLink,
  likedStoriesLength,
}) => {
  const { showCommitteeStories } = useContext(AppSettingsContext);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const scrollToGlobalActivationBoxes = () => {
    const hereParagraph = document.getElementById("global_activation_boxes");
    if (hereParagraph) {
      hereParagraph.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className={styles.root} data-testid="MobileNavigationDropdown">
      {/* NOMITATION OR VOTING BUTTON */}
      {(() => {
        switch (sitePhase) {
          case "Nomination":
            return (
              <NavLink exact className={styles.item} to="/share-your-story">
                <span>{NOMINATE}</span>
              </NavLink>
            );
          case "Voting":
            return (
              <NavLink
                exact
                className={styles.item}
                to="/vote-for-ultimate-founders"
              >
                <span>{VOTE}</span>
              </NavLink>
            );
          default:
            return null;
        }
      })()}

      {/* ULTIMATE_FOUNDER_WINNERS OR VOTING BUTTON */}
      {(() => {
        switch (sitePhase) {
          case "Selection":
            return (
              <NavLink
                exact
                className={styles.item}
                to="/ultimate-founders-winners"
              >
                <span>{ULTIMATE_FOUNDER_WINNERS}</span>
              </NavLink>
            );
          default:
            return (
              <NavLink exact className={styles.item} to="/past-winners">
                <span>{PAST_WINNERS}</span>
              </NavLink>
            );
        }
      })()}

      {sitePhase !== "Selection" && newsletterSent && (
        <NavLink
          exact
          className={styles.item}
          to="https://bacardi.sharepoint.com/sites/FoundersDay2024ToolKits"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{ASSETS}</span>
        </NavLink>
      )}

      {/* <NavLink exact className={styles.item} to="/stories">
        <span>{ALL_STORIES}</span>
      </NavLink> */}
      {/* {!!likedStoriesLength && <NavLink exact className={styles.item} to="/liked-stories">
        <span>{LIKED_STORIES_NAV}</span>
      </NavLink>} */}
      {showCommitteeStories && (
        <NavLink exact className={styles.item} to="/committee-stories">
          <span>{COMMITTEE_STORIES_NAV}</span>
        </NavLink>
      )}
      {/* <NavLink exact className={styles.item} to="/purpose">
        <span>{PURPOSE}</span>
      </NavLink> */}
      {/* <NavLink exact className={styles.item} to={downloadLink} download>
        <span>{DOWNLOAD}</span>
      </NavLink> */}
    </div>
  );
};

export default MobileNavigationDropdown;
