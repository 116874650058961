import React, { useEffect, useRef, useContext } from "react";
import { useQuery, gql } from "@apollo/client";
import clsx from "clsx";
import { AppSettingsContext } from "../../App";

// import Navbar from "../../components/navbar";
import Loader from "../../components/loader";
import Navbar from "../../components/navbar";
import { FirstSection } from "./components/first-section";
import { SecondSection } from "./components/second-section";
import { ThirdSection } from "./components/third-section";
// import { FourthSection } from "./components/fourth-section";
// import { FifthSection } from "./components/fifth-section";
// import { MomentsSection } from "./components/moments-section";
import { FooterSection } from "./components/footer-section";
import styles from "./homepage.module.scss";

const HPAGE_QUERY = gql`
  query GetHomePage {
    homepage {
      data {
        attributes {
          main_screen_text
          main_video_link
          compiling_text
          nomination_text
          votes_text
          choosing_text
          winners_text
          purpose_text
          sharepoint_text
          download_link
          legacy_radio {
            data {
              attributes {
                url
              }
            }
          }
          slides
        }
      }
    }
  }
`;

const Homepage = () => {
  const rootRef = useRef();
  const { loading, error, data } = useQuery(HPAGE_QUERY);
  const { site_phase } = useContext(AppSettingsContext);

  useEffect(() => {
    function handleFullScreenPlayback() {
      const isFullscreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitCurrentFullScreenElement;

      if (isFullscreen) {
        rootRef.current.style.display = "none";
      } else {
        rootRef.current.style.display = "flex";
      }
    }
    const fullScreeanEventTypes = [
      "fullscreenchange",
      "webkitfullscreenchange",
      "mozfullscreenchange",
      "msfullscreenchange",
    ];
    fullScreeanEventTypes.forEach((eventType) =>
      document.addEventListener(eventType, handleFullScreenPlayback)
    );
    return () => {
      fullScreeanEventTypes.forEach((eventType) =>
        document.removeEventListener(eventType, handleFullScreenPlayback)
      );
    };
  }, [rootRef]);

  if (loading) {
    return <Loader show />;
  }
  if (error || !data) {
    //TODO LOG
    // navigate("/error");
    return <div>{error}</div>;
  }
  const _home = data?.homepage?.data?.attributes;
  const _legacy_radio = `${process.env.REACT_APP_API_BASE_URL}${_home.legacy_radio?.data?.attributes?.url}`;
  return (
    <div
      id="home-page"
      className={styles.root}
      data-testid="homepage-root"
      ref={rootRef}
    >
      <Navbar />
      <section className={clsx(styles.section, styles.colorful)}>
        <FirstSection text={_home.main_screen_text} />
      </section>
      {/* <section className={clsx(styles.section, styles.colorful)}>
        <MomentsSection slides={_home.slides} />
      </section> */}
      {site_phase === "Selection" && (
        <section className={clsx(styles.section)}>
          <SecondSection link={_home.main_video_link} />
        </section>
      )}
      <section className={clsx(styles.section, styles.colorful)}>
        <ThirdSection
          nominationText={_home.nomination_text}
          compilingText={_home.compiling_text}
          votesText={_home.votes_text}
          choosingText={_home.choosing_text}
          winnersText={_home.winners_text}
          legacyRadioUrl={_legacy_radio}
        />
      </section>
      {/* <section className={clsx(styles.section)}>
        <FourthSection purposeText={_home.purpose_text} />
      </section> */}
      {/* <section className={clsx(styles.section, styles.colorful)}>
        <FifthSection link={_home.download_link} text={_home.sharepoint_text} />
      </section> */}
      <section className={clsx(styles.section, styles.colorful)}>
        <FooterSection />
      </section>
    </div>
  );
};

export default Homepage;
