import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import ExploreStoryHeaderContent from "./explore-story-header-content";

import "./explore-story-header-mobile.scss";

const ExploreStoryHeaderMobile = ({
  theme,
  minimized,
  coverImage,
  title,
  period,
}) => (
  <div
    className={clsx("bc-explore-story__header-background-mobile", {
      minimized,
    })}
  >
    <ExploreStoryHeaderContent
      theme={theme}
      coverImage={coverImage}
      title={title}
      period={period}
    />
  </div>
);

ExploreStoryHeaderMobile.defaultProps = {
  minimized: false,
  rank: 0,
  nominatedAuthor: null,
  theme: "mint",
};

ExploreStoryHeaderMobile.propTypes = {
  coverImage: PropTypes.string.isRequired,
  coverImageThumb: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  minimized: PropTypes.bool,
  rank: PropTypes.number,
  nominatedAuthor: PropTypes.string,
  theme: PropTypes.string,
};

export default React.memo(ExploreStoryHeaderMobile);
