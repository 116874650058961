import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export function useValidateStep(step, data = {}) {
  const navigate = useNavigate();
  // const data = {}; //useSelector(getWizardData);
  useEffect(() => {
    const stepOneFields = ['primoName', 'email', 'country'];
    const stepTwoFields = ['category', 'title', 'location', 'description'];
    const stepThreeFields = ['coverImage', 'attachment'];
    let fields = [];
    switch (step) {
      case '1-5':
      case 2: {
        fields = [...fields, ...stepOneFields];
        break;
      }
      case 3: {
        fields = [...fields, ...stepOneFields, ...stepTwoFields];
        break;
      }
      case 4:
      default: {
        fields = [...fields, ...stepOneFields, ...stepTwoFields, ...stepThreeFields];
        break;
      }
    }

    let valid = true;
    fields.forEach(key => {
      const value = data[key];
      if (!value || (key === 'attachment' && value.length === 0)) {
        valid = false;
      }
    });

    if (!valid) {
      navigate('/share-your-story');
    }
    // eslint-disable-next-line
  }, []);
}
