import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
// Components
import ExploreFamilyStoryRenderer from "./explore-family-story-renderer";

import Loader from "../../components/loader";
import Copyright from "../../components/copyright/copyright";
import Support from "../../components/support";
import Footer from "../../components/footer";
import { useScrollTop } from "../../helpers/hooks";

// Styles
import "./explore-story.scss";

const FOUNDER_STORY_QUERY = gql`
  query GetStory($id: ID!) {
    family(id: $id) {
      data {
        id
        attributes {
          title
          cover_image {
            data {
              attributes {
                url
              }
            }
          }
          video_link
          attached_files {
            data {
              attributes {
                url
                mime
              }
            }
          }
          text
          period
          thumbnail {
            data {
              attributes {
                url
              }
            }
          }
          thumbnail_message
        }
      }
    }
  }
`;

const ExploreFamilyStory = () => {
  const navigate = useNavigate();
  const { storyId } = useParams();
  const { loading, error, data } = useQuery(FOUNDER_STORY_QUERY, {
    variables: { id: storyId },
  });

  useScrollTop(null, ".bc-explore-story__container", false);

  if (loading) {
    return <Loader show />;
  }
  if (error || !data?.family) {
    //TODO LOG
    // navigate("/error");
    return <div>{error}</div>;
  }

  const convert2founderStory = (id, attributes) => {
    if (!attributes) return null;

    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    const _photos = attributes.attached_files?.data
      ?.filter((x) => x.attributes?.mime.startsWith("image"))
      ?.map((x) => ({
        src: `${apiBaseURL}${x.attributes?.url}`,
        thumbnail_src: `${apiBaseURL}${x.attributes?.url}`,
      }));

    //else
    return {
      id: id,
      period: attributes?.period,
      title: attributes?.title,
      text: attributes.text,
      cover_image: `${apiBaseURL}${attributes?.cover_image?.data?.attributes?.url}`,
      video_link: attributes?.video_link,
      thumbnail_message: attributes?.thumbnail_message,
      thumbnailUrl: `${apiBaseURL}${attributes?.thumbnail?.data?.attributes?.url}`,
      photos: _photos,
    };
  };

  if (!data?.family?.data) {
    navigate("/not-found-page");
  }

  const story = convert2founderStory(
    data?.family?.data?.id,
    data?.family?.data?.attributes
  );
  return (
    <>
      <ExploreFamilyStoryRenderer story={story} />
      <Footer theme="dark">
        <Support />
        <Copyright />
      </Footer>
    </>
  );
};

export default ExploreFamilyStory;
