import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import ExploreStoryHeaderContent from "./explore-story-header-content";

import "./explore-story-header-mobile.scss";

const ExploreStoryHeaderMobile = ({
  coverImage,
  coverImageThumb,
  location,
  title,
  rank,
  author,
  date,
  minimized,
  nominatedAuthor,
  theme,
}) => (
  <div
    className={clsx("bc-explore-story__header-background-mobile", {
      minimized,
    })}
  >
    <ExploreStoryHeaderContent
      theme={theme}
      location={location}
      author={author}
      rank={rank}
      coverImage={coverImage}
      title={title}
      coverImageThumb={coverImageThumb}
      nominatedAuthor={nominatedAuthor}
      date={date}
    />
  </div>
);

ExploreStoryHeaderMobile.defaultProps = {
  minimized: false,
  rank: 0,
  nominatedAuthor: null,
  theme: "mint",
};

ExploreStoryHeaderMobile.propTypes = {
  coverImage: PropTypes.string,
  coverImageThumb: PropTypes.string,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  minimized: PropTypes.bool,
  rank: PropTypes.number,
  nominatedAuthor: PropTypes.string,
  theme: PropTypes.string,
};

export default React.memo(ExploreStoryHeaderMobile);
