import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { Icons } from '../icon';
import DropdownSelectItem from './dropdown-select-item';
import { useEventListener, useOnClickOutside } from '../../helpers/hooks';

const DropdownSelectMultiple = ({
  label,
  options,
  onChange,
  selected,
  disabled,
  editable,
  placeholder,
  error,
  max
}) => {
  const optionsRef = React.useRef();
  const inputRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(
    options.filter(item => selected.includes(item.value))
  );
  const [focused, setFocused] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const handleClose = React.useCallback(() => {
    setFocused(false);
    setInputValue('');
    setOpen(false);
  }, [setOpen]);

  const handleKeyUpPress = React.useCallback(
    e => {
      if (
        (e.key === 'Enter' || e.key === 'Space') &&
        optionsRef.current === document.activeElement
      ) {
        setOpen(true);
      }

      if (e.key === 'Escape') {
        setOpen(false);
        setFocused(false);
      }
    },
    [optionsRef]
  );

  useOnClickOutside(optionsRef, handleClose);

  useEventListener('keyup', handleKeyUpPress);

  React.useEffect(() => {
    if (focused) {
      inputRef.current.focus();
    }
  }, [focused]);

  return (
    <div className='bc-dropdown-select'>
      <div
        className={classNames('bc-dropdown-select__container', {
          disabled,
          editable,
          hasSelected: !!selectedOption.length,
          focused,
          error: !!error,
          open
        })}
        ref={optionsRef}
      >
        <div
          /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
          tabIndex={0}
          onFocus={() => {
            setFocused(true);
            setOpen(true);
          }}
          className={classNames('bc-dropdown-select__control', { open })}
          onClick={() => {
            setOpen(true);
            setFocused(true);
          }}
        >
          {focused ? (
            <input
              className='bc-dropdown-select__input'
              ref={inputRef}
              onFocus={() => setFocused(true)}
              value={inputValue}
              onClick={e => e.stopPropagation()}
              onChange={e => setInputValue(e.target.value)}
              type='text'
            />
          ) : (
            <div>
              <div>
                {selectedOption.length
                  ? selectedOption.map(item => item.label).join(', ')
                  : placeholder}
              </div>
            </div>
          )}
          {label && <div className='bc-dropdown-select__label'>{label}</div>}
          <div className={classNames('control-toggle', { open })}>
            {Icons.CHEVRON_DOWN}
          </div>
        </div>
        <div className={classNames('bc-dropdown-select__options', { open })}>
          {options
            .filter(option => {
              if (inputValue.trim() !== '') {
                return option.label
                  .toLowerCase()
                  .startsWith(inputValue.toLowerCase());
              }
              return true;
            })
            .sort((a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0;
            })
            .map(option => (
              <DropdownSelectItem
                key={option.value}
                label={option.label}
                value={option.value}
                selected={selectedOption && selectedOption.includes(option)}
                onClick={() => {
                  if (selected.includes(option.value)) {
                    setInputValue('');
                    setSelectedOption(
                      selectedOption.filter(item => item.value !== option.value)
                    );
                    onChange(selected.filter(item => item !== option.value));
                  } else {
                    setInputValue('');
                    setSelectedOption(
                      [...selectedOption, option]
                        .reverse()
                        .slice(0, max)
                        .reverse()
                    );
                    onChange(
                      [...selected, option.value]
                        .reverse()
                        .slice(0, max)
                        .reverse()
                    );
                  }
                  if (max === 1) {
                    handleClose(false);
                  }
                }}
              />
            ))}
        </div>
      </div>
      {!!error && (
        <div className='bc__status'>
          <div>{Icons.ERROR}</div>
          <div>{error}</div>
        </div>
      )}
    </div>
  );
};

DropdownSelectMultiple.defaultProps = {
  selected: [],
  disabled: false,
  placeholder: '',
  label: '',
  error: null,
  max: 3,
  editable: true
};

// DropdownSelectMultiple.propTypes = {
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
//     })
//   ).isRequired,
//   onChange: PropTypes.func.isRequired,
//   selected: PropTypes.arrayOf(PropTypes.number),
//   disabled: PropTypes.bool,
//   editable: PropTypes.bool,
//   placeholder: PropTypes.string,
//   label: PropTypes.string,
//   error: PropTypes.string,
//   max: PropTypes.number
// };

export default DropdownSelectMultiple;
