import React from "react";
import "./errorPage.scss"; // Import the CSS file for styling

const UnknownErrorPage = () => {
  const handleContactWebmaster = () => {
    // Replace with your logic to handle contacting the webmaster
    alert("Please contact the webmaster for assistance.");
  };

  return (
    <div className="unknown-error-container">
      <h1 className="unknown-error-heading">Oops!</h1>
      <p className="unknown-error-text">Something unexpected happened.</p>
      <p className="unknown-error-message">
        We apologize for the inconvenience. Please try again later or contact
        the webmaster for assistance.
      </p>
      {/* <button className="unknown-error-button" onClick={handleContactWebmaster}>
        Contact Webmaster
      </button> */}
      <a href="/" className="unknown-error-button">
        Go back to homepage
      </a>
    </div>
  );
};

export default UnknownErrorPage;
