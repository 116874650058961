import React from "react";
import PropTypes from "prop-types";

import ExploreStoryHeaderContent from "./explore-story-header-content";

const ExploreStoryHeaderOriginal = ({ theme, coverImage, title, period }) => (
  <div className="bc-explore-family__header-background">
    <ExploreStoryHeaderContent
      theme={theme}
      coverImage={coverImage}
      title={title}
      period={period}
    />
  </div>
);

ExploreStoryHeaderOriginal.defaultProps = {
  rank: 0,
  nominatedAuthor: null,
  theme: "mint",
};

ExploreStoryHeaderOriginal.propTypes = {
  coverImage: PropTypes.string.isRequired,
  coverImageThumb: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rank: PropTypes.number,
  nominatedAuthor: PropTypes.string,
  theme: PropTypes.string,
};

export default ExploreStoryHeaderOriginal;
