import { useEffect, useLayoutEffect, useState, useRef } from "react";

import { getLocalStorageItem, setLocalStorageItem } from "./localStorage";

export function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      // eslint-disable-next-line consistent-return
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

export function useOnClickOutside(ref, handler, allowedRef, allowedClass) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      if (allowedRef) {
        if (!allowedRef.current || allowedRef.current.contains(event.target)) {
          return;
        }
      }
      if (allowedClass) {
        if (event.target.closest(`.${allowedClass}`)) {
          return;
        }
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, allowedRef, allowedClass]);
}

export function useTransparent() {
  const [transparent, setTransparent] = useState(true);

  useEffect(() => {
    function handleScrollTop() {
      if (window.scrollY > 0 && transparent) {
        setTransparent(false);
      } else if (window.scrollY === 0 && !transparent) {
        setTransparent(true);
      }
    }
    window.addEventListener("scroll", handleScrollTop, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, [transparent]);

  return { transparent };
}

/** Local storage hook */
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(
    getLocalStorageItem(key, initialValue)
  );

  /**
   * Return a wrapped version of useState's setter
   * function that persists the new value to localStorage.
   */
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      setLocalStorageItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

/**
 * Custom useIntersect hook
 * @param root
 * @param rootMargin
 * @param threshold
 */
export const useIntersect = ({ root = null, rootMargin, threshold }) => {
  const [observerEntry, setEntry] = useState({});
  const elRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => setEntry(entries[0]),
      {
        root,
        rootMargin,
        threshold:
          threshold?.indexOf(",") > -1
            ? threshold.split(",").map((el) => +el)
            : threshold,
      }
    );
    if (elRef.current) {
      observer.observe(elRef.current);
    }
    return () => observer.disconnect();
  }, [elRef, root, rootMargin, threshold]);
  return [observerEntry, elRef];
};

export function useScrollTop(
  dep,
  selector = ".scrollable-content__wrapper",
  smoothBehavior
) {
  useLayoutEffect(() => {
    const el = document.querySelector(selector);
    if (el && el.scrollIntoView) {
      el.scrollIntoView({ behavior: smoothBehavior ? "smooth" : "auto" });
    }
  }, [dep, selector, smoothBehavior]);
}
