import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debounce from 'lodash.debounce';

import { Icons } from '../icon';

import { useEventListener, useOnClickOutside } from '../../helpers/hooks';

import './input.scss';

const Input = ({
  onChange,
  onBlur,
  label,
  value,
  error,
  editable,
  debounceDelay,
  type
}) => {
  const inputRef = React.useRef();
  const containerRef = React.useRef();
  const [focused, setFocused] = React.useState(false);
  const [filled, setFilled] = React.useState(!!value);

  const handleClickOutside = React.useCallback(() => {
    if (value) {
      setFilled(true);
    } else {
      setFilled(false);
    }
    setFocused(false);
  }, [value]);

  const handleBlur = React.useCallback(() => {
    if (value) {
      setFilled(true);
    } else {
      setFilled(false);
    }
    setFocused(false);
    if (onBlur) {
      onBlur();
    }
  }, [onBlur, value]);

  const handleKeyUp = React.useCallback(e => {
    if ((e.key === 'Enter' || e.key === 'Escape') && inputRef.current === document.activeElement) {
      inputRef.current.blur();
    }
    if (e.key === 'Enter') {
      onChange(inputRef.current.value);
    }
  }, [onChange]);

  useEventListener('keyup', handleKeyUp);
  useOnClickOutside(containerRef, handleClickOutside);
  React.useEffect(() => {
    if (focused) {
      inputRef.current.focus();
    }
  }, [focused]);

  React.useEffect(() => {
    if (value && !filled) {
      setFilled(true);
    }
  }, [value, filled]);

  const delayedQuery = useRef(
    debounce(q => onChange(q), debounceDelay)
  ).current;

  const handleInput = useCallback(e => {
    if (debounceDelay) {
      delayedQuery(e.target.value);
    } else {
      onChange(e.target.value);
    }
  }, [onChange, delayedQuery, debounceDelay]);

  return (
    <div
      ref={containerRef}
      className={clsx('bc-input', { readOnly: !editable })}
      // role='textbox'
      // tabIndex={0}
      // onFocus={() => setFocused(true)}
      onClick={() => setFocused(true)}>
      <div className={clsx('bc-input__wrapper', {
        focused, error: !!error, filled
      })}>
        <div className='label'>{label}</div>
        <div className='label-placeholder'>Placeholder</div>
        <input
          type={type}
          disabled={!editable}
          defaultValue={value}
          ref={inputRef}
          onFocus={() => setFocused(true)}
          className={clsx(({ empty: inputRef?.current?.value === '' || !value }))}
          onBlur={handleBlur}
          onChange={handleInput}
          placeholder='' />
      </div>
      {!!error && (
        <div className='bc-input__status'>
          <div>{Icons.ERROR}</div>
          <div>{error}</div>
        </div>
      )}
    </div>
  );
};

Input.defaultProps = {
  error: null,
  onBlur: undefined,
  editable: true,
  debounceDelay: 100,
  type: 'text',
  value: ''
};

Input.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  editable: PropTypes.bool,
  debounceDelay: PropTypes.number,
  type: PropTypes.string
};

export default Input;
