import React, { useState } from "react";
import "./pagination.style.scss";

const PrevBtn = (props) => (
  <button className="manage-button" type="button" {...props}>
    Prev
  </button>
);

const NextBtn = (props) => (
  <button className="manage-button" type="button" {...props}>
    Next
  </button>
);

const Ellipsis = () => <span className="ellipsis">...</span>;

/* eslint-disable */
function* range(start, end) {
  while (start < end) {
    yield start;
    start += 1;
  }
}

export const Pagination = ({
  activePage = 1,
  numberOfButtons = 10,
  numberOfPages = 6,
  onPageChange,
}) => {
  // const [activePage, setActivePage] = useState(1);

  const generatePages = () => {
    const handleButtonClick = (e, n) => {
      if (n !== activePage) {
        e.target.focus();
        onPageChange(n);
      }
    };

    if (numberOfPages <= numberOfButtons) {
      const pages = Array.from(range(1, numberOfPages + 1));
      return pages.map((numberOfPages, i) => (
        <button
          type="button"
          key={`btn-page-${i}`}
          className={numberOfPages === activePage ? "is-active" : ""}
          onClick={(e) => handleButtonClick(e, numberOfPages)}
        >
          {numberOfPages}
        </button>
      ));
    }
    if (numberOfPages - activePage <= numberOfButtons - 2) {
      const init = [1];
      const end = Array.from(
        range(numberOfPages - (numberOfButtons - 2), numberOfPages + 1)
      );

      return (
        <>
          {init.map((numberOfPages, i) => (
            <button
              key={`btn-init-${i}`}
              type="button"
              className={numberOfPages === activePage ? "is-active" : ""}
              onClick={(e) => handleButtonClick(e, numberOfPages)}
            >
              {numberOfPages}
            </button>
          ))}
          <Ellipsis />
          {end.map((numberOfPages, i) => (
            <button
              key={`btn-end-${i}`}
              type="button"
              className={numberOfPages === activePage ? "is-active" : ""}
              onClick={(e) => handleButtonClick(e, numberOfPages)}
            >
              {numberOfPages}
            </button>
          ))}
        </>
      );
    } else if (numberOfPages - (numberOfPages - activePage) < numberOfButtons) {
      const first = Array.from(range(1, numberOfButtons));
      const end = [numberOfPages];

      return (
        <>
          {first.map((numberOfPages, i) => (
            <button
              key={`btn-first-${i}`}
              type="button"
              className={numberOfPages === activePage ? "is-active" : ""}
              onClick={(e) => handleButtonClick(e, numberOfPages)}
            >
              {numberOfPages}
            </button>
          ))}
          <Ellipsis />
          {end.map((numberOfPages, i) => (
            <button
              key={`btn-end-${i}`}
              type="button"
              className={numberOfPages === activePage ? "is-active" : ""}
              onClick={(e) => handleButtonClick(e, numberOfPages)}
            >
              {numberOfPages}
            </button>
          ))}
        </>
      );
    } else {
      const first = [1];
      const medium = Array.from(
        range(activePage - 1, activePage + (numberOfButtons - 4))
      );
      const end = [numberOfPages];

      return (
        <>
          {first.map((numberOfPages, i) => (
            <button
              key={`btn-first-${i}`}
              type="button"
              className={numberOfPages === activePage ? "is-active" : ""}
              onClick={(e) => handleButtonClick(e, numberOfPages)}
            >
              {numberOfPages}
            </button>
          ))}
          <Ellipsis />
          {medium.map((numberOfPages, i) => (
            <button
              key={`btn-medium-${i}`}
              type="button"
              className={numberOfPages === activePage ? "is-active" : ""}
              onClick={(e) => handleButtonClick(e, numberOfPages)}
            >
              {numberOfPages}
            </button>
          ))}
          <Ellipsis />
          {end.map((numberOfPages, i) => (
            <button
              key={`btn-end-${i}`}
              type="button"
              className={numberOfPages === activePage ? "is-active" : ""}
              onClick={(e) => handleButtonClick(e, numberOfPages)}
            >
              {numberOfPages}
            </button>
          ))}
        </>
      );
    }
  };

  return (
    <div id="pagination-container">
      <section className="btn-page btn-page--prev">
        <PrevBtn
          disabled={activePage === 1}
          onClick={() => onPageChange(activePage - 1)}
        />
      </section>
      <section className="btn-page btn-page--numbers">
        {generatePages()}
      </section>
      <section className="btn-page btn-page--next">
        <NextBtn
          disabled={activePage === numberOfPages}
          onClick={() => onPageChange(activePage + 1)}
        />
      </section>
    </div>
  );
};
