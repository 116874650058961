import React, { useMemo, createContext } from "react";
import clsx from "clsx";
import { isMobileDevice } from "responsive-react";

// Components
import ExploreStoryHeader from "./explore-story-header/explore-story-header";
import ExploreStoryContentOriginal from "./explore-story-content/explore-story-content-original";
import ExploreStoryContentMobile from "./explore-story-content/explore-story-content-mobile";
import Navbar from "../../components/navbar";
import Background from "../../components/background";

// Helpers
import { useIntersect } from "../../helpers/hooks";
import { getTheme, isFeatureAvailable } from "../../helpers/common";

// Styles
import "./explore-story.scss";

export const StoryContext = createContext({});

const ExploreStoryRenderer = ({ story }) => {
  const theme = useMemo(() => getTheme(), []);
  const [observerEntry, entryRef] = useIntersect({
    threshold: "0, 1",
    rootMargin: "0px 0px 0px 0px",
  });
  const [observerLike, likeRef] = useIntersect({
    threshold: "0.01, 0.5, 1",
    rootMargin: "-50px 0px 0px 0px",
  });

  const {
    id,
    period,
    title,
    text,
    cover_image,
    video_link,
    thumbnail_message,
    thumbnailUrl,
    photos,
  } = story;
  const video_embedded =
    thumbnail_message?.toLowerCase() === "video directly on the page";

  return (
    <div className={clsx("bc-explore-story__container", theme)}>
      <Background />
      <Navbar light />

      <section ref={entryRef}>
        <ExploreStoryHeader
          theme={theme}
          minimized={
            observerEntry.isIntersecting !== undefined &&
            !(
              observerEntry.isIntersecting &&
              observerEntry.boundingClientRect?.top === 0
            )
          }
          coverImage={cover_image}
          title={title}
          period={period}
        />
      </section>
      {isMobileDevice() ? (
        <ExploreStoryContentMobile
          ref={{ likeRef }}
          theme={theme}
          text={text}
          photos={photos}
          videoUrl={video_link}
          thumbnailUrl={thumbnailUrl}
          thumbnail_message={thumbnail_message}
          embedded={video_embedded}
        />
      ) : (
        <ExploreStoryContentOriginal
          ref={{ likeRef }}
          theme={theme}
          text={text}
          photos={photos}
          videoUrl={video_link}
          thumbnailUrl={thumbnailUrl}
          thumbnail_message={thumbnail_message}
          embedded={video_embedded}
        />
      )}
    </div>
  );
};

export default ExploreStoryRenderer;
