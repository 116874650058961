import React from "react";
import PropTypes from "prop-types";
import { Col, Grid, Row } from "react-flexbox-grid";
import { createMarkup } from "./helpers";
import { isFeatureAvailable } from "../../../helpers/common";
import Like from "../../../components/like";
import { AnimatedContent } from "../../../components/animated-content";
import StoryCarousel from "../../../components/story-carousel";

const ExploreStoryContentMobile = React.forwardRef(
  (
    {
      text,
      theme,
      photos,
      videos,
      likes,
      id,
      storyTagsNames
    },
    ref
  ) => {
    const { likeRef } = ref;

    return (
      <Grid
        className="bc-explore-story__content-mobile"
        style={{ paddingRight: 16, paddingLeft: 16 }}
      >
        <Row center="xs">
          <Col xs={12}>
            <AnimatedContent
              rootMargin={0}
              threshold={0.1}
              delay={0.35}
              custom={1}
            >
              <div
                ref={likeRef}
                dangerouslySetInnerHTML={createMarkup(text)}
                className="bc-formatted-text"
              />
            </AnimatedContent>
          </Col>
        </Row>
        {isFeatureAvailable(1) && (
          <Row center="lg" style={{ marginBottom: "1rem" }}>
            <Col lg={10}>
              <Like
                likesNumber={likes}
                theme={theme}
                storyId={id}
                storyTagsNames={storyTagsNames} 
              />
            </Col>
          </Row>
        )}
        <Row center="xs">
          <Col xs={12}>
            <StoryCarousel photos={photos} videos={videos} theme={theme} />
          </Col>
        </Row>
      </Grid>
    );
  }
);

ExploreStoryContentMobile.defaultProps = {
  text: "",
  photos: [],
  videos: [],
  likes: 0,
};

ExploreStoryContentMobile.propTypes = {
  likes: PropTypes.number,
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  theme: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default ExploreStoryContentMobile;
