import React from 'react';
import PropTypes from 'prop-types';
import { Animate } from 'react-move';
import { easeQuadOut } from 'd3-ease';
import clsx from 'clsx';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { interpolation } from '../../helpers/common';

import './loader.scss';

const UploadProgress = ({ show, progress }) => (
  <Animate
    show={show}
    start={{ opacity: 0 }}
    enter={{
      opacity: [1],
      timing: { duration: 750, delay: 0, ease: easeQuadOut }
    }}
    update={{
      opacity: [1],
      timing: { duration: 750, ease: easeQuadOut, delay: 0 }
    }}
    leave={[
      {
        opacity: [0],
        timing: { delay: 0, duration: 500, ease: easeQuadOut }
      }
    ]}
    interpolation={interpolation}
  >
    {({ opacity }) => (
      <div
        style={{ opacity }}
        className={clsx('bc-upload-progress__container')}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className='bc-background-wizard' />
        <div className='bc-background-wizard__overlay' />
        <Grid className='bc-upload-progress-content'>
          <Row center='xs' className='bc-upload-progress-content__row'>
            <Col xs={12}>
              <div className='bc-upload-progress-logo'>
                Uploading your content
              </div>
            </Col>
          </Row>
          <Row center='xs' className='bc-upload-progress-content__row'>
            <Col xs={10} md={4} lg={4}>
              <div className='bc-loader__progress'>
                <div
                  className='bc-loader__progress-bar'
                  style={{ width: `${progress}%` }}
                />
                <div
                  className={clsx('bc-loader__progress-value', {
                    colorful: progress <= 45
                  })}
                >
                  {progress}%
                </div>
              </div>
            </Col>
          </Row>
          <Row center='xs' className='bc-upload-progress-content__row'>
            <Col xs={10} md={4} lg={4}>
              <div className='bc-upload-progress-text'>
                This process may take some time.
                <br />
                Please do not refresh or close this page.
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )}
  </Animate>
);

// UploadProgress.defaultProps = {
//   progress: 0
// };

// UploadProgress.propTypes = {
//   show: PropTypes.bool.isRequired,
//   progress: PropTypes.number
// };

export default UploadProgress;
